<template>
  <div>
    <b-modal size="xl" :visible="showModal" @change="$emit('update:showModal', newValue)" :title="modalTitle" ok-only ok-title="All Set" >
      <template #modal-title>
      <div>
        Compare Similar Metrics for <strong>{{$filters.convertFromCamelCase(tableType)}}: {{modalTitle}}</strong>
      </div>
      </template>

      <!-- MODAL BODY -->
      <div class="d-flex flex-column" style="height:calc(100vh - 260px);">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <strong class="mr-3">
              Select Metrics to Compare:
            </strong>
            <div><b-form-select v-model="selectedField" :options="availableFieldOptions"/></div>
          </div>
          <div class="ml-auto mr-4" v-html="rankInfo">
          </div>
        </div>
        <div class="flex-grow-0 pt-2">
          <view-similar-metrics-chart :selected-metric="selectedField" :chart-data="chartData" :highlight-index="highlightIndex"/>
        </div>
        <div class="flex-grow-1">
          <div class="position-relative h-100">
            <fluent-table plan-type="similarMetric" :use-flex-classes="true" :items="similarMetrics" :fields="fields"/>
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import _orderBy from 'lodash.orderby'
import ViewSimilarMetricsChart from './viewSimilarMetricsChart.vue'

export default {
  name: 'viewSimilarMetrics',
  components: { ViewSimilarMetricsChart },
  props: ['value', 'showModal', 'tableType'],
  data () {
    return {
      similarMetrics: [],
      fields: [],
      availableFields: ['cost', 'impressions', 'clicks', 'conversions', 'clickThroughRate', 'costPerClick', 'costPerConversion', 'costPerThousandImpressions', 'conversionRate', 'impressionShare'], // 'position', 'avgLostToBudget', 'avgLostToRank', 'estimatedMaximumSpend'],
      desc: ['cost', 'impressions', 'clicks', 'conversions', 'clickThroughRate', 'conversionRate', 'impressionShare'], // 'position', 'avgLostToBudget', 'avgLostToRank', 'estimatedMaximumSpend'],
      selectedField: 'costPerClick',
      showModalVar: true,
      source: {},
      highlightIndex: -1
    }
  },
  watch: {
    selectedField () {
      this.sortMetrics()
    }
  },
  computed: {
    availableFieldOptions () {
      return this.availableFields.map(field => ({
        value: field,
        text: this.$filters.convertFromCamelCase(field)
      })
      )
    },
    modalTitle () {
      const titles = {
        campaign: this.value.name || '',
        adGroup: this.value.name || '',
        keyword: this.value.keywordText || ''
      }
      return titles[this.tableType]
    },
    chartData () {
      const sortedMetrics = this.getSortedMetrics()

      const metricToUseForLabel = 'accountName'

      let truncatedAccounts = sortedMetrics.filter(item => !!(item[this.selectedField]))

      if (truncatedAccounts.length > 20) {
        const source = truncatedAccounts.find(item => item.source)
        const firstTen = truncatedAccounts.splice(0, 10)
        const lastTen = truncatedAccounts.splice(truncatedAccounts.length - 10, 10)
        truncatedAccounts = [...firstTen, ...lastTen]
        if (source && !truncatedAccounts.includes(source)) {
          truncatedAccounts[9] = source
        }
      }

      const dataPoints = truncatedAccounts.map(metric => metric[this.selectedField])
      const labels = truncatedAccounts.map(metric => metric[metricToUseForLabel])
      const backgroundColors = truncatedAccounts.map(metric => {
        if (metric.source) {
          return 'rgba(116, 173, 210, 0.7)'
        } else {
          return 'rgba(153, 184, 152, 0.3)'
        }
      })

      return {
        labels,
        datasets: [{
          label: this.selectedField,
          backgroundColor: backgroundColors,
          borderColor: 'rgba(153, 184, 152, 1)',
          data: dataPoints
        }]
      }
    },
    rankInfo () {
      const index = this.similarMetrics.findIndex(item => item.source)
      if (index >= 0) {
        return `${this.similarMetrics[index].accountName} ranks <b>${index + 1}/${this.similarMetrics.length}</b> in ${this.$filters.convertFromCamelCase(this.selectedField)}`
      }
      return ''
    }
  },
  created () {
    this.getSimilarMetrics()
    this.$track.event('view similar metrics', {
      planType: this.value.objectType
    }, 'manage')
  },
  methods: {
    getSortedMetrics () {
      if (this.desc.includes(this.selectedField)) {
        return _orderBy([...this.similarMetrics], [this.selectedField], ['desc'])
      } else {
        return _orderBy([...this.similarMetrics], [this.selectedField])
      }
    },
    sortMetrics () {
      this.similarMetrics = this.getSortedMetrics()
    },
    async getSimilarMetrics () {
      const [startDateTime, endDateTime] = this.$store.getters.timeRange
      this.$store.commit('loading', {
        similarMetric: true
      })

      const payload = {
        campaign: {
          objectType: 'CampaignPlan',
          objectIds: [this.value.campaignPlanId],
          startDateTime,
          endDateTime,
          prependFields: ['accountName']
        },
        adGroup: {
          objectType: 'AdGroupPlan',
          objectIds: [this.value.adGroupPlanId],
          startDateTime,
          endDateTime,
          prependFields: ['accountName', 'campaignName']
        },
        keyword: {
          objectType: 'KeywordPlan',
          objectIds: [this.value.keywordPlanId],
          startDateTime,
          endDateTime,
          prependFields: ['accountName', 'campaignName', 'adGroupName']
        }
      }
      const tablePayload = payload[this.tableType]
      this.fields = tablePayload.prependFields.concat(this.availableFields)
      delete tablePayload.prependFields
      const response = await this.$res.fetch.similarMetrics(payload[this.tableType])

      if (response) {
        response.forEach(metric => {
          if (metric.source) {
            metric._rowVariant = 'info'
          }
        })
        this.similarMetrics = response
        this.source = this.value
      }

      this.$store.commit('loading', {
        similarMetric: false
      })
    }
  }
}
</script>

<style scoped>
</style>
