<template>
  <!--  <div class="position-absolute crumbs mb-2 ml-1">-->
  <div class="navbar navbar-expand white-space-pre nav nav-clean small breadcrumb-header">

    <div v-if="account" class="d-flex nav-item nav-link p-0" :class="{active: !budget}">
      <nuxt-link v-if="account" :to="{path: '/manage/account/', query: getQueryForRoute(account.accountPlanId) }" class="d-flex align-items-center">
        <fluency-icon type="account" size="lg" class="mr-3 account-fg"></fluency-icon>
        <span class="crumb-text" v-p-tooltip.bottom="account.name">{{account.name}} <template v-if="advanced">({{account.accountPlanId}})</template></span>

      </nuxt-link>
      <b-dropdown variant="link" :menu-class="classes.menu" :toggle-class="classes.toggle" lazy>
        <b-dropdown-item :key="accountItem.accountPlanId"
                         @click="goTo({path: '/manage/account/', query:{id:accountItem.accountPlanId}})"
                         v-for="accountItem in accounts">{{accountItem.name}}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div v-if="budget" class="d-flex nav-item nav-link p-0" :class="{active: !campaign}">
      <nuxt-link v-if="budget" :to="{path: '/manage/budget/', query: getQueryForRoute(budget.budgetPlanId) }" class="d-flex align-items-center ">
        <fluency-icon type="budget" size="lg" class="mr-3 budget-fg" />
        <span class="crumb-text" v-p-tooltip.bottom="budget.name">{{budget.name}} <template v-if="advanced">({{budget.budgetPlanId}})</template></span>
      </nuxt-link>
      <b-dropdown variant="link" :menu-class="classes.menu" :toggle-class="classes.toggle" lazy>
        <b-dropdown-item v-for="(budgetItem) in budgets"
                          :key="budgetItem.budgetPlanId"
                          @click="goTo({path: '/manage/budget/', query:{id:budgetItem.budgetPlanId}})">
          {{budgetItem.name}}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div v-if="campaign" class="d-flex nav-item nav-link p-0" :class="{active: !adGroup}">
      <nuxt-link v-if="campaign" :to="{path: '/manage/campaign/', query: getQueryForRoute(campaign.campaignPlanId) }" class="d-flex  align-items-center ">
        <fluency-icon type="campaign" size="lg" class="mr-3 campaign-fg"/>
        <span class="crumb-text" v-p-tooltip.bottom="campaign.name">{{campaign.name}} <template v-if="advanced">({{campaign.campaignPlanId}})</template></span>

      </nuxt-link>
      <b-dropdown variant="link" :menu-class="classes.menu" :toggle-class="classes.toggle" lazy>
        <b-dropdown-header v-for="(channelCampaigns, channelId) in campaigns"
                          :key="channelId">
          <span class="d-flex align-items-center border-bottom mx-3 mb-1 pl-3 py-2">
                  <h4 class="mb-0"><fluency-icon :type="AdvertisingChannelIcons.get(advertisingChannels[channelId])"
                                                 class="text-success mr-3"/></h4>
                  <b>{{advertisingChannels[channelId]}}</b>
                </span>
          <b-dropdown-item v-for="campaignItem in channelCampaigns"
                           :key="campaignItem.campaignPlanId"
                           @click="goTo({path: '/manage/campaign/', query: getQueryForRoute(campaignItem.campaignPlanId) })">
            {{campaignItem.name}}
          </b-dropdown-item>
        </b-dropdown-header>
      </b-dropdown>
    </div>

    <div v-if="adGroup" class="d-flex nav-item nav-link p-0 active">
      <nuxt-link v-if="adGroup" :to="{path: '/manage/adgroup/', query: getQueryForRoute(adGroup.adGroupPlanId) }" class="d-flex  align-items-center ">
        <fluency-icon type="adgroup" class="mr-3 ad-group-fg" />
        <span class="crumb-text" v-p-tooltip.bottom="adGroup.name">{{adGroup.name}} <template v-if="advanced">({{adGroup.adGroupPlanId}})</template></span>
      </nuxt-link>
      <b-dropdown variant="link" :menu-class="classes.menu" :toggle-class="classes.toggle" lazy>
        <b-dropdown-item :key="`dropdown-breadcrumb-ad-group${adGroupItem.adGroupPlanId}`"
                         @click="goTo({path: '/manage/adgroup/', query:{id:adGroupItem.adGroupPlanId}})"
                         v-for="adGroupItem in adGroups">{{adGroupItem.name}}
        </b-dropdown-item>
      </b-dropdown>
    </div>

  </div>
</template>

<script>
import { AdvertisingChannelIcons } from 'assets/js/constants'
import { useAccountsById } from '@/composables/useAccountsById.js'
import { useCampaignNavigation } from '../../../composables/useCampaignNavigation'
import { useAdGroupNavigation } from '../../../composables/useAdGroupNavigation'
import { useActiveItemAncestors } from '../../../composables/useActiveItemAncestors'

export default {
  name: 'breadcrumbHeader',
  props: ['showPlanStatus'],
  setup () {
    const { getAccountsById } = useAccountsById()
    const { getCampaignNavigation } = useCampaignNavigation()
    const { getAdGroupNavigation } = useAdGroupNavigation()
    const { getActiveBudget, getActiveCampaign, getActiveAdGroup } = useActiveItemAncestors()
    return {
      getAccountsById,
      getCampaignNavigation,
      getAdGroupNavigation,
      getActiveBudget,
      getActiveCampaign,
      getActiveAdGroup
    }
  },
  data () {
    return {
      classes: {
        menu: 'breadcrumb-dropdown mr-3',
        toggle: 'breadcrumb-toggle p-2'
      },
      advertisingChannels: [...this.$store.getters.advertisingChannels]
        .toMap(channel => channel.advertisingChannelId, channel => channel.channelLabel),
      AdvertisingChannelIcons,
      accounts: []
    }
  },
  // a watcher should suffice here
  mounted () {
    this.accounts = this.filterShowPlanEnabledStatus(Array.from((this.$store.getters.accountsById || new Map()).values()))
      .map(acct => ({ accountPlanId: acct.accountPlanId, name: acct.name }))
    this.$store.subscribe(this.storeSubscribeToAccountsById)
  },
  beforeUnmount () {
    const idx = this.$store._subscribers.indexOf(this.storeSubscribeToAccountsById)
    if (idx >= 0) {
      this.$store._subscribers.splice(idx, 1)
    }
  },
  computed: {
    item () {
      return this.$store.getters.activeItem
    },
    budget () {
      if (this.item?.planType === 'budget') {
        return this.item
      }
      return this.getActiveBudget() || null
    },
    budgets () {
      return this.filterShowPlanEnabledStatus(this.$store.getters.activeAccountBudgets)
        .map(b => ({ budgetPlanId: b.budgetPlanId, name: b.name }))
    },
    account () {
      if (this.item?.accountPlanId && this.$store.getters.accountsById?.has(this.item.accountPlanId)) {
        return this.$store.getters.accountsById.get(this.item.accountPlanId)
      } else {
        return null
      }
    },
    campaign () {
      if (this.item?.planType === 'campaign') {
        return this.item
      } else {
        return this.getActiveCampaign() || null
      }
    },
    campaigns () {
      if (this.account) {
        const structuredNav = this.getCampaignNavigation()?.[this.account.accountPlanId] || {}
        const flatCampaigns = Object.values(structuredNav).flatMap(campaigns => campaigns)
        const filteredCampaigns = this.filterShowPlanEnabledStatus(flatCampaigns)

        return filteredCampaigns.groupBy(campaign => campaign.advertisingChannelId)
      } else {
        return null
      }
    },
    adGroup () {
      if (this.item?.planType === 'adGroup') {
        return this.item
      } else {
        return this.getActiveAdGroup() || null
      }
    },
    adGroups () {
      if (this.campaign) {
        // return this.filterShowPlanEnabledStatus(this.$store.getters.adGroupNavigation[this.campaign.campaignPlanId] || [])
        return this.filterShowPlanEnabledStatus(this.getAdGroupNavigation()?.[this.campaign.campaignPlanId] || [])
      }
      return null
    },
    advanced () { // advanced UI view
      return this.$store.getters.userMode === 'advanced'
    }

  },
  methods: {
    goTo (route) {
      this.$router.push(route)
    },
    storeSubscribeToAccountsById (mutation, state) {
      if (mutation.type === 'setAccountsById' || mutation.type === 'updateAccountInAccountsById') {
        this.accounts = this.filterShowPlanEnabledStatus(Array.from(this.getAccountsById().values()))
          .map(acct => ({ accountPlanId: acct.accountPlanId, name: acct.name }))
      }
    },
    filterShowPlanEnabledStatus (plans) {
      let returnedPlans = []
      if (this.showPlanStatus === 'Show All But Removed') {
        returnedPlans = plans
          .filter(e => (e.servingStatus ? e.servingStatus.cascadingStatus : e.status) === 'ENABLED' || this.parseImpression(e.impressions) > 0 || (e.servingStatus ? e.servingStatus.cascadingStatus : e.status === 'PAUSED') || e.enabled)
      } else if (this.showPlanStatus === 'Show All Enabled') {
        returnedPlans = plans
          .filter(e => (e.servingStatus ? e.servingStatus.cascadingStatus : e.status) === 'ENABLED' || this.parseImpression(e.impressions) > 0 || e.enabled)
      }
      if (returnedPlans.length > 0 && returnedPlans[0].name) {
        returnedPlans = returnedPlans.slice(0, 100).sort(function (a, b) {
          if (a.name && b.name) {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()
            if (nameA < nameB) { // sort string ascending
              return -1
            }
            if (nameA > nameB) { // default return value (no sorting)
              return 1
            }
          }
          return 0
        })
      }
      return returnedPlans
    },
    parseImpression (val) {
      if (!val) {
        return 0
      }
      if (typeof val === 'string') {
        return parseInt((val).replace(/,/, ''))
      }
      return val
    },
    getQueryForRoute (id) {
      const query = { id }
      if (this.$route.query.table) {
        query.table = this.$route.query.table
      }
      return query
    }
  }
}
</script>

<style scoped lang="scss">
$bread-crumb-height: 40px;

/*.crumb:not(.last) {*/
.crumb-text {
  text-overflow: ellipsis;
  max-width: 160px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  transition: color 150ms linear;
}

.nav-link {
  border-bottom: 2px solid transparent;
}
.active {
  border-bottom: 2px solid $fluency-green;
}
.nav-link .crumb-text {
  color: $fluency-gray;
}
.nav-link:hover .crumb-text,
.active .crumb-text {
  color: $fluency-green
}

.crumbs {
  top: 3px;
  left: 2px;

  .crumb {
    float: left;
    height: $bread-crumb-height;
    padding: 12px 0 12px 8px;
    margin-right: 0;
    margin-left: 18px;
  }

  a {
    float: left;
    display: inline-block;
    color: white;
    font-weight: 500;

    .crumb-icon {
      float: left;
    }

    .crumb-text {
      float: left;
    }
  }

  .crumb:not(.last) {
    .crumb-text {
      text-overflow: ellipsis;
      max-width: 110px;
      overflow: hidden;
      display: block;
      white-space: nowrap;
    }
  }

  .account-crumb {
    margin-left: 0;
  }
}

//.account-bg {
//  background-color: $color-key-account !important;
//}
.account-fg {
  color: $color-key-account !important;
}

//.campaign-bg {
//  background-color: $color-key-campaign !important;
//}
.campaign-fg {
  color: $color-key-campaign !important;
}

//.ad-group-bg {
//  background-color: $color-key-adgroup !important;
//}
.ad-group-fg {
  color: $color-key-adgroup !important;
}

//.budget-bg {
//  background-color: $color-key-budget !important;
//}
.budget-fg {
  color: $color-key-budget !important;
}
</style>
<style lang="scss">
.breadcrumb-header {
  #coachmark-manage-experiment-toggle .coachmark-container {
    .coachmark-blinker,
    .coachmark {
      top: 2px;
      left: 2px;
    }
  }
  .breadcrumb-dropdown.dropdown-menu {
    overflow: auto;
    max-height: 97vh;
  }

  .dropdown-item {
    width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .nav-link .breadcrumb-toggle{
    color: $fluency-gray;
  }
  .nav-link:hover .breadcrumb-toggle,
  .active .breadcrumb-toggle {
    color: $fluency-green
  }
}
</style>
