<template>
  <div>
    <div
         style="max-width: 200px; text-overflow: ellipsis; overflow: hidden"  >
      <span v-p-tooltip.top="tooltipText">
        <img v-if="planType === 'imageExtension'" :src="extensionPlan.image" style="max-height: 40px;"/>
        <template v-else>
          {{displayText}}
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import EditExtensionMixin from './editExtensionMixin'

// Scott and Matt decided to stop using the inline editor for extensions and just direct the  user go straight to the advanced manage screen

export default {
  name: 'extensionNameDisplay',
  components: {
  },
  props: {
    extensionPlan: Object
  },
  mixins: [EditExtensionMixin],
  data () {
    return {
      isEditing: false,
      draftExtensionPlan: {}
    }
  },
  watch: {
    extensionPlan: {
      handler: function (val) {
        this.draftExtensionPlan = {
          ...val,
          ...this.draftExtensionPlan
        }
      },
      immediate: true
    }
  },
  computed: {
    tooltipText () {
      return this.labelizeExtensionTooltip(this.extensionPlan) // from mixin
    },
    displayText () {
      return this.labelizeExtensionName(this.extensionPlan) // from mixin
    },
    extensionPlanId () {
      return this.extensionPlan[`${this.planType}PlanId`]
    },
    planType () {
      if (this.extensionPlan.extensionType) {
        return this.extensionPlan.extensionType.slice(0, -4)
      } else if (this.extensionPlan.planType) {
        return this.extensionPlan.planType
      } else {
        return ''
      }
    }
  },
  methods: {}
}
</script>

<style lang='scss' scoped>

</style>
