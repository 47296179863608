<template>
  <div class="d-inline">
    <b-button v-if="showBtn"
              variant="ghost-light"
              @click="showModal = true"
              class=""
              v-p-tooltip.top="'Paste'"
              :disabled="!pasteButtonEnabled"
              :class="{'disabledPaste': !pasteButtonEnabled, 'dropdown-item': dropdownItem}">
      <fluency-icon v-if="!dropdownItem" type="copy" />
      Paste
    </b-button>

    <b-modal v-model="showModal" title="Paste Plans (Beta)" ok-title="Paste" @ok="pasteInto()">
      <div v-if="copiedPlans && copiedPlans.plans">
        <div class="font-italic mb-4">Paste {{ copiedPlans.plans.length }} {{ readableType.toLowerCase() }} into
                                      {{ copiedPlans.parent }}
                                      '{{ activeItemName }}'.
          <span v-if="copiedPlans.type === 'extension'"> Only Site Links and Callouts are currently supported</span>
        </div>
        <b-form-group label="Paste Depth">
          <b-form-radio :checked="copyDepth" @input="copyDepth = $event" name="paste-depth" value="SHALLOW">Only the
                                                                               {{ readableType.toLowerCase() }},
                                                                               none of their children
          </b-form-radio>
          <b-form-radio :checked="copyDepth" @input="copyDepth = $event" name="paste-depth" value="DEEP">The {{ readableType.toLowerCase() }}, and
                                                                            all
                                                                            their children
          </b-form-radio>
        </b-form-group>
        <b-form-checkbox
          id="checkbox-1"
          :checked="replaceAccountInfo"
          @input="replaceAccountInfo = $event"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
        >
          Replace Account Info
        </b-form-checkbox>
        <div class="mt-4">
          <template v-if="replacements.length > 0">
            <div class="form-row">
              <div class="col">
                <h5>Find and Replace Text
                  <info-tooltip popover>
                    <template #title>
                    <ul class="">
                      <li>Campaigns: Name</li>
                      <li>Ad Groups: Name, Destination Url</li>
                      <li>Keywords: Keyword Text, Destination Url</li>
                      <li>Creative: Name, Text Assets, Destination Url</li>
                      <li>Extension: Extension Text, Descriptions</li>
                    </ul>
                    </template>
                  </info-tooltip>
                </h5>
              </div>
            </div>
            <div class="form-row">
              <div class="col">Find Text</div>
              <div class="col">Replace With</div>
            </div>
          </template>
          <div :key="`replacement-${replacementIndex}`" class="form-row"
               v-for="(replacement, replacementIndex) in replacements">
            <b-form-group class="col">
              <b-form-input v-model="replacement.from"></b-form-input>
            </b-form-group>
            <b-form-group class="col">
              <b-form-input v-model="replacement.to"></b-form-input>
            </b-form-group>
          </div>
          <div class="form-row">
            <b-button variant="ghost-secondary" @click="replacements.push({from:'', to:''})">
              <fluency-icon type="add" class="pr-1"/>
              Find and Replace Text
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InfoTooltip from './forms/infoTooltip'

export default {
  name: 'pastePlans',
  components: { InfoTooltip },
  props: {
    settingsType: {
      type: String
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    openDialog: {
      type: Boolean
    },
    bulkSelection: {
      type: Array
    },
    view: {
      type: String,
      default: ''
    },
    dropdownItem: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: false,
      // copiedPlans: [],
      copyDepth: 'DEEP',
      replaceAccountInfo: true,
      fromAndToData: [
        { from: 'this', to: 'that' },
        { from: 'this', to: 'that' }
      ],
      clipboardResolutionData: [],
      replacements: []
    }
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (oldValue === true && newValue === false) {
          this.$emit('modal-closed')
        }
      }
    },
    openDialog: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          if (this.pasteButtonEnabled) {
            this.$setCompat(this, 'showModal', true)
          } else {
            const copied = this.copiedPlans?.type ?? null
            const target = this.type
            let helperText = ''

            if (this.view === 'manageTable') {
              helperText += '\n\n(Hint: Make sure you\'re viewing the right table in Manage - close the blue bar to verify)'
            }
            let alertMessage = 'You need to copy a valid element before pasting.'
            if (copied) {
              alertMessage = `${alertMessage}\n\nYou are trying to paste ${this.aOrAN(copied)} **${copied.toUpperCase()}** into ${this.aOrAN(target)} **${target.toUpperCase()}**${helperText}`
            }

            this.$alert(alertMessage)
            this.$emit('modal-closed')
          }
        }
      }
    }
  },
  computed: {
    type () {
      return (this.settingsType === 'adgroup') ? 'adGroup' : this.settingsType
    },
    activeItem () {
      return this.$store.getters.activeItem
    },
    activeItemName () {
      return this.activeItem?.name || ''
    },
    readableType () {
      if (this.copiedPlans.type) {
        const filtered = this.$filters.convertFromCamelCase(this.copiedPlans.type)
        return filtered + ((this.copiedPlans.plans.length > 1) ? 's' : '')
      } else {
        return ''
      }
    },
    pasteButtonEnabled () {
      if (this.copiedPlans && this.copiedPlans.type && this.parent(this.copiedPlans.type) === this.type) {
        return true
      }

      return false
    },
    copiedPlans () {
      return this.$store.getters.copyBin || []
    }
  },
  methods: {
    aOrAN (type) {
      if (type === 'adGroup') {
        return 'an'
      }
      return 'a'
    },
    parent (type) {
      const parent = {
        creative: 'adGroup',
        keyword: 'adGroup',
        adGroup: 'campaign',
        campaign: 'account',
        extension: 'campaign' // extensions can also be on account and ad group, so not sure how to deal with that yet
      }

      return parent?.[type]
    },
    async pasteInto () {
      if (!this.copiedPlans?.type) {
        return
      }
      let promises = []
      if (this.copiedPlans.type === 'extension') {
        const extensionTypeGroups = this.copiedPlans.plans.groupBy(plan => plan.extensionType)
        promises = Object.keys(extensionTypeGroups)
          .map(extensionType => this.pasteIntoAtLevel(this.toCapsUnderscore(extensionType.replace('Plan', '')), { extensionType }))
      } else {
        promises = [this.pasteIntoAtLevel(this.copiedPlans.type.toUpperCase())]
      }
      const responses = await Promise.all(promises)
      if (responses.some(res => !res)) {
        this.$toast('Items failed to paste', 'danger')
      } else {
        const hasFailures = responses.some(res => {
          if (res.nErrors >= 1) {
            this.$toast(`Encountered ${res.nErrors} error${(res.nErrors > 1) ? 's' : ''} while trying to paste`, 'danger')
            return true
          }

          return false
        })

        if (!hasFailures) {
          this.$toast('Items pasted', 'success')
        }

        this.$store.dispatch('fetchPlansWithMetrics', { fetchType: this.copiedPlans.type })
      }
    },
    async pasteIntoAtLevel (level, options) {
      let destinationParentIds = []
      if (this.bulkSelection && this.bulkSelection.length > 0) {
        destinationParentIds = this.bulkSelection.map(x => x[`${this.type}PlanId`])
      } else {
        destinationParentIds = [this.activeItem[`${this.type}PlanId`]]
      }

      const pasteBuffer = {
        level,
        strategy: this.copyDepth,
        options: {
          autoReplaceAccountValues: this.replaceAccountInfo
        },
        sourceItemIds: this.mapCopiedPlansToPlanIds(level, options),
        destinationParentIds,
        destinationParentAccountId: this.activeItem.accountPlanId,
        replacements: this.replacements
      }
      if (pasteBuffer.destinationParentIds.length > 0 && pasteBuffer.destinationParentAccountId) {
        return this.$res.set.pastePlans(pasteBuffer)
      } else {
        return Promise.resolve()
      }
    },
    async resolveClipboard () {
      const resolveClipboardObj = {
        copyElementType: this.$filters.convertFromCamelCaseToCapsUnderscore(this.copiedPlans.type) + '_PLAN',
        elementIds: this.copiedPlans.plans.map(plan => plan[`${this.copiedPlans.type}PlanId`])
      }
      const response = await this.$res.fetch.clipboardResolve(resolveClipboardObj)
      this.$setCompat(this, 'clipboardResolutionData', response)
    },
    mapCopiedPlansToPlanIds (level, options) {
      if (level === 'SITE_LINK' || level === 'CALLOUT') { // supported vals
        const { extensionType } = options
        return this.copiedPlans.plans
          .filter(plan => plan.extensionType === extensionType || plan.extensionType === extensionType)
          .map(plan => plan.extensionPlanId)
      } else {
        return this.copiedPlans.plans.map(plan => plan[`${this.copiedPlans.type}PlanId`])
      }
    },
    toCapsUnderscore (text) {
      if (text) {
        return text.trim().split(/(?=[A-Z-])/).join('_').toUpperCase()
      }
      return ''
    }
  }
}
</script>

<style scoped>
.disabledPaste {
  color: gray;
  opacity: 0.3;
}
</style>
