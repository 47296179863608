<template>
  <b-modal v-model="showDestinationUrlModal" title="Bulk Set Destination URL" @ok="checkValidation">
    <domain-input v-model="destinationUrl"
                  label="Enter the destination URL you want to use:"
                  :validity-callback="validURL"/>
  </b-modal>
</template>

<script>
import DomainInput from '../../common/forms/domainInput'

export default {
  name: 'bulkSetDestinationUrlModal',
  components: { DomainInput },
  props: ['value'],
  data () {
    return {
      destinationUrl: '',
      showDestinationUrlModal: false,
      secureProtocol: false,
      validUrl: null
    }
  },
  mounted () {
    this.showDestinationUrlModal = this.value
  },
  watch: {
    value (newValue, oldValue) {
      this.showDestinationUrlModal = newValue
    },
    showDestinationUrlModal (newValue) {
      this.$emit('input', this.showDestinationUrlModal)
    }
  },
  methods: {
    checkValidation (evt) {
      if (this.validURL(this.destinationUrl)) {
        this.$emit('save', this.destinationUrl)
      } else {
        this.validUrl = false
        evt.preventDefault()
      }
    },
    validURL (str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(.*)*$')
      return !!pattern.test(str)
    }
  }
}
</script>

<style scoped>

</style>
