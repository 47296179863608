<template>
  <div>
    <b-modal v-model="showModalVar"
             size="lg"
             title="Bulk Edit Budgets"
             :ok-disabled="okDisabled"
             @ok="saveChanges"
             @hide="$emit('modal-closed')">
      <template #modal-ok><span>Save</span></template>

      <b-checkbox :checked="togglePacing"
                  :value="true"
                  :unchecked-value="false"
                  :switch="true"
                  class="mb-3"
                  @input="togglePacing = $event; togglePacingCheckbox($event)">Edit Pacing Strategy</b-checkbox>

      <p v-if="togglePacing" class="mb-0">
        <small>Set a Pacing Strategy for all selected Budgets.</small>
      </p>

      <bulk-pacing-strategy-options v-if="showModalVar"
                                    :disabled="!togglePacing"
                                    @changed="changedSettings($event)" />

      <div class="row pt-4">
        <div class="col-11">
          <b-form-group class="mb-0">
            <b-checkbox :checked="toggleSettings"
                        :value="true"
                        :unchecked-value="false"
                        :switch="true"
                        @input="toggleSettings = $event; toggleSettingsCheckbox($event)">Edit Settings</b-checkbox>
          </b-form-group>

          <p class="mb-3 text-right font-italic font-weight-bold small">Edit only the fields that you wish to adjust.</p>

          <!-- EXISTING -->
          <b-form-group :label-cols="labelColWidth"
                        label="Adjust Budget Amount"
                        label-for="pauseCampaignsWhenExhausted"
                        class="mb-3">
            <!-- <b-input :disabled="!toggleSettings" v-model="updates.budgetAmount"/> -->
            <bulk-modify-dollar-amount @input="updates.budgetAmount = $event" :disabled="!toggleSettings"/>
          </b-form-group>
          <b-form-group :label-cols="labelColWidth"
                        label="Set Publishing Status"
                        label-for="readOnlyBudgetBulkInput"
                        class="mb-3">
            <b-select id="readOnlyBudgetBulkInput"
                      v-model="updates.readOnly"
                      :disabled="!toggleSettings"
                      :options="readOnlyOptions" />
          </b-form-group>
          <b-form-group :label-cols="labelColWidth"
                        label="Pause Campaigns When Exhausted"
                        label-for="pauseCampaignsWhenExhausted"
                        class="mb-3">
            <b-select v-model="updates.pauseCampaignsWhenExhausted"
                      id="pauseCampaignsWhenExhausted"
                      :disabled="!toggleSettings"
                      :options="options"></b-select>
          </b-form-group>
          <b-form-group :label-cols="labelColWidth"
                        label="Allow Reallocation"
                        label-for="allowReallocation"
                        class="mb-3">
            <b-select v-model="updates.allowReallocation"
                      id="allowReallocation"
                      :disabled="!toggleSettings"
                      :options="options"></b-select>
          </b-form-group>

          <b-form-group :label-cols-sm="labelColWidth"
                        label="Auto Remove Blocking Keywords On Underspend"
                        label-for="autoRemoveBlockingKeywordOnUnderspend"
                        class="mb-3">
            <b-select v-model="updates.autoRemoveBlockingKeywordOnUnderspend"
                      id="autoRemoveBlockingKeywordOnUnderspend"
                      :disabled="!toggleSettings"
                      :options="options"></b-select>
          </b-form-group>

          <!-- NEW -->
          <b-form-group :label-cols-sm="labelColWidth"
                        label="Underspend Bid Adjustment"
                        label-for="bidAdjustmentPercentageOnUnderspend"
                        style="margin-bottom:-0.75em;">
            <bid-adjustment-input
              :prepend="[{text: 'Increase by', value: 1}]"
              :hide-label="true"
              :disabled="!toggleSettings"
              v-model="updates.bidAdjustmentPercentageOnUnderspend"/>

          </b-form-group>
          <b-form-group :label-cols-sm="labelColWidth"
                        label="Underspend Broad Match"
                        label-for="useBroadMatchOnUnderspend"
                        class="mb-3">
            <b-select v-model="updates.useBroadMatchOnUnderspend"
                      id="useBroadMatchOnUnderspend"
                      :disabled="!toggleSettings"
                      :options="options"></b-select>
          </b-form-group>
          <b-form-group :label-cols-sm="labelColWidth"
                        label="Underspend Relax Ad Schedules"
                        label-for="openAdSchedulesOnUnderspend"
                        class="mb-3">
            <b-select v-model="updates.openAdSchedulesOnUnderspend"
                      id="openAdSchedulesOnUnderspend"
                      :disabled="!toggleSettings"
                      :options="options"></b-select>
          </b-form-group>
          <b-form-group :label-cols-sm="labelColWidth"
                        label="Underspend Geo Max"
                        label-for="geoTargetExpansionAbsoluteMaxInMiles"
                        class="mb-3">
            <b-input-group size="md" append="mi">
              <b-input v-model="updates.geoTargetExpansionAbsoluteMaxInMiles"
                       min="0"
                       type="number"
                       placeholder="Enter distance to change"
                       :disabled="!toggleSettings"></b-input>
            </b-input-group>
          </b-form-group>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import BulkPacingStrategyOptions from './bulkPacingStrategyOptions'
import BidAdjustmentInput from 'core-ui/components/inputs/bidAdjustment'
import BulkModifyDollarAmount from './bulkModifyDollarAmount'

export default {
  name: 'bulkModifyBudgetModal',
  components: { BulkModifyDollarAmount, BidAdjustmentInput, BulkPacingStrategyOptions },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModalVar: true,
      toggleSettings: false,
      togglePacing: false,
      toggleChannelAllocation: false,
      updates: {
        // OLD
        budgetBiasStrategy: null,
        fixedBudgetValue: null,
        pauseCampaignsWhenExhausted: null,
        notifyOnPause: null,
        allowReallocation: null,
        budgetAmount: null,
        // NEW
        bidAdjustmentPercentageOnUnderspend: null,
        useBroadMatchOnUnderspend: null,
        openAdSchedulesOnUnderspend: null,
        geoTargetExpansionAbsoluteMaxInMiles: null,
        autoRemoveBlockingKeywordOnUnderspend: null,
        readOnly: null
      },
      options: [
        { text: 'Don\'t change', value: null },
        { text: 'On', value: true },
        { text: 'Off', value: false }
      ],
      readOnlyOptions: [
        { text: 'Don\'t change', value: null },
        { text: 'Place Budgets In DRAFT', value: true },
        { text: 'Allow Budgets to PUBLISH', value: false }
      ],
      labelColWidth: 5
    }
  },
  computed: {
    okDisabled () {
      if (!this.updates.budgetBiasStrategy === null) {
        return false
      }
      if (this.updates.budgetBiasStrategy !== 'FIXED_DAILY') {
        return false
      }
      if (this.updates.budgetBiasStrategy === 'FIXED_DAILY' && this.updates.fixedBudgetValue > 0) {
        return false
      }

      return true
    }
  },
  watch: {
    showModal: {
      handler (newValue) {
        this.showModalVar = newValue
      },
      immediate: true
    }
  },
  methods: {
    togglePacingCheckbox (e) {
      if (!e) {
        this.updates.budgetBiasStrategy = null
        this.updates.fixedBudgetValue = null
      }
    },
    toggleSettingsCheckbox (e) {
      if (!e) {
        const o = Object.assign({}, this.updates, e)

        Object.entries(o).forEach(entry => {
          const key = entry[0]
          this.updates[key] = null
        })
      }
    },
    toggleChannelAllocationCheckbox (e) {
      // stuff
    },
    changedSettings (e) {
      const o = Object.assign({}, this.updates, e)
      this.updates = o
    },
    saveChanges (e) {
      this.$emit('saved', this.updates)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
