<template>
  <div>
    <b-modal size="sm"
             v-model="showModalVar"
             title="Bulk Edit Bids"
             @ok="$emit('saved', {modificationAmount, setOrModify, dollarOrPercent, matchType})"
             @hide="$emit('modal-closed')">
      <b-button-group class="mb-3">
        <b-button :variant="(setOrModify === 'modify')?'success':''" @click="setOrModify = 'modify'">Modify by
        </b-button>
        <b-button :variant="(setOrModify === 'set')?'success':''" @click="setOrModify = 'set'; dollarOrPercent = '$'">
          Set
        </b-button>
      </b-button-group>
      <b-input-group style="width:130px;" class="float-right">
        <b-input-group-prepend>
          <b-button @click="toggleFixedPercent()">{{dollarOrPercent}}</b-button>
        </b-input-group-prepend>
        <b-form-input type="number" min="0.00" v-model="modificationAmount"></b-form-input>
      </b-input-group>
      <b-form-group label="Match Type:" v-if="tableType === 'keyword'" class="mb-0">
        <b-form-checkbox-group :options="keywordSelect" :checked="matchType" @input="matchType = [...$event]"></b-form-checkbox-group>
      </b-form-group>
      <br/>
      <div class="clearer float-right">{{bidLanguage}}</div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'bulkModifyBidModal',
  props: ['showModal', 'tableType'],
  data () {
    return {
      showModalVar: false,
      dollarOrPercent: '$',
      modificationAmount: 0,
      setOrModify: 'modify',
      matchType: [],
      keywordSelect: [
        { value: 'broadMatchBid', text: 'Broad' },
        { value: 'phraseMatchBid', text: 'Phrase' },
        { value: 'exactMatchBid', text: 'Exact' }
      ]
    }
  },
  computed: {
    bidLanguage () {
      let matchTypeText = ''
      if (this.tableType === 'keyword') {
        if (this.matchType && this.matchType.length > 0) {
          let matchList = []
          this.matchType.forEach((match, count) => {
            matchList.push(this.$filters.convertFromCamelCase(match))
          })
          let matchListText = matchList.join(', ')
          matchListText = matchListText.replace(/\,([^\,]*)$/, ' and $1').replace(/\sBid/g, '')
          matchTypeText = `for ${matchListText}`
        } else {
          return 'Select match type you want to change above.'
        }
      }
      return `${this.$filters.convertFromCamelCase(this.setOrModify)} selected bids ${matchTypeText} ${(this.setOrModify === 'modify') ? 'by' : 'to'} ${(this.dollarOrPercent === '$') ? '$' : ''}${this.modificationAmount}${(this.dollarOrPercent === '%') ? '%' : ''}`
    }
  },
  watch: {
    showModal: {
      handler (newValue) {
        this.$setCompat(this, 'showModalVar', newValue)
      },
      immediate: true
    }
  },
  methods: {
    toggleFixedPercent () {
      if (this.setOrModify === 'modify') {
        this.dollarOrPercent = (this.dollarOrPercent === '$') ? '%' : '$'
      }
    }
  }
}
</script>

<style scoped>

</style>
