<template>
  <div class="manage-type"
       :class="{ 'overlay': !bionicSwitcher }">
    <view-similar-metrics :show-modal="showViewSimilarMetricsModal"
                          @update:show-modal="showViewSimilarMetricsModal = $event"
                          :value="viewSimilarMetricsObject"
                          v-if="showViewSimilarMetricsModal"
                          :table-type="tableType"/>
    <bulk-modify-bid-modal :show-modal="showModifyBidModal"
                           v-if="showModifyBidModal"
                           @saved="bulkModifyBids($event)"
                           @modal-closed="showModifyBidModal = false"
                           :table-type="tableType"/>
    <bulk-set-destination-url-modal v-model="showDestinationUrlModal"
                                    @save="bulkModalReturn($event)"/>
    <bulk-modify-budget-modal :show-modal="bulkBudget.showModal"
                              v-if="bulkBudget.showModal"
                              @saved="bulkModifyBudgets($event)"
                              @modal-closed="bulkBudget.showModal = false"/>
    <paste-plans v-if="bulkEditDialog.component === 'pastePlans' && bulkEditDialog.show"
                 :settings-type="bulkEditDialog.propsOverride.settingsType || tableType"
                 :show-btn="false"
                 view="manageTable"
                 :open-dialog="bulkEditDialog.show"
                 @modal-closed="bulkEditDialog.show = false"
                 :bulk-selection="bulkDataCommand.selectedRows" />
    <duplicate-plans v-if="bulkEditDialog.component === 'duplicatePlans' && bulkEditDialog.show"
                     :show="bulkEditDialog.show"
                     @update:show="bulkEditDialog.show = $event"
                     :plans="bulkDataCommand.selectedRows"
                     :plan-type="tableType"
                     :account-plan-id="accountPlanId" />
    <bulk-find-and-replace-keywords-modal :show-modal="showFindAndReplaceKeywordsModal"
                                          v-if="showFindAndReplaceKeywordsModal"
                                          @modal-closed="showFindAndReplaceKeywordsModal = false"
                                          :table-type="tableType"></bulk-find-and-replace-keywords-modal>
    <bulk-budget-worksheet v-if="showBulkBudgetWorksheetModal"
                           :show-modal="showBulkBudgetWorksheetModal"
                           @modal-closed="showBulkBudgetWorksheetModal = false"
                           :table-type="tableType"></bulk-budget-worksheet>
    <remap-blueprints-modal :show-modal="showRemapBlueprintsModal"/>

    <product-catalog-preview :value="catalogPreview" @hide="hideProductCatalogPreview()" />

    <div id="manage-type-overlay" class="d-flex flex-column vh-100">

      <div class="flex-grow-1 bg-warning overflow-auto-y">
        <splitpanes :class="splitpanes.theme"
                    :dbl-click-splitter="false"
                    :style="`height: 100vh; max-height: 100vh;`"
                    @resized="resizePanes($event)">
          <!-- "NAV PANE" -->
          <pane :size="splitpanesSizes.nav" min-size="2" id="manage-pane-nav" :class="[splitpanes.classes.pane, { 'closed': splitpanesSizes.nav === this.splitpanes.minSideWidth }]">
            <!--<div :class="splitpanes.classes.stickyHeading"></div>-->

            <div class="manageHeaderNav d-flex align-items-center" :class="[(splitpanesSizes.nav === this.splitpanes.minSideWidth) ? 'justify-content-center' : 'justify-content-end']">
              <h1 :class="[splitpanes.classes.sideColumnHeading, { 'closed': splitpanesSizes.nav <= this.splitpanes.minSideWidth }]">
                <template v-if="splitpanesSizes.nav > this.splitpanes.minSideWidth">
                  <a href="#toggle" class="text-dark p-0" role="button" @click.prevent.stop="managePaneCollapse('nav', 'close', $event)">
                    <fluency-icon type="collapseLeft" />
                  </a>
                </template>
                <template v-else>
                  <a href="#toggle" class="text-dark p-0" role="button" @click.prevent.stop="managePaneCollapse('nav', 'open', $event)">
                    <fluency-icon type="collapseRight" />
                  </a>
                </template>
              </h1>
            </div>

            <div :class="splitpanes.classes.paneContent" class="position-relative">
              <template v-if="splitpanesSizes.nav > this.splitpanes.minSideWidth">
                <manage-navigation :modal-shown='modalShownTrigger'
                                   :class="['animate-width', 'navigation-pane', 'p-0', 'rounded-top bg-white',
                                   { 'col-0': collapsedSidebar, 'col-dynamic': !collapsedSidebar }]"
                                   :style="`height: calc(100vh - ${headerOffsetA}px)`" />
              </template>
            </div>
          </pane>
          <!-- TABLE PANE -->
          <pane :size="splitpanesSizes.table" min-size="2" :class="[splitpanes.classes.pane, 'd-flex']">
            <div :class="[splitpanes.classes.paneContent, 'd-flex', 'flex-grow-1', 'flex-column', 'position-relative', 'wacka']"
                 style="height: calc(100vh - 1px); max-height: calc(100vh - 1px);">

<!--              ROW 1-->

              <div class="manageHeaderNav w-100 d-flex justify-content-between">
                <breadcrumb-header class="pl-4 pt-1 align-self-start"
                                   :show-plan-status="showPlanStatus"/>

                <div class="d-flex align-self-center align-items-center">

                  <div v-if="item && item.accountPlanId && item.planType === 'account'">
                    <manage-report-definitions :selected-accounts="[item]" variant="link" no-caret toggle-class="p-0 no-focus">
                      <template #button-content>
                        <header-icon-notifications icon-type="document" class="dropdown-toggle" v-p-tooltip.bottom="'Reports'"/>
                      </template>
                    </manage-report-definitions>
                  </div>

                  <account-notifications/>

                  <preview-geos />

                  <div class="divider border-right d-block pr-2 mr-2" style="height: 30px"></div>

                  <header-icon-notifications icon-type="search"
                                             title="Search"
                                             class="jump-to-manage"
                                             v-p-tooltip.bottom="{ value: 'Jump to a Page\n(cmd + shift + j)', escape: false }"
                                             @click="$eventBus.$emit('openAccountJump')"></header-icon-notifications>
                  <fluency-support />

                  <header-icon-notifications icon-type="x"
                                             title="Close"
                                             class="pr-4 manage-modal-close-button"
                                             @click="showModal = false"
                  />
                </div>
              </div>

<!--              ROW 2-->

              <div v-if="item && planType" class="d-flex align-items-center py-4 justify-content-between">

                <div>
                  <div class="ml-4">
                    <fluency-loader v-if="$store.getters.loading.activeItem" color dots inline />
                    <small class="word-break-break-word" v-else>
                      <b>{{$filters.labelizePlanType(item.planType)}}:</b>
                      {{item.name}}
                    </small>
                  </div>
                  <metrics-settings-panel show-preview class="metrics-pane bg-white"/>
                </div>

                <div>
                  <small class="d-block ml-3 mb-4">{{$filters.labelizePlanType(item.planType)}} Actions</small>
                  <div class="d-flex align-items-center">
                    <div class="mr-1">
                      <action-icon variant="link-info"
                                   icon="settings"
                                   buttonText="Settings"
                                   :shortkey="{mac: ['meta','shift','s'], windows: ['ctrl','shift','s']}"
                                   :disabled="disableSettingsButton"
                                   :data-qa="disableSettingsButton ? undefined : `button-open-${item.planType}-settings`"
                                   disabled-cursor-type="cursor-wait"
                                   @click="openSettings(item)"
                                   @shortkey="openSettings(item)" />
                    </div>
                    <div v-if="AIChat" class="mr-1">
                      <action-icon variant="link-info"
                                   icon="muse"
                                   buttonText="Muse AI Insights"
                                   buttonClass="icon-hover-current-color"
                                   @click="openMuseDrawer(item)"/>
                    </div>
                    <toolbox-dropdown v-if="toolboxDropdown.show"
                                      :class="`${toolboxDropdown.type}Toolbox`"
                                      :listing-page="true"
                                      :manage-v2-listing-page="true"
                                      :plan-type="toolboxDropdown.type"
                                      :plan-id="toolboxDropdown.id"
                                      :account-plan-id="accountPlanId"
                                      :active-channels="activeChannels"
                                      buttonStyle
                                      @showSettingsReplicator="showCampaignSettingsReplicator"/>
                    <date-range-picker-global class="mx-3" opens="left"></date-range-picker-global>
                  </div>
                </div>
              </div>

<!--              TABLE-->

              <div class="w-100 mw-100 position-relative flex-grow-1 mr-5 ">
                <fluency-loader v-if="isPerformingBulkAction" class="p-5">Saving bulk changes, please wait...</fluency-loader>
                <template v-else>
                  <fluent-table     :fields="filteredFields"
                                    :primary-key="tableType === 'extension' ? 'extensionKey' : `${tableType}PlanId`"
                                    :items="tableItems"
                                    show-ai-chat-button
                                    :plan-type="tableType"
                                    :show-download-button="true"
                                    :column-save-key="`manage-${tableType}`"
                                    :per-page="50"
                                    @row-clicked="rowSingleClicked($event)"
                                    @name-clicked="drillIn($event)"
                                    @settings-clicked-drawer-pop="openSettings($event)"
                                    @open-analyze-campaign="handleManageTableAnalyzeCampaign($event)"
                                    @open-preview-geos="handleManageTablePreviewGeos($event)"
                                    @deleted="removeItem($event)"
                                    @saved="saveTableRowItem($event)"
                                    @saved-po="saveSubTableRowItem($event)"
                                    @save-status="bulkStatusChange([$event.item], $event.status, false, true)"
                                    @save-strategy="saveStrategy($event)"
                                    @row-details-toggled="rowDetailsToggled"
                                    @viewSimilarMetrics="viewSimilarMetrics($event)"
                                    @reload="reloadTableItems"
                                    @show-product-catalog-preview="showProductCatalogPreview($event)"
                                    :table-toolbar="tableToolbar"
                                    :sort-extension="metricsTableSorter"
                                    :shared-budget-totals="tableType === 'campaign'"
                                    :summable-columns="summableFields[tableType] || []"
                                    :is-inline-loading="isInlineLoading"
                                    numericColumnFilters
                                    class="border-top"
                                    table-action-ellipses
                                    >
                        <template v-if="canCreateDisplayAds || showAddTypeButton" #addNewButton>
                          <!-- TRIGGER CREATE SINGLE-->
                          <template v-if="showAddTypeButton && ['creative', 'keyword', 'extension'].includes(tableType)">
                            <b-button v-p-tooltip.top="`Create New ${$filters.labelizePlanType(tableType)}`"
                                      variant="link-info"
                                      @click="$store.commit('createSingle', tableType)">
                              <fluency-icon type="add" size="lg"/>
                            </b-button>
                          </template>

                          <!-- TRIGGER 'CREATE FLOW' -->
                          <nuxt-link v-else-if="showAddTypeButton"
                                     :to="`/manage/create/${getAddNewItemRoute(tableType)}`"
                                     v-p-tooltip.top="`Create New ${$filters.labelizePlanType(tableType)}`">
                            <b-button variant="link-info">
                              <fluency-icon type="add" size="lg"/>
                            </b-button>
                          </nuxt-link>

                          <nuxt-link v-else-if="canCreateDisplayAds"
                                     :to="`/manage/account/?id=${accountPlanId}&form=displayads&campaignId=${campaignPlanId}&adGroupId=${adGroupPlanId}`"
                                     class="px-3 position-relative"
                                     v-p-tooltip.top="'Create New Display Ad'">
                            <fluency-icon type="imageLandscape" size="lg"></fluency-icon>
                            <div class="position-absolute text-dark" style="top: -4px; left: 3px;">
                              <fluency-icon type="add" size="lg" />
                            </div>
                          </nuxt-link>
                        </template>

                        <template #left-nav>
                          <ul class="nav nav-clean ml-3">
                            <li v-for="(tab, tabIndex) in tableTabs"
                                :key="`metrics-table-tab-${$route.params.manageType}-${tabIndex}`"
                                :class="[`nav-item-${tab}`, 'nav-item']">
                              <nuxt-link :to="{query:{...$route.query, table: tab}}"
                                         :class="['nav-link', {'active': tab === tableType}]">
                                <b-button v-if="canTabBeDefaultUserPref(tab)"
                                          variant="link" class="p-0"
                                          v-p-tooltip.top="`Click if you prefer ${getDisplayTabType(tab)}`"
                                          @click.stop.prevent="userPrefDefaultTableTab = tab">
                                  <fluency-icon :type="tab === userPrefDefaultTableTab ? 'star' : 'emptyStar'"
                                                class="landing-tab-control-icon"
                                                :class="{'landing-tab-control-icon--active': tab === userPrefDefaultTableTab }"/>
                                </b-button>
                                {{ getDisplayTabType(tab) }}
                              </nuxt-link>
                            </li>
                            <li :class="[`nav-item-blank`, 'nav-item']">&nbsp;</li>
                          </ul>
                          <t-alert v-if="tableType !== 'campaign' && tableType !== 'adGroup' && tableType !== 'budget' && tableItems.length >= 5000" show variant="warning" class="mr-3">
                            Only showing the first 5,000 {{tableType}}s.
                            <info-tooltip popover variant="warning">
                              <template #title>
                                <p>
                                  If you're looking for something specific, try scoping down to a Campaign or Ad Group.
                                  If you need to perform bulk actions on the entire set, use <a href="/bulk/">Bulk Manage <fluency-icon type="skip" /></a>.
                                </p>
                              </template>
                            </info-tooltip>
                          </t-alert>
                        </template>

                        <template #additional-table-actions>
                          <b-dropdown-item v-if="tableType === 'creative' && item" class="p-0" @click="goToBulkEditCreative">
                            Bulk Edit
                          </b-dropdown-item>
                        </template>

                        <template #additionalFilter>
                          <div class="ml-3">
                            <mad-lib-select v-model="showPlanStatus"
                                            :options="['Show All Enabled', 'Show All But Removed', 'Show All']"
                                            variant="light"
                                            button>
                              <template #additional-content>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-text text-class="font-weight-normal">*Your selection will include items that contributed to performance metrics in the chosen time frame, regardless of their status.</b-dropdown-text>
                              </template>
                            </mad-lib-select>
                          </div>
                        </template>

                        <template #cell(extensionName)="row">
                          <extension-name-display :extension-plan="row.item"/>
                          <small v-if="row.item.shownBasedOnImpressions"
                                 class="text-muted"
                                 v-p-tooltip.top="{ value: 'This item is shown regardless of its status because it contributed to performance metrics during the selected time period.', showDelay: 1000 }">*Shown based on metrics</small>
                        </template>

                        <template #foot(extensionName)>
                          &nbsp;
                        </template>
                        <template #foot(extensionType)>
                          &nbsp;
                        </template>
                        <template #foot(extensionStatus)>
                          &nbsp;
                        </template>
                        <template #foot(contentTemplateName)>
                          &nbsp;
                        </template>
                        <template #foot(campaignPlanName)>
                          &nbsp;
                        </template>
                        <template #foot(adGroupPlanName)>
                          &nbsp;
                        </template>

                        <template #cell(extensionType)="row">
                          <template v-if="row.item.extensionType">
                            {{ $filters.convertFromCamelCase(row.item.extensionType.replace('Plan', '')) }}
                          </template>
                        </template>

                        <template #cell(extensionStatus)="row">
                          <extension-status-button-group :value="row.item"
                                                         :disabled="row.item.locationExtensionPlanId"
                                                         size="sm"
                                                         hide-lock
                                                         @input="row.item.enabled = $event.enabled; saveTableRowItem($event)"/>
                        </template>

                        <template #cell(campaignPlanName)="row">
                          <b-badge class="badge-campaign" style="font-size: 90%">{{row.value }}</b-badge>
                        </template>
                        <template #cell(adGroupPlanName)="row">
                          <b-badge class="badge-adGroup" style="font-size: 90%">{{row.value }}</b-badge>
                        </template>
                      </fluent-table>
                </template>
              </div>
            </div>
          </pane>
        </splitpanes>
      </div>
    </div>

    <create-single v-if="$store.getters.createSingle"></create-single>
  </div>
</template>

<script>
import ManageNavigation from '@/components/test/manageNavigation'
import MetricsSettingsPanel from '@/components/pages/manage/metricsSettingsPanel'
import { PartnerName, SharedBudgetUnsupportedBidStrategies } from '../../assets/js/constants'
import ManageReportDefinitions from '../../components/pages/manage/manageReportDefinitions'
import BreadcrumbHeader from '../../components/pages/manage/breadcrumbHeader'
import EditExtensionMixin from '../../components/pages/manage/extensions/editExtensionMixin'
import ManageLockedFieldsMixin from '@/mixins/manageLockedFieldsMixin'
import BudgetsMixin from '@/mixins/budgetsMixin'
import BulkSetDestinationUrlModal from '../../components/pages/manage/bulkSetDestinationUrlModal'
import PastePlans from '../../components/common/pastePlans'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import _ from 'lodash'
import Hierachy from '@/assets/js/hierarchy'
import RemapBlueprintsModal from '@/components/common/remapBlueprintsModal'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import ToolboxDropdown from '@/components/pages/manage/toolboxDropdown'
import ActionIcon from '@/components/pages/manage/actionIcon'
import AccountNotifications from '@/components/pages/manage/account/accountNotifications'
import PreviewGeos from '@/components/pages/manage/campaign/previewGeos'
import HeaderIconNotifications from '~/components/common/headerIconNotifications'
import FluencySupport from '@/components/common/fluencySupport'
import InfoTooltip from '@/components/common/forms/infoTooltip.vue'
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import ProductCatalogPreview from '@/components/pages/manage/productCatalogPreview.vue'
import { setActiveInterval } from 'core-ui/utils/setActiveInterval'
import DateRangePickerGlobal from '~/components/common/dateRangePickerGlobal.vue'
import { ValidChannels } from '~/assets/js/constants'
import { useCampaignNavigation } from '../../composables/useCampaignNavigation'
import ExtensionNameDisplay from '../../components/pages/manage/extensions/extensionNameDisplay.vue'
import ExtensionStatusButtonGroup from '../../components/pages/manage/extensions/extensionStatusButtonGroup.vue'
import BulkModifyBidModal from '../../components/pages/manage/bulkModifyBidModal.vue'
import BulkModifyBudgetModal from '../../components/pages/manage/bulkModifyBudgetModal.vue'
import BulkFindAndReplaceKeywordsModal from '../../components/pages/manage/bulkFindAndReplaceKeywordsModal.vue'
import ViewSimilarMetrics from '../../components/pages/manage/tableActions/viewSimilarMetrics.vue'
import DuplicatePlans from '../../components/pages/manage/duplicatePlans.vue'
import { defineAsyncComponent } from 'vue'
import { useActiveChildItems } from '../../composables/useActiveChildItems'
import { usePartnerPolling } from '../../composables/usePartnerPolling'
import MetricsSettingsPanelItem from '../../components/pages/manage/metricsSettingsPanelItem.vue'
import { currency } from 'core-ui/plugins/CoreUiFilters'
Splitpanes.compatConfig = { MODE: 2 }
Pane.compatConfig = { MODE: 2 }
export default {
  name: 'ManagePlanSettings',
  mixins: [EditExtensionMixin, ManageLockedFieldsMixin, BudgetsMixin],
  components: {
    DateRangePickerGlobal,
    ProductCatalogPreview,
    ActionIcon,
    InfoTooltip,
    FluencySupport,
    DuplicatePlans,
    HeaderIconNotifications,
    PreviewGeos,
    AccountNotifications,
    ToolboxDropdown,
    Splitpanes,
    Pane,
    RemapBlueprintsModal,
    CreateSingle: defineAsyncComponent(() => import('@/components/pages/manage/create/createSingle')),
    MadLibSelect,
    BulkBudgetWorksheet: defineAsyncComponent(() => import('@/components/pages/manage/budget/bulkBudgetWorksheet')),
    ViewSimilarMetrics,
    BulkFindAndReplaceKeywordsModal,
    FluencyLoader,
    BulkModifyBudgetModal,
    PastePlans,
    BulkSetDestinationUrlModal,
    BulkModifyBidModal,
    BreadcrumbHeader,
    ManageReportDefinitions,
    MetricsSettingsPanel,
    ManageNavigation,
    ExtensionNameDisplay,
    ExtensionStatusButtonGroup,
    MetricsSettingsPanelItem
  },
  layout ({ route }) {
    return route.query.tab ? 'contentOnly' : 'default'
  },
  setup () {
    const { refreshActiveChildItemsRef } = useActiveChildItems()
    const { addPendingWork, clearAllPendingWork } = usePartnerPolling()

    return {
      addPendingWork,
      clearAllPendingWork,
      refreshActiveChildItemsRef
    }
  },
  data () {
    return {
      isInlineLoading: false,
      inlineLoadingTimeoutId: null,
      splitpanes: {
        theme: 'default-theme',
        minSideWidth: 2, // %
        openToSize: {
          nav: 20,
          detail: 30
        },
        classes: {
          pane: 'overflow-auto bg-white',
          parentPane: 'bg-white',
          // paneContent: 'p-3',
          paneContent: '',
          stickyHeading: 'sticky-top d-flex bg-white',
          sideColumnHeading: 'side-col-heading mb-0 px-3'
        }
      },
      fetchFullPlanTypes: ['campaign', 'budget', 'adgroup', 'ad'],
      statsPaneSizes: ['none', 'even', 'full'],
      showModal: true,
      modalShownTrigger: false,
      settingsPaneOverflow: false,
      plan: null,
      selectorTest: 'showIncluded',
      showRunReport: false,
      contentTemplateOptions: [],
      showModifyBidModal: false,
      bulkBudget: {
        showModal: false,
        type: ''
      },
      showDestinationUrlModal: false,
      bulkDataCommand: {},
      openBulkPasteDialogCounter: 0,
      bulkEditDialog: {
        component: '',
        show: false,
        propsOverride: {}
      },
      showFindAndReplaceKeywordsModal: false,
      isPerformingBulkAction: false,
      summableFields: {
        budget: ['budgetAmount', 'lifetimeBudget', 'budgetCurrentTotalSpend', 'totalLifetimeCost', 'nextBudgetAmount', 'pacing', 'projectedOverspend', 'conversions', 'callConversions', 'webConversions', 'storeConversions', 'pageViewConversions', 'otherConversions', 'optimizedDailyAmount', 'spendYesterday'],
        campaign: ['budget', 'lifetimeBudget', 'cost', 'spendYesterday', 'totalLifetimeCost', 'impressions', 'impressionShare', 'clicks', 'clickThroughRate', 'costPerClick', 'conversions', 'callConversions', 'webConversions', 'storeConversions', 'pageViewConversions', 'otherConversions', 'conversionRate', 'costPerConversion', 'uniqueImpressions', 'views', 'costPerLandingPageView', 'videoViews', 'leads', 'webLeads', 'platformLeads', 'costPerLead', 'engagement', 'frequency', 'reach', 'uniqueClicks', 'uniqueClickThroughRate', 'uniqueOutboundClicks', 'views', 'uniqueLandingPageViews', 'uniqueLandingPageViewRate', 'costPerUniqueLandingPageView', 'costPerThousandImpressions', 'costPerThousandReach', 'leadReachRate'],
        adGroup: ['bid', 'budgetWeight', 'cost', 'spendYesterday', 'impressions', 'clicks', 'clickThroughRate', 'costPerClick', 'conversions', 'callConversions', 'webConversions', 'storeConversions', 'pageViewConversions', 'otherConversions', 'conversionRate', 'costPerConversion', 'uniqueImpressions', 'views', 'costPerLandingPageView', 'videoViews', 'leads', 'webLeads', 'platformLeads', 'costPerLead', 'engagement', 'frequency', 'reach', 'uniqueClicks', 'uniqueClickThroughRate', 'uniqueOutboundClicks', 'views', 'uniqueLandingPageViews', 'uniqueLandingPageViewRate', 'costPerUniqueLandingPageView', 'costPerThousandImpressions', 'costPerThousandReach', 'leadReachRate'],
        keyword: ['cost', 'spendYesterday', 'impressions', 'clicks', 'clickThroughRate', 'costPerClick', 'conversions', 'callConversions', 'webConversions', 'storeConversions', 'pageViewConversions', 'otherConversions', 'conversionRate', 'costPerConversion'],
        creative: ['cost', 'spendYesterday', 'impressions', 'clicks', 'clickThroughRate', 'costPerClick', 'conversions', 'callConversions', 'webConversions', 'storeConversions', 'pageViewConversions', 'otherConversions', 'conversionRate', 'costPerConversion', 'uniqueImpressions', 'views', 'costPerLandingPageView', 'videoViews', 'leads', 'webLeads', 'platformLeads', 'costPerLead', 'engagement', 'frequency', 'reach', 'uniqueClicks', 'uniqueClickThroughRate', 'uniqueOutboundClicks', 'views', 'costPerLandingPageView', 'uniqueLandingPageViews', 'uniqueLandingPageViewRate', 'costPerUniqueLandingPageView', 'costPerThousandImpressions', 'costPerThousandReach', 'leadReachRate'],
        extension: ['cost', 'spendYesterday', 'impressions', 'clicks', 'clickThroughRate', 'costPerClick', 'conversions', 'conversionRate', 'costPerConversion']
      },
      viewSimilarMetricsObject: {},
      showViewSimilarMetricsModal: false,
      showBulkBudgetWorksheetModal: false,
      tableFilter: '',
      showRemapBlueprintsModal: false,
      activeItemAncestry: {},
      catalogPreview: null,
      tableType: 'budget',
      tableTabs: [],
      pinterestFields: [
        { slot: 'repins', validChannels: [25] },
        { slot: 'pinClicks', validChannels: [25] },
        { slot: 'webSessions', validChannels: [25] },
        { slot: 'repinRate', validChannels: [25] },
        { slot: 'engagementRate', validChannels: [25] }
      ],
      automotiveAttribution: [
        { key: 'attr_forms', requiredCapabilities: ['AutomotiveAttributionData'], label: 'Attr. Forms', formatter: (value, key, item) => this.$filters.number(item?.metricsDTO?.additionalMetrics?.attr_forms, 1, true), filterByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_forms, sortByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_forms, tdClass: 'text-right' },
        { key: 'attr_visits', requiredCapabilities: ['AutomotiveAttributionData'], label: 'Attr. Visits', formatter: (value, key, item) => this.$filters.number(item?.metricsDTO?.additionalMetrics?.attr_visits, 1, true), filterByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_visits, sortByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_visits, tdClass: 'text-right' },
        { key: 'attr_vdp', requiredCapabilities: ['AutomotiveAttributionData'], label: 'Attr. VDP Views', formatter: (value, key, item) => this.$filters.number(item?.metricsDTO?.additionalMetrics?.attr_vdp, 1, true), filterByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_vdp, sortByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_vdp, tdClass: 'text-right' },
        { key: 'attr_vlp', requiredCapabilities: ['AutomotiveAttributionData'], label: 'Attr. VLP Views', formatter: (value, key, item) => this.$filters.number(item?.metricsDTO?.additionalMetrics?.attr_vlp, 1, true), filterByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_vlp, sortByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_vlp, tdClass: 'text-right' },
        { key: 'attr_bounces', requiredCapabilities: ['AutomotiveAttributionData'], label: 'Attr. Bounces', formatter: (value, key, item) => this.$filters.number(item?.metricsDTO?.additionalMetrics?.attr_bounces, 1, true), filterByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_bounces, sortByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_bounces, tdClass: 'text-right' }
      ],
      facebookFields: [
        { slot: 'outboundClicks', validChannels: [2] },
        { slot: 'costPerOutboundClick', validChannels: [2] },
        { slot: 'outboundClickRate', validChannels: [2] },
        { slot: 'inlineClicks', validChannels: [2] },
        { slot: 'costPerInlineClick', validChannels: [2] },
        { slot: 'inlineClickRate', validChannels: [2] },
        { slot: 'contentViews', validChannels: [2] },
        { slot: 'costPerContentView', validChannels: [2] },
        { slot: 'contentViewsWebsite', validChannels: [2] },
        { slot: 'costPerContentViewWebsite', validChannels: [2] },
        { slot: 'purchases', validChannels: [2] },
        { slot: 'costPerPurchase', validChannels: [2] },
        { slot: 'purchasesWebsite', validChannels: [2] },
        { slot: 'costPerPurchaseWebsite', validChannels: [2] },
        { slot: 'addToCarts', validChannels: [2] },
        { slot: 'costPerAddToCart', validChannels: [2] },
        { slot: 'addToCartsWebsite', validChannels: [2] },
        { slot: 'costPerAddToCartWebsite', validChannels: [2] },
        { slot: 'initiateCheckouts', validChannels: [2] },
        { slot: 'costPerInitiateCheckout', validChannels: [2] },
        { slot: 'initiateCheckoutsWebsite', validChannels: [2] },
        { slot: 'costPerInitiateCheckoutWebsite', validChannels: [2] },
        { slot: 'pageEngagement', validChannels: [2] },
        { slot: 'pageLikes', validChannels: [2] },
        { slot: 'postEngagement', validChannels: [2] }
      ]
    }
  },
  // test http://localhost:3000/manage/campaign/?id=662681&table=adGroup&fsEnv=canary&settingsItemId=519297&settingsItemType=budget&form=basic
  mounted () {
    // onActivated is also called on mount, and onDeactivated on unmount.
    if (this.showPlanStatus === '') {
      let showPlanStatus = 'Show All But Removed'
      if (localStorage && localStorage.getItem('showPlanStatus')) {
        showPlanStatus = localStorage.getItem('showPlanStatus')
      }
      this.showPlanStatus = showPlanStatus
    }
    this.fetchMiniBlueprints()
    this.$eventBus.$on('bv::modal::hide', this.setPreventEscapeCloseTrue)
    document.addEventListener('keyup', this.keyUpHandler)
  },
  beforeUnmount () {
    this.$eventBus.$off('bv::modal::hide', this.setPreventEscapeCloseTrue)
    if (this.$poller.blueprintPoller) {
      clearInterval(this.$poller.blueprintPoller)
      this.$poller.blueprintPoller = null
    }
    // moved some of the cleanup stuff to the showModal watcher
  },

  unmounted () {
    document.removeEventListener('keyup', this.keyUpHandler)
  },
  watch: {
    isManageEligible: {
      handler: function (val) {
        if (val === false) {
          this.$router.push({ path: '/error', query: { code: 'MISSING_CAPABILITY', redirect: 'welcome' } })
        }
      },
      immediate: true
    },
    itemPendingWork (newValue) {
      // if the primary active item has pending work attached to it (i.e. the selected plan) start polling
      if (newValue && typeof newValue !== 'undefined' && newValue.length > 0) {
        this.addPendingWork(newValue)
      }
    },
    '$store.getters.timeRange' () {
      this.reloadTableMetrics()
    },
    '$store.getters.timeRangeCompare' () {
      this.reloadTableMetrics()
    },
    statsPaneSize (val) {
      this.$nextTick(() => {
        this.$eventBus.$emit('resizeManagePanels', val)
      })
    },
    '$route.query.table': {
      immediate: true,
      handler (table, old) {
        let tableType = table
        if (!tableType) {
          tableType = this.userPrefDefaultTableTab || 'budget'
        }
        if (tableType === 'adgroup') {
          tableType = 'adGroup'
        }
        if (this.tableType !== tableType) {
          this.clearAllPendingWork() // clear pending work, so that we aren't trying to refresh creative job status after switching to the campaign tab
          this.tableType = tableType
        }
      }
    },
    showModal (val) {
      if (val === false) {
        this.hideModal()
        if (this.$store.getters.activeAccountBudgets?.length > 0) {
          this.$store.commit('setActiveBudgets', [])
        }
        this.$store.commit('resetActiveChildItems')
        this.$store.commit('setActiveAccountNotifications', null)
        this.clearAllPendingWork()
        useCampaignNavigation().clearCampaignNavigation()
      } else {
        this.modalShownTrigger = true
      }
    },
    accountPlanId (newVal, oldVal) {
      this.clearAllPendingWork()
      // this.$store.dispatch('cancelPartnerPollingInterval')
      this.blueprintPollFetch()
    },
    '$route.query.tableFilter': {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal?.length > 0) {
          const filterString = decodeURI(newVal)

          const queryIndex = filterString.indexOf('q=')
          if (queryIndex > 0) {
            let commaIndex = filterString.indexOf(',', queryIndex)
            commaIndex = commaIndex < 0 ? filterString.length : commaIndex
            if (commaIndex > 0 && commaIndex - queryIndex + 2 >= 1) {
              this.tableFilter = filterString.substring(queryIndex + 2, commaIndex)
            }
          }
        }
      }
    },
    blueprintPollInterval: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.$poller.blueprintPoller) {
            clearInterval(this.$poller.blueprintPoller)
            this.$poller.blueprintPoller = null
          }
          const interval = setActiveInterval(this.blueprintPollFetch, newVal)
          this.$poller.blueprintPoller = interval
        }
      }
    },
    itemIdAndPlanType: {
      immediate: true,
      handler (value, old) {
        if (value && value !== old) {
          this.tableTabs = this.getTableTabsForItem(this.item)
        }
      }
    }
  },
  computed: {
    bionicSwitcher () {
      return this.$store.getters?.appForksBionicLayout
    },
    isManageEligible () {
      return this.user.capabilities?.Manage
    },
    isBlueprintsEligible () {
      return this.user.capabilities?.Blueprints
    },
    user () {
      return this.$store.getters.user
    },
    settingsItem () {
      return this.$store.getters.settingsItem
    },
    settingsItemType () {
      return this.$route.query.settingsItemType || this.$route.params.manageType
    },
    filteredFields () {
      return this.fields[this.tableType]
        ? this.fields[this.tableType].filter(f => {
          if (!f) return false
          let valid = true
          if (f.validChannels) {
            valid = f.validChannels.reduce((accu, curr) => this.doesPlanHaveAdvertisingChannel(curr) ? true : accu, false)
          }
          if (valid && f.requiredCapabilities) {
            valid = f.requiredCapabilities.every((cap) => this.user?.capabilities?.[cap])
          }
          return valid
        })
        : undefined
    },
    toolboxDropdown () {
      const returnObj = {
        show: false,
        type: this.planType?.toLowerCase()
      }

      switch (this.planType) {
        case 'account':
          returnObj.id = this.accountPlanId
          break
        case 'budget':
          returnObj.id = parseInt(this.$route.query.id)
          break
        case 'campaign':
          returnObj.id = this.campaignPlanId
          break
        case 'adGroup':
          returnObj.id = this.adGroupPlanId
          break
        case 'creative':
          returnObj.id = parseInt(this.$route.query.id)
          break
        case 'keyword':
          returnObj.id = parseInt(this.$route.query.id)
          break
      }

      if (returnObj.id && !this.$route.query?.settingsItemId) {
        // hiding toolbox when manage drawer is open because there is another instance inside the drawer and two on the page at once interfere with each other
        returnObj.show = true
      }

      return returnObj
    },
    headerHeight () {
      return this.$store.getters.getHeaderHeight
    },
    headerOffsetA () {
      return this.$store.getters.getHeaderHeight
    },
    headerOffsetB () {
      return this.$store.getters.getHeaderHeight + 65
    },
    whatFixEnabled () {
      return this.$store.getters.user?.capabilities?.WhatFix
    },
    splitpanesSizes: {
      get: function () {
        return this.$store.getters.splitpanesManageDetails
      },
      set: function (obj) {
        this.$store.commit('splitpanes', { key: 'manageDetails', value: obj })
      }
    },
    accountPlanId () {
      return this.$store.getters.activeItem?.accountPlanId
    },
    campaignPlanId () {
      return this.$store.getters.activeItem?.campaignPlanId
    },
    adGroupPlanId () {
      return this.$store.getters.activeItem?.adGroupPlanId
    },
    activeChannels () {
      if (this.item?.advertisingChannelId) {
        return [this.item?.advertisingChannelId]
      }

      return this.item?.activeChannels
    },
    displayCampaignGroup () {
      return (this.$store.getters.user.capabilities?.CampaignGroups || this.item?.capabilities?.CampaignGroups) ?? false
    },
    displayAdGroupBidColumn () {
      if (this.campaignPlanId) {
        return this.item?.capabilities?.AdGroupBid
      }
      return true
    },
    canCreateDisplayAds () {
      if (this.accountPlanId && this.campaignPlanId && this.adGroupPlanId && this.planType === 'adGroup') {
        if (this.item?.campaignPlan?.capabilities?.DisplayAds) { // check that parent CampaignPlan has capability
          return true
        }
      }
      return false
    },
    displayProjectedOverUnderSpendColumn () {
      return this.$store.getters.user.capabilities?.ProjectedOverspend ?? false
    },
    selectedPlans () {
      const type = this.tableType === 'creative' ? 'ad' : this.tableType
      return this.$store.getters['table/selectedRowObjs'](type) || []
    },
    collapsedSidebar: {
      get () {
        return this.$store.getters.manageNavigationCollapsed
      },
      set (newVal) {
        this.$store.commit('setting', { key: 'manageNavigationCollapsed', value: newVal })
      }
    },
    blueprints () {
      return this.$store.getters.blueprints // fyi, this is min blueprints
    },
    isInternalUser () {
      return this.$store.getters?.user?.internalUser
    },
    showBulkManage () {
      return this.$store.getters.user?.capabilities?.BulkManage
    },
    tableToolbar () {
      const selectedPlanAdvertisingChannels = this.selectedPlans
        ?.filter(p => !!p)
        .map(p => p.advertisingChannelId)
        .distinct() ?? []
      const isOnlyDDC = this.DDCSpecialChannels(selectedPlanAdvertisingChannels)
      let applicableBudgets = []
      let selectedPlansNotCompatible = []
      if (selectedPlanAdvertisingChannels.length === 1 || isOnlyDDC) {
        applicableBudgets = this.filterSharedChannelBudgets(this.budgets, selectedPlanAdvertisingChannels[0]) // from budgetsMixin
        if (applicableBudgets.length > 0) {
          selectedPlansNotCompatible = this.selectedPlans
            .filter(plan => SharedBudgetUnsupportedBidStrategies.includes(plan.biddingStrategy))
        }
      }
      const toolbarSetup = {
        budget: [
          {
            type: 'expandCollapse'
          },
          {
            label: 'Remove',
            type: 'remove-budget',
            action: this.bulkOperation
          },
          {
            label: 'Modify',
            type: 'edit-budget',
            action: this.modifyBudgetsModal,
            deselect: false
          },
          {
            label: 'Read Only Mode',
            type: 'dropdown',
            dropdownItems: [
              { label: 'Turn ON read only mode', type: 'budget-read-only-enable', action: this.bulkOperation },
              { label: 'Turn OFF read-only mode', type: 'budget-read-only-turnOFF', action: this.bulkOperation }
            ]
          },
          ...this.showBulkManage
            ? [{
                label: 'Bulk Manage',
                type: 'bulk-edit',
                action: this.advancedBulkEdit
              }]
            : []
        ],
        campaign: [
          {
            type: 'expandCollapse'
          },
          {
            label: 'Status',
            type: 'dropdown',
            dropdownItems: [
              { label: 'Enable', type: 'status-enabled', action: this.bulkOperation },
              { label: 'Pause', type: 'status-paused', action: this.bulkOperation },
              { label: 'Remove', type: 'status-removed', action: this.bulkOperation }
            ]
          },
          {
            label: 'Assign Budget',
            type: 'dropdown',
            dropdownItems: [
              selectedPlanAdvertisingChannels.length > 1 && !isOnlyDDC && {
                label: 'The selection includes multiple advertising channels. Shared Budgets are specific to one advertising channel.',
                type: 'dropdown-alert'
              },
              applicableBudgets.length === 0 && (selectedPlanAdvertisingChannels.length === 1 || isOnlyDDC) && {
                label: 'There are no active shared budgets within this advertising channel.',
                type: 'dropdown-alert'
              },
              selectedPlansNotCompatible.length > 0 && {
                label: this.writeSharedBudgetMessage(selectedPlansNotCompatible),
                type: 'dropdown-alert'
              },
              ...applicableBudgets
                .map(budget => {
                  return {
                    label: budget.name,
                    type: 'budget-assign',
                    action: this.bulkOperation,
                    extraInfo: budget,
                    selectedPlansNotCompatible
                  }
                })
            ].filter(p => p)
          },
          // {
          //   label: 'Set Tracking Template',
          //   type: 'campaign-tracking-template',
          //   action: this.bulkOperation
          // },
          {
            label: 'Duplicate',
            type: 'campaign-duplicate',
            action: this.bulkModal,
            useModal: 'duplicatePlans'
          },
          {
            label: 'Copy',
            type: 'campaign-copy',
            action: this.copyPlans
          },
          {
            label: 'Paste',
            type: 'campaign-paste',
            action: this.bulkModal,
            useModal: 'pastePlans'
          },
          {
            label: 'Modify Keywords',
            type: 'campaign-keyword-replace',
            action: this.findAndReplaceKeywords,
            shortkey: ['meta', 'shift', 'k'],
            deselect: false
          },
          {
            label: 'Read-Only',
            type: 'dropdown',
            dropdownItems: [
              { label: 'Turn On', type: 'read-only-enable', action: this.bulkOperation },
              { label: 'Turn Off', type: 'read-only-disable', action: this.bulkOperation }
            ],
            deselect: false
          },
          ...this.showBulkManage
            ? [{
                label: 'Bulk Manage',
                type: 'bulk-edit',
                action: this.advancedBulkEdit
              }]
            : [],
          {
            label: 'Republish Blueprints',
            type: 'campaign-republish-blueprints',
            action: this.bulkOperation
          }
        ],
        adGroup: [
          {
            type: 'expandCollapse'
          },
          {
            label: 'Status',
            type: 'dropdown',
            dropdownItems: [
              { label: 'Enable', type: 'status-enabled', action: this.bulkOperation },
              { label: 'Pause', type: 'status-paused', action: this.bulkOperation },
              { label: 'Remove', type: 'status-removed', action: this.bulkOperation }
            ]
          },
          {
            label: 'Set Destination URL',
            type: 'destination-url',
            action: this.bulkModal,
            extraInfo: 'adGroup'
          },
          // {
          //   label: 'Set Tracking Template',
          //   type: 'ad-group-tracking-template',
          //   action: this.bulkOperation
          // },
          {
            label: 'Duplicate',
            type: 'ad-group-duplicate',
            action: this.bulkModal,
            useModal: 'duplicatePlans'
          },
          {
            label: 'Copy',
            type: 'ad-group-copy',
            action: this.copyPlans
          },
          {
            label: 'Paste',
            type: 'ad-group-paste',
            action: this.bulkModal,
            useModal: 'pastePlans'
          },
          {
            label: 'Modify Keywords',
            type: 'ad-group-keyword-replace',
            action: this.findAndReplaceKeywords,
            shortkey: ['meta', 'shift', 'k'],
            deselect: false
          },
          ...this.showBulkManage
            ? [{
                label: 'Bulk Manage',
                type: 'bulk-edit',
                action: this.advancedBulkEdit
              }]
            : [],
          {
            label: 'Republish Blueprints',
            type: 'campaign-republish-blueprints',
            action: this.bulkOperation
          },
          ...this.isInternalUser
            ? [{
                label: 'Blueprint Tweaks',
                type: 'blueprint-tweaks',
                action: this.remapBlueprints
              }]
            : []
        ],
        keyword: [
          {
            type: 'expandCollapse'
          },
          {
            label: 'Status',
            type: 'dropdown',
            dropdownItems: [
              { label: 'Enable', type: 'status-enabled', action: this.bulkOperation },
              { label: 'Pause', type: 'status-paused', action: this.bulkOperation },
              { label: 'Remove', type: 'status-removed', action: this.bulkOperation }
            ]
          },
          {
            label: 'Copy',
            type: 'keyword-copy',
            action: this.copyPlans
          },
          {
            label: 'Bulk Modify',
            type: 'modify-keyword',
            action: this.findAndReplaceKeywords,
            shortkey: ['meta', 'shift', 'k'],
            deselect: false
          },
          ...this.showBulkManage
            ? [{
                label: 'Bulk Manage',
                type: 'bulk-edit',
                action: this.advancedBulkEdit
              }]
            : [],
          {
            label: 'Republish Blueprints',
            type: 'campaign-republish-blueprints',
            action: this.bulkOperation
          }
        ],
        creative: [
          {
            type: 'expandCollapse'
          },
          {
            label: 'Status',
            type: 'dropdown',
            dropdownItems: [
              { label: 'Enable', type: 'status-enabled', action: this.bulkOperation },
              { label: 'Pause', type: 'status-paused', action: this.bulkOperation },
              { label: 'Remove', type: 'status-removed', action: this.bulkOperation }
            ]
          },
          {
            label: 'Set Destination URL',
            type: 'destination-url',
            action: this.bulkModal,
            extraInfo: 'creative'
          },
          {
            label: 'Duplicate',
            type: 'creative-duplicate',
            action: this.bulkModal,
            useModal: 'duplicatePlans'
          },
          {
            label: 'Copy',
            type: 'creative-copy',
            action: this.copyPlans
          },
          {
            label: 'Paste',
            type: 'creative-paste',
            action: this.bulkModal,
            useModal: 'pastePlans'
          },
          ...this.showBulkManage
            ? [{
                label: 'Bulk Manage',
                type: 'bulk-edit',
                action: this.advancedBulkEdit
              }]
            : [],
          {
            label: 'Republish Blueprints',
            type: 'campaign-republish-blueprints',
            action: this.bulkOperation
          }
        ],
        extension: [
          {
            label: 'Copy',
            type: 'extension-copy',
            action: this.copyPlans
          }
        ]
      }

      return toolbarSetup[this.tableType]
    },
    statsPaneSize: {
      get () {
        return this.$store.getters.manageStatsPaneSize
      },
      set (val) {
        this.$store.commit('setting', { key: 'manageStatsPaneSize', value: val })
      }
    },
    toggleFilters () {
      return this.$store.getters.tableToggleFilters
    },
    filteredAccountBudgets () {
      let budgets = this.$store.getters.activeAccountBudgets
      if (this.toggleFilters?.budget?.explicitlyShared) {
        budgets = budgets?.filter(b => b.explicitlyShared) || []
      }
      if (this.toggleFilters?.budget?.showActiveOnly) {
        budgets = budgets?.filter(b => !b.inactive) || []
      }
      if (this.showPlanStatus === 'Show All Enabled') {
        budgets = budgets?.filter(b => b.campaignPlans?.length > 0 || b.budgetPlanId === this.item.budgetPlanId) || []
      }
      return budgets
    },
    displayTableType () {
      return this.$filters.convertFromCamelCase(this.tableType)
    },
    fields () {
      // be careful changing these columns make sure you look at the splices below first
      const fields = {
        budget: [
          'selectRow',
          { slot: 'budgetShared', label: 'Budget Shared', defaultWidth: 25, filter: true, filterOptions: [[{ text: 'Shared', value: true }, { text: 'Explicit', value: false }]] },
          { slot: 'budgetName', filter: true, filterOptions: ['string'] },
          { slot: 'channels', filter: true, filterOptions: [this.getTablePartners] },
          { slot: 'budgetChannel', filter: true, filterOptions: [this.getTableChannels], thClass: 'text-left', tdClass: 'text-left' },
          'servingStatus',
          (this.displayCampaignGroup)
            ? {
                slot: 'campaignGroupName',
                slotOption: 'editable',
                filter: true,
                filterOptions: [this.getTableValues]
              }
            : null,
          { slot: 'budgetType', filter: true, filterOptions: [this.getTableValues] },
          {
            slot: 'budgetAmount',
            formatter: (value, key, item) => item?.budgetType === 'LIFETIME' ? 0 : value,
            sortByFormatted: true,
            filterByFormatted: true
          },
          'nextBudgetAmount',
          'budgetCurrentTotalSpend',
          'optimizedDailyAmount',
          'spendYesterday',
          { slot: 'pacing', slotOption: 'bar' },
          'profileAdjustedPacing',
          (this.displayProjectedOverUnderSpendColumn)
            ? { key: 'projectedOverspend', label: 'Projected Over/Under Spend', sortable: true, filter: true, summable: { sum: true, average: true, format: 'number' }, formatter: (val, key, item) => isNaN(val) || !val || val === 'Infinity' ? ' - ' : this.$filters.currency(val, 2, item ? { code: item.currencyCode || item._currencyCode } : {}) }
            : null,
          { slot: 'lifetimeBudget', slotOption: 'editable' },
          { slot: 'totalLifetimeCost', label: 'All-time Flighted Spend' },
          'impressionShare',
          'avgLostToBudget',
          'avgLostToRank',
          'callConversions',
          'webConversions',
          'storeConversions',
          'pageViewConversions',
          'otherConversions',
          'budgetSpendSoFar',
          'camp',
          { slot: 'budgetBiasStrategy', filter: true, filterOptions: [this.getTablePacingStrategies], filterFunction: this.pacingStratFilter },
          'allowReallocation',
          'bidAdjustmentPercentageOnUnderspend',
          'autoRemoveBlockingKeywordOnUnderspend',
          'useBroadMatchOnUnderspend',
          'openAdSchedulesOnUnderspend',
          'geoTargetExpansionAbsoluteMaxInMiles',
          'proratePartialMonth',
          'clicks',
          'impressions',
          'costPerThousandImpressions',
          'conversions',
          'costPerClick',
          'clickThroughRate',
          'costPerConversion',
          'conversionRate',
          'frequency',
          'reach',
          'costPerThousandReach',
          'views',
          'costPerLandingPageView',
          'videoViews',
          'costPerVideoView',
          'thruplays',
          'costPerThruplay',
          'leads',
          'costPerLead',
          'webLeads',
          'costPerWebLead',
          'platformLeads',
          'costPerPlatformLead',
          'leadReachRate',
          'paceToMetric',
          'paceToMetricValue',
          'metricPacing',
          ...this.facebookFields,
          ...this.pinterestFields
        ],
        campaign: [
          'selectRow',
          { slot: 'name', slotOption: 'editable', filter: true, filterOptions: ['string'] },
          { slot: 'channels', filter: true, filterOptions: [this.getTablePartners] },
          { slot: 'campaignChannel', filter: true, filterOptions: [this.getTableChannels] },
          'budgetManagerPaused',
          'servingStatus',
          { slot: 'optimizationScore', validChannels: [1] },
          { slot: 'stat', filter: true, filterOptions: [this.getTableStatuses] },
          { slot: 'budgetType', filter: true, filterOptions: [this.getTableBudgetTypes], filterFunction: this.budgetTypeFilter },
          this.isBlueprintsEligible ? { slot: 'contentTemplateName', slotOption: this.contentTemplateOptions } : null,
          { slot: 'budget', slotOption: 'editable' },
          this.displayCampaignGroup ? { slot: 'campaignGroupName', slotOption: 'editable', filter: true, filterOptions: [this.getTableValues] } : null,
          { slot: 'biddingStrategy', filter: true, filterOptions: [this.getTableValues] },
          'cost',
          'spendYesterday',
          'lifetimeBudget',
          'totalLifetimeCost',
          'impressions',
          'costPerThousandImpressions',
          'impressionShare',
          'avgLostToBudget',
          'avgLostToRank',
          'clicks',
          'clickThroughRate',
          'costPerClick',
          'conversions',
          'callConversions',
          'webConversions',
          'storeConversions',
          'pageViewConversions',
          'otherConversions',
          { key: 'attr_calls', requiredCapabilities: ['AutomotiveAttributionData'], label: 'Attr. Calls', formatter: (value, key, item) => this.$filters.number(item?.metricsDTO?.additionalMetrics?.attr_calls, 1, true), filterByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_calls, sortByFormatted: (value, key, item) => item?.metricsDTO?.additionalMetrics?.attr_calls, tdClass: 'text-right' },
          ...this.automotiveAttribution,
          'conversionRate',
          'costPerConversion', 'totalConversionValue', 'totalConversionValuePerCost', 'totalConversionValuePerClick',
          'uniqueImpressions',
          'views',
          'costPerLandingPageView',
          'videoViews',
          'costPerVideoView',
          'thruplays',
          'costPerThruplay',
          'leads',
          'costPerLead',
          'webLeads',
          'costPerWebLead',
          'platformLeads',
          'costPerPlatformLead',
          'leadReachRate',
          'engagement',
          'frequency',
          'reach',
          'costPerThousandReach',
          ...this.facebookFields,
          'uniqueClicks',
          'uniqueClickThroughRate',
          'uniqueOutboundClicks',
          'uniqueLandingPageViews',
          'uniqueLandingPageViewRate',
          'costPerUniqueLandingPageView',
          'attachedFeeds',
          {
            slot: 'campaignLabels',
            filter: true,
            filterOptions: [this.getTableCampaignLabels],
            filterFunction: (items, filter, key) => {
              let filtered = items
              const labelsToCheck = filter.checkboxValues

              filtered = filtered.filter(item => {
                if (labelsToCheck.includes('No Label') && (!item[key] || item[key].length < 1)) {
                  return true
                }
                return item[key]?.some(label => labelsToCheck.includes(label))
              })

              return filtered
            }
          },
          'startDate',
          'endDate',
          ...this.pinterestFields
        ],
        adGroup: [
          'selectRow',
          { slot: 'name', slotOption: 'editable', filter: true, filterOptions: ['string'] },
          { slot: 'channels', filter: true, filterOptions: [this.getTablePartners] },
          'servingStatus',
          { slot: 'stat', filter: true, filterOptions: [this.getTableStatuses] },
          { slot: 'contentTemplateName', slotOption: this.contentTemplateOptions },
          { key: 'campaignPlanName', filter: true, filterOptions: ['string'], inherit: true },
          this.displayAdGroupBidColumn ? 'bidAmount' : null,
          'budgetWeight',
          'cost',
          'spendYesterday',
          'impressions',
          'costPerThousandImpressions',
          'clicks',
          'clickThroughRate',
          'costPerClick',
          'conversions',
          'callConversions',
          'webConversions',
          'storeConversions',
          'pageViewConversions',
          'otherConversions',
          ...this.automotiveAttribution,
          'conversionRate',
          'costPerConversion',
          'totalConversionValue',
          'totalConversionValuePerCost',
          'totalConversionValuePerClick',
          // 'position',
          'uniqueImpressions',
          'views',
          'costPerLandingPageView',
          'videoViews',
          'costPerVideoView',
          'thruplays',
          'costPerThruplay',
          'leads',
          'costPerLead',
          'webLeads',
          'costPerWebLead',
          'platformLeads',
          'costPerPlatformLead',
          'leadReachRate',
          'engagement',
          'frequency',
          'reach',
          'costPerThousandReach',
          ...this.facebookFields,
          'uniqueClicks',
          'uniqueClickThroughRate',
          'uniqueOutboundClicks',
          'uniqueLandingPageViews',
          'uniqueLandingPageViewRate',
          'costPerUniqueLandingPageView',
          'attachedFeeds',
          {
            slot: 'adGroupLabels',
            filter: true,
            filterOptions: [this.getTableAdGroupLabels],
            filterFunction: (items, filter, key) => {
              let filtered = items
              const labelsToCheck = filter.checkboxValues

              filtered = filtered.filter(item => {
                if (labelsToCheck.includes('No Label') && (!item[key] || item[key].length < 1)) {
                  return true
                }
                return item[key]?.some(label => labelsToCheck.includes(label))
              })
              return filtered
            }
          },
          'startDate',
          'endDate',
          { key: 'deliveryEstimateStatus', label: 'Audience Definition', filter: true, filterOptions: [this.getTableValues], sortable: true, formatter: (value, key, item) => this.$filters.convertFromCapsUnderscore(value) },
          { key: 'deliveryEstimateLowerBound', label: 'Estimated Size', filter: true, filterOptions: ['numeric'], defaultWidth: 120, sortable: true, formatter: (value, key, item) => this.$filters.number(item.deliveryEstimateLowerBound, 0, true) + ' - ' + this.$filters.number(item.deliveryEstimateUpperBound, 0, true), tooltip: 'Estimated audience size is an estimate of how many people may meet your targeting criteria. It is based on factors such as targeting selections, ad placements and how many people were shown ads on Meta apps and services in the past 30 days.' },
          ...this.pinterestFields
        ],
        keyword: [
          'selectRow',
          { slot: 'keywordText', filter: true, filterOptions: ['string'] },
          { slot: 'channels', filter: true, filterOptions: [this.getTablePartners], thClass: 'pr-5' },
          'servingStatus',
          { slot: 'stat', filter: true, filterOptions: [this.getTableStatuses] },
          { slot: 'contentTemplateName', slotOption: this.contentTemplateOptions },
          { key: 'campaignPlanName', filter: true, filterOptions: ['string'], inherit: true },
          { key: 'adGroupPlanName', filter: true, filterOptions: ['string'], inherit: true },
          'qualityScore',
          'topOfPageCpc',
          'firstPositionCpc',
          'broadMatchBid',
          'phraseMatchBid',
          'exactMatchBid',
          'cost',
          'spendYesterday',
          'impressions',
          'clicks',
          'clickThroughRate',
          'costPerClick',
          'conversions',
          'callConversions',
          'webConversions',
          'storeConversions',
          'pageViewConversions',
          'otherConversions',
          'conversionRate',
          'costPerConversion',
          'totalConversionValue',
          'totalConversionValuePerCost',
          'totalConversionValuePerClick',
          ...this.pinterestFields
        ],
        creative: [
          'selectRow',
          { slot: 'creativePlan', filter: true, filterOptions: ['string'] },
          { slot: 'channels', filter: true, filterOptions: [this.getTablePartners], thClass: 'pr-5' },
          'servingStatus',
          { slot: 'stat', filter: true, filterOptions: [this.getTableStatuses] },
          { slot: 'contentTemplateName', slotOption: this.contentTemplateOptions },
          { key: 'campaignPlanName', filter: true, filterOptions: ['string'], inherit: true },
          { key: 'adGroupPlanName', filter: true, filterOptions: ['string'], inherit: true },
          { slot: 'creativeType', label: 'Ad Type', filter: true, filterOptions: [this.getTableValues] },
          'destinationUrl',
          'cost',
          'spendYesterday',
          'impressions',
          'costPerThousandImpressions',
          'clicks',
          'clickThroughRate',
          'costPerClick',
          'conversions',
          'callConversions',
          'webConversions',
          'storeConversions',
          'pageViewConversions',
          'otherConversions',
          'conversionRate',
          'costPerConversion',
          'totalConversionValue',
          'totalConversionValuePerCost',
          'totalConversionValuePerClick',
          'uniqueImpressions',
          'views',
          'costPerLandingPageView',
          'videoViews',
          'costPerVideoView',
          'thruplays',
          'costPerThruplay',
          'leads',
          'costPerLead',
          'webLeads',
          'costPerWebLead',
          'platformLeads',
          'costPerPlatformLead',
          'leadReachRate',
          'engagement',
          'frequency',
          'reach',
          'costPerThousandReach',
          ...this.facebookFields,
          'uniqueClicks',
          'uniqueClickThroughRate',
          'uniqueOutboundClicks',
          'uniqueLandingPageViews',
          'uniqueLandingPageViewRate',
          'costPerUniqueLandingPageView',
          ...this.pinterestFields
        ],
        extension: [
          { key: 'extensionName', defaultWidth: 250, sticky: true, filter: true, filterOptions: ['string'], filterFunction: this.extensionNameFilter },
          { key: 'extensionType', defaultWidth: 150, filter: true, filterOptions: [this.getTableValues] },
          'servingStatus',
          { key: 'extensionStatus', label: 'Status' },
          { slot: 'contentTemplateName', slotOption: this.contentTemplateOptions },
          { key: 'campaignPlanName', filter: true, filterOptions: ['string'], inherit: true },
          { key: 'adGroupPlanName', filter: true, filterOptions: ['string'], inherit: true },
          'cost',
          'spendYesterday',
          'impressions',
          'clicks',
          'clickThroughRate',
          'costPerClick',
          'conversions',
          'conversionRate',
          'costPerConversion'
        ],
        criterion: [
          {
            slot: 'criterionType',
            class: 'text-left truncate',
            defaultWidth: 250,
            sticky: true,
            filter: true,
            filterOptions: [this.getTableValues],
            formatter: this.$filters.labelizeCriterion,
            filterByFormatted: true,
            sortByFormatted: true
          },
          {
            slot: 'criterionSpec',
            filter: true,
            filterOptions: ['string'],
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (a, b, item) => item.name
          },
          'status',
          'criterionBid',
          {
            slot: 'criterionExcluded',
            label: 'Excluded',
            defaultWidth: 100,
            filter: true,
            filterOptions: [[{ text: 'Excluded', value: true }, { text: 'Not Excluded', value: false }]],
            filterByFormatted: true,
            sortByFormatted: true,
            formatter: (a, b, item) => item.excluded
          },
          ...this.pinterestFields
        ],
        form: [
          'selectRow',
          { slot: 'name', filter: true, filterOptions: ['string'] },
          { slot: 'channels', filter: true, filterOptions: [this.getTablePartners], thClass: 'pr-5' },
          'stat',
          'cost',
          'spendYesterday',
          'impressions',
          'clicks',
          'clickThroughRate',
          'leads'
        ]
      }
      return fields
    },
    // eslint-disable-next-line vue/return-in-computed-property
    title () {
      if (this.item) {
        let name = this.item.name
        if (this.item.keywordText && this.planType === 'keyword') {
          name = this.item.keywordText
        } else if (this.item.headline1 && this.planType === 'creative') {
          name = this.item.headline1
        }
        return (`Manage ${this.$filters.labelizePlanType(this.$route.params.manageType)}${this.item ? ': ' + name : ''}`)
      }
      return ''
    },
    item () {
      return this.$store.getters.activeItem
    },
    itemIdAndPlanType () {
      if (!this.item) return null
      return `${this.item.planType}${this.item[Hierachy.getManageTypeFromTableType(this.tableType) + 'PlanId']}`
    },
    isReadOnly () {
      if (this.item?.planType === 'account') {
        return !!this.item?.readOnlyMode
      }

      return !!this.item?.accountPlan?.readOnlyMode
    },
    itemPendingWork () {
      if (this.$store.getters.activeItem) {
        return this.$store.getters.activeItem.pendingWork
      } else {
        return undefined
      }
    },
    tableItems () {
      if (!this.item) return []
      if (this.tableType === 'budget') {
        const tableizedAccountBudgets = this.filteredAccountBudgets

        tableizedAccountBudgets.forEach((value) => {
          value._currencyCode = this.item?.currencyCode || this.item?.accountPlan?.currencyCode
          value._rowVariant = value.budgetPlanId === (this.item.budgetPlanId ?? this.item.budgetPlan?.budgetPlanId) ? 'selected-row' : ''
          value._cellVariants = {
            geoTargetExpansionAbsoluteMaxInMiles: value.budgetUnderspendStatus?.hasTargetingAdjustmentsApplied ? 'warning' : null,
            bidAdjustmentPercentageOnUnderspend: value.budgetUnderspendStatus?.hasBidAdjustmentsApplied ? 'warning' : null,
            autoRemoveBlockingKeywordOnUnderspend: value.budgetUnderspendStatus?.hasNegativeKeywordMitigationApplied ? 'warning' : null,
            useBroadMatchOnUnderspend: value.budgetUnderspendStatus?.hasMatchKeywordsApplied ? 'warning' : null,
            openAdSchedulesOnUnderspend: value.budgetUnderspendStatus?.hasOpenAdSchedule ? 'warning' : null
          }
          if (value.pendingWork?.length > 0) {
            this.addPendingWork(value.pendingWork)
          }
        })

        // if you are looking at another account but the budgets aren't loaded yet return a loading graphic by sending back a null array
        if (this.$store.getters.activeAccountBudgets?.length > 0 && this.item?.accountPlanId === this.$store.getters.activeAccountBudgets[0].accountPlanId) {
          return tableizedAccountBudgets
        } else {
          return []
        }
      }
      const planMap = this.$store.getters.activeChildItems(this.tableType)
      if (!planMap) {
        return []
      }

      if (this.tableType === 'keyword') {
        planMap.forEach((value, key, map) => {
          if (value.keywordPlanId === -1) {
            map.delete(key)
          }
        })
      }

      const activeTableItemId = this.$store.getters.activeItem?.[`${Hierachy.getManageTypeFromTableType(this.tableType)}PlanId`]
      const parentTypes = Hierachy.getParentTypes(this.planType).concat([Hierachy.getTableTypeFromManageType(this.planType)])
      let plans = Array.from(planMap.values())
      if (this.tableType === 'campaign' && this.toggleFilters?.campaign?.showActiveOnly) {
        plans = plans?.filter(p => !p.inactive) || []
      }
      if (this.showPlanStatus === 'Show All But Removed') {
        const validStatuses = ['ENABLED', 'PAUSED']
        plans = this.filterOnShowPlanStatus(plans, validStatuses)
      } else if (this.showPlanStatus === 'Show All Enabled') {
        const validStatuses = ['ENABLED']
        plans = this.filterOnShowPlanStatus(plans, validStatuses)
      }

      plans.forEach((plan) => {
        if (parentTypes.includes(this.tableType) && activeTableItemId && activeTableItemId === plan?.[`${Hierachy.getManageTypeFromTableType(this.tableType)}PlanId`]) {
          plan._rowVariant = 'selected-row'
        } else {
          plan._rowVariant = ''
        }
        if (!plan._showDetails) {
          plan._showDetails = false
        }
        if (this.tableType === 'extension') {
          plan.extensionKey = `${plan.extensionPlanId}_${plan.extensionType}`
        }
        if (plan.pendingWork && plan.pendingWork.length > 0) {
          this.addPendingWork(plan.pendingWork)
        }
        if (!plan.currencyCode) {
          plan._currencyCode = this.item?.currencyCode || this.item?.accountPlan?.currencyCode
        }
      })

      return plans
    },
    planType () {
      let planType
      if (this.$route.params.manageType === 'adgroup') {
        planType = 'adGroup'
      } else if (this.$route.params.manageType === 'ad') {
        planType = 'creative'
      } else {
        planType = this.$route.params.manageType
      }

      return planType
    },
    showAddTypeButton () {
      if (!this.item) return false
      switch (this.tableType) {
        case 'budget':
          return true
        case 'campaign':
          return true
        case 'adGroup':
          return this.item.planType !== 'account' && this.item.planType !== 'budget'
        case 'creative':
          return (this.item.planType === 'adGroup' || this.item.planType === 'keyword' || this.item.planType === 'creative') && this.canAddCreative
        case 'keyword':
          return this.item.planType === 'adGroup' || this.item.planType === 'keyword' || this.item.planType === 'creative'
        case 'extension':
          return true
        case 'form':
          return true
        default:
          return false
      }
    },
    canAddCreative () {
      if (this.tableType !== 'creative') return true // not a creative table
      if (this.tableItems?.some(creative => creative.status !== 'REMOVED' && creative.creativeType === 'MEDIA_DATA_AD')) return false // at most one MEDIA_DATA_AD is allowed per ad group, and no other ads.
      return true
    },
    showPlanStatus: {
      set (val) {
        this.$store.dispatch('setShowPlanStatus', val)
      },
      get () {
        return this.$store.getters.showPlanStatus
      }
    },
    budgets () {
      return this.$store.getters.activeAccountBudgets
    },
    userPrefDefaultTableTab: {
      get () {
        if (this.$store.getters.user) {
          return this.$store.getters.user.defaultManageTableType
        }
        return null
      },
      set (defaultManageTableType) {
        if (defaultManageTableType) {
          this.$store.dispatch('setUserDefaultManageTableType', { defaultManageTableType })
        }
      }
    },
    blueprintPollInterval () {
      if (this.$store.getters.activelyRepublishingBlueprints.length > 0) {
        return 2500
      } else {
        return 10000
      }
    },
    disableSettingsButton () {
      return this.$store.getters.loading?.activeItem
    },
    AIChat () {
      const isAllowedPlan = this.planType === 'account' || this.planType === 'campaign'

      return isAllowedPlan && this.$store.getters?.user?.capabilities?.AIChat
    }
  },
  methods: {
    openMuseDrawer () {
      const planType = this.planType
      const key = planType === 'account' ? 'accountPlanId' : 'campaignPlanId'

      this.$eventBus.$emit('openMuseChatDrawer', {
        immediatePrompt: true,
        planType,
        planTypeId: {
          [key]: this.item[key]
        },
        suggestions: []
      })
    },
    setPreventEscapeCloseTrue () {
      this.preventEscapeClose = true
    },
    hideProductCatalogPreview (e) {
      this.catalogPreview = null
    },
    showProductCatalogPreview (e) {
      this.catalogPreview = e
    },
    showCampaignSettingsReplicator () {
      this.openSettings(this.item)
      this.openCampaignSettingsReplicatorDrawer()
    },
    isExtensionManageType (type = this.planType) {
      return this.isPlanTypeExtension(type)
    },
    openCampaignSettingsReplicatorDrawer () {
      setTimeout(() => {
        this.$eventBus.$emit('showCampaignSettingsReplicator', true)
      }, 333)
    },
    managePaneCollapse (name, val = 'open', $event) {
      const computedCopy = _.cloneDeep(this.splitpanesSizes)

      computedCopy[name] = (val === 'open') ? this.splitpanes.openToSize[name] : this.splitpanes.minSideWidth

      // Table column should always fill the difference
      computedCopy.table = 100 - computedCopy.nav

      const trackingName = (val === 'open') ? 'manage_navigation_expand' : 'manage_navigation_collapse'
      this.$track.event(trackingName, {}, 'manage', $event.target.closest('[role=button]'))

      this.splitpanesSizes = computedCopy
    },
    resizePanes (e) {
      const computedCopy = _.cloneDeep(this.splitpanesSizes)

      computedCopy.nav = e[0].size // nav
      computedCopy.table = e[1].size // table

      this.splitpanesSizes = computedCopy

      return true
    },
    isPlanTypeExtension (planType) {
      const extensionTypes = ['siteLink', 'structuredSnippet', 'callout', 'callExtension', 'priceExtension', 'imageExtension', 'locationExtension', 'promotionExtension']
      return extensionTypes.includes(planType)
    },
    getDisplayTabType (tab) {
      // handle plural criterion
      if (tab === 'criterion') {
        return 'Criteria'
      }
      return this.$filters.upperCaseCharOne(this.$filters.labelizePlanType(`${tab}s`))
    },
    extensionNameFilter (items, filter) {
      let filteredPlans = items
      filteredPlans = filteredPlans.filter(p => this.labelizeExtensionName(p).toLowerCase().includes(filter.text.toLowerCase()))
      return filteredPlans
    },
    pacingStratFilter (items, filter) {
      return items.filter(i => filter.checkboxValues.includes(i.budgetManagerConfiguration?.budgetBiasStrategy))
    },
    budgetTypeFilter (items, filter) {
      return items.filter(i => filter.checkboxValues.includes(i.budgetPlan?.budgetType))
    },
    advancedBulkEdit () {
      this.$store.commit('bulkEdit/returnPath', this.$route.fullPath)
      this.$router.push({ path: '/bulk', query: { table: this.tableType } })
    },
    getTableValues (key) {
      const values = []
      for (const item of this.tableItems) {
        if (!values.includes(item[key]) && item[key]) {
          values.push(item[key])
        }
      }
      values.sort()
      return [...values, 'No Value']
    },
    getTablePacingStrategies () {
      const pacingStrats = []
      for (const item of this.tableItems) {
        const ps = item.budgetManagerConfiguration?.budgetBiasStrategy
        if (ps && !pacingStrats.includes(ps)) {
          pacingStrats.push(ps)
        }
      }
      pacingStrats.sort()
      return pacingStrats
    },
    async fetchMiniBlueprints () {
      const response = await this.$store.dispatch('fetchMinBlueprints')
      if (response) {
        this.contentTemplateOptions = [
          {
            text: 'Unlinked',
            value: 'Unlinked'
          },
          ...Object.values(response).map(bp => ({ text: bp.templateName, value: bp.contentTemplateId }))
        ]
      }
    },
    getTableBudgetTypes () {
      const bts = []
      for (const item of this.tableItems) {
        const bt = item.budgetPlan?.budgetType
        if (bt && !bts.includes(bt)) {
          bts.push(bt)
        }
      }
      bts.sort()
      return bts
    },
    getTableStatuses () {
      const values = []
      for (const item of this.tableItems) {
        if (!values.includes(item.status) && item.status) {
          values.push(item.status)
        }
      }
      values.sort()
      return values
    },
    getTableChannels () {
      const channels = []
      for (const item of this.tableItems) {
        if (!channels.includes(item.advertisingChannelId)) {
          channels.push(item.advertisingChannelId)
        }
      }
      return channels.map(c => {
        return { text: this.getAdvertisingChannelLabel(c), value: c }
      })
    },
    getTablePartners () {
      const partners = []
      for (const item of this.tableItems) {
        if (item.partnerObjects) {
          for (const partner of item.partnerObjects) {
            if (!partners.includes(partner.partnerId)) {
              partners.push(partner.partnerId)
            }
          }
        }
      }
      return [...partners.map(p => {
        return { text: PartnerName(p), value: p }
      }), { text: 'Errors', value: 'errors' }]
    },
    getTableCampaignLabels () {
      return this.tableItems.flatMap(campaign => campaign.labels?.length > 0 ? campaign.labels : []).distinct().concat(['No Label'])
    },
    getTableAdGroupLabels () {
      return this.tableItems.flatMap(adgroup => adgroup.labels?.length > 0 ? adgroup.labels : []).distinct().concat(['No Label'])
    },
    getAdvertisingChannelLabel (id) {
      return this.$store.getters.advertisingChannels?.find(ch => ch.advertisingChannelId === id)?.channelLabel.replace('DDC RTB ', '').replace('Ingested', 'Legacy')
    },
    getTableTabsForItem (item) {
      const tableTabs = ValidChannels.TableTabs
      const tabs = []
      let itemChannels
      if (item.planType === 'account') {
        itemChannels = item.activeChannels
      } else {
        itemChannels = [this.findItemAdvertisingChannel(item)]
      }
      if (!itemChannels) return []
      for (const [tab, validChannels] of Object.entries(tableTabs)) {
        const includeChannels = validChannels.filter(c => c > 0)
        const excludeChannels = validChannels.filter(c => c < 0).map(c => c * -1)
        let pass = true
        if (includeChannels.length > 0 && !itemChannels.some(ch => includeChannels.includes(ch))) {
          pass = false
        }
        if (excludeChannels.length > 0 && itemChannels.every(ch => excludeChannels.includes(ch))) {
          pass = false
        }
        if (pass) {
          tabs.push(tab)
        }
      }
      return tabs
    },
    async reloadTableMetrics () {
      const promises = []
      const tableTypePlans = new Map(this.$store.getters.activeChildItems(this.tableType))

      if (this.tableType === 'budget' && this.$store.getters.activeItem?.accountPlanId) {
        promises.push(this.$store.dispatch('fetchAccountBudgets', { accountPlanId: this.$store.getters.activeItem.accountPlanId }))
      } else if (this.tableType === 'extension' && this.$store.getters.activeItem?.accountPlanId) {
        /* `extensions` isn't a type that we can fetch metrics for, making a dispatch to `fetchPlansWithMetrics` here
         * works because that method has logic to break up calls for `siteLinksPlan`, `calloutPlan`,
         * `callExtensionPlan`, etc. This gets the correct metrics without having to change how `fetchMetrics` works
         */
        this.$store.dispatch('fetchPlansWithMetrics', { fetchType: this.tableType, forceBudgetReload: this.tableType === 'budget' })

        return
      } else if (this.tableType?.length > 0 && tableTypePlans.size > 0) {
        promises.push(this.$store.dispatch('fetchMetrics', { plansById: tableTypePlans, fetchType: this.tableType }))
      }

      if (!this.$store.getters.activeItem) {
        // if activeItem hasn't been loaded yet and the user is requesting a refresh of data (like via date picker)
        await this.$store.dispatch('fetchActiveItem', { planType: this.planType, id: this.$route.query.id })
      }

      promises.push(this.$store.dispatch('fetchActiveItemMetrics', { planType: this.planType }))

      await Promise.all(promises)

      let items = { ...this.$store.getters.activeChildItems(this.tableType) }
      if (items || tableTypePlans.size > 0) {
        // everything but budgets
        items = tableTypePlans

        this.$store.commit('activeChildItems', { type: this.tableType, items: new Map(items) })
      }
    },
    async reloadTableItems () {
      this.$store.dispatch('fetchPlansWithMetrics', { fetchType: this.tableType, forceBudgetReload: this.tableType === 'budget' })
      this.refreshActiveItemMetrics()
      if (!this.item.dirty) {
        this.$store.dispatch('fetchActiveItem', { planType: this.planType, id: this.$route.query.id })
      }
    },
    async refreshActiveItemMetrics () {
      await this.$store.dispatch('fetchActiveItemMetrics', { planType: this.planType })
    },
    viewSimilarMetrics (plan) {
      this.viewSimilarMetricsObject = plan
      this.showViewSimilarMetricsModal = true
    },
    findAndReplaceKeywords () {
      this.showFindAndReplaceKeywordsModal = true
    },
    modifyBudgetsModal () {
      this.bulkBudget.showModal = true
    },
    budgetAmountCalc (budgetChange, budget) {
      let adjustAmount = 0

      if (budgetChange.setOrModify === 'set') {
        adjustAmount = budgetChange.adjustAmount
      } else {
        if (budgetChange.dollarOrPercent === '$') {
          adjustAmount = budget + (budgetChange.adjustAmount * budgetChange.increaseOrDecrease)
        } else { // percentage
          if (budgetChange.increaseOrDecrease === -1) {
            adjustAmount = budget * (1 - (budgetChange.adjustAmount / 100))
          } else {
            adjustAmount = budget * (1 + budgetChange.adjustAmount / 100)
          }
        }
      }
      return adjustAmount
    },
    async bulkModifyBudgets (o) {
      let anythingToChange = false
      const mutatedRows = this.selectedPlans.map(item => {
        const itemCopy = _.cloneDeep(item)
        Object.entries(o).forEach(entry => {
          const key = entry[0]
          const value = entry[1]

          if (value !== null) {
            anythingToChange = true
            switch (key) {
              // budgetManagerConfiguration items
              case 'budgetBiasStrategy':
              case 'fixedBudgetValue':
              case 'pauseCampaignsWhenExhausted':
              case 'notifyOnPause':
                if (itemCopy.hasOwnProperty('budgetManagerConfiguration')) {
                  itemCopy.budgetManagerConfiguration[key] = value
                }
                break
              case 'budgetAmount':
                itemCopy[key] = this.budgetAmountCalc(o.budgetAmount, itemCopy[key])
                break
              default:
                itemCopy[key] = value
                break
            }
          }
        })

        return itemCopy
      })

      if (anythingToChange) {
        let numUpdates = 0

        this.isPerformingBulkAction = true

        const responsesArray = await this.$res.set.budgetDTOs(mutatedRows)
        const budgetPlans = [...this.$store.getters.activeAccountBudgets]

        if (!responsesArray) {
          this.$toast('Error saving Budgets. Please try your action again.', 'danger')
        } else {
          responsesArray.successes.forEach((response) => {
            if (response) {
              const budgetIndex = budgetPlans.findIndex(plan => plan.budgetPlanId === response.budgetPlanId)
              if (budgetIndex > -1) {
                budgetPlans[budgetIndex] = { ...response, _showDetails: false } // update budget data from response
                numUpdates++
              }
            } else {
              this.$toast('Error saving one of the Budgets. Please try your action again.', 'danger')
            }
          })
          if (responsesArray.failures.length > 0) {
            this.$toast(`${responsesArray.failures.length} budget update${responsesArray.failures.length > 0 ? 's' : ''} failed.`, 'warning')
          }
          if (responsesArray.noChange.length > 0) {
            this.$toast(`${responsesArray.noChange.length} budget${responsesArray.noChange.length > 0 ? 's' : ''} did not change.`, 'info')
          }
        }

        if (numUpdates > 0) {
          this.$store.commit('setActiveBudgets', budgetPlans)
          this.reloadTableItems()
          this.$toast(`${numUpdates} Budget${numUpdates > 0 ? 's' : ''} updated successfully`, 'success')
        }
      } else {
        this.$toast('No changes were made', 'info')
      }

      this.isPerformingBulkAction = false
    },
    async bulkModifyBids ({ modificationAmount, setOrModify, dollarOrPercent, matchType }) {
      const bidKeys = (matchType && matchType.length > 0) ? matchType : ['bid']
      const mutatedRows = this.selectedPlans.map(item => {
        const bidAmount = []
        bidKeys.forEach(bidKey => {
          bidAmount[bidKey] = item[bidKey]
        })
        const modAmt = parseFloat(modificationAmount)
        const mutateObject = {}
        if (setOrModify === 'set') {
          // mutateObject = { bid: modAmt }
          bidKeys.forEach(bidKey => {
            mutateObject[bidKey] = modAmt
          })
        } else if (setOrModify === 'modify') {
          // mutateObject = (dollarOrPercent === '$') ? { bid: bidAmount + modAmt } : { bid: bidAmount * modAmt }
          bidKeys.forEach(bidKey => {
            mutateObject[bidKey] = (dollarOrPercent === '$') ? bidAmount[bidKey] + modAmt : bidAmount[bidKey] * modAmt
          })
        }
        return {
          ...item,
          ...mutateObject
        }
      })
      const response = await this.$res.set.plans(this.tableType, mutatedRows)
      if (response) {
        this.$toast('Items Saved', 'success')
        this.reloadTableItems()
      }
    },
    async bulkModal (button, modalName) {
      this.bulkDataCommand = {
        button,
        selectedRows: this.selectedPlans,
        modalName
      }
      if (button.useModal) {
        this.bulkEditDialog.propsOverride = button.propsOverride ? { ...button.propsOverride } : {}
        this.bulkEditDialog.component = button.useModal
        this.bulkEditDialog.show = true
      } else {
        this.showDestinationUrlModal = true
      }
    },
    async bulkModalReturn (modalData) {
      await this.bulkOperation(this.bulkDataCommand.button, modalData)
    },
    remapBlueprints () {
      this.showRemapBlueprintsModal = true
    },
    async bulkOperation (button, modalData) {
      let readOnlyOverride = false
      let selectedRows = [...this.selectedPlans]
      if (button.selectedPlansNotCompatible?.length > 0) {
        selectedRows = selectedRows.filter(row => !button.selectedPlansNotCompatible.includes(row))
      }
      const actionType = button.type
      const statusMap = {
        'status-enabled': 'ENABLED',
        'status-removed': 'REMOVED',
        'status-paused': 'PAUSED'
      }
      let objectUpdate = {} // what mutation needs to occur
      let planType = '' // what object is going to get sent in

      if (selectedRows.some(row => row.readOnly)) {
        readOnlyOverride = await this.$confirm('Some of these selected items are read only. Do you want to make them editable and apply changes?')
        if (!readOnlyOverride) {
          return false
        } else {
          // set rows so that they don't look like read only anymore, the actual save takes place below
          selectedRows.forEach(row => {
            row.readOnly = false
          })
        }
      }

      if (actionType.startsWith('status')) {
        await this.bulkStatusChange(selectedRows, statusMap[actionType], readOnlyOverride)
        return
      } else if (actionType === 'remove-budget') {
        await this.bulkDeleteBudgets(selectedRows)
        return
      } else if (actionType === 'budget-assign') {
        let confirmMessage = `Are you sure you want to assign these ${selectedRows.length} campaigns to the budget '${button.extraInfo.name}'`
        const validated = this.validateCampaignGroup(button.extraInfo, selectedRows) // from budgetsMixin
        if (validated.invalid.length > 0) {
          confirmMessage = `Assigning this budget might have unintended side effects. ${validated.invalid.length} of these campaigns are in a campaign group that is different than this budget's campaign group. \r\n\r\n${confirmMessage}`
        }
        const makeChange = await this.$confirm(confirmMessage)
        if (makeChange) {
          planType = 'campaign'
          objectUpdate = {
            budgetPlanId: button.extraInfo.budgetPlanId,
            budgetPlan: button.extraInfo
          }
        } else {
          return
        }
      } else if (actionType === 'campaign-tracking-template') {
        const trackingTemplate = await this.$prompt('Enter the tracking template URL you want to use:')
        if (trackingTemplate && trackingTemplate.length > 0) {
          planType = 'campaign'
          objectUpdate = {
            trackingUrlTemplate: trackingTemplate
          }
        } else {
          return
        }
      } else if (actionType === 'ad-group-tracking-template') {
        const trackingTemplate = await this.$prompt('Enter the tracking template URL you want to use:')
        if (trackingTemplate && trackingTemplate.length > 0) {
          planType = 'adGroup'
          objectUpdate = {
            trackingUrlTemplate: trackingTemplate
          }
        } else {
          return
        }
      } else if (actionType === 'destination-url') {
        const destinationURL = modalData
        if (destinationURL && destinationURL.length > 0) {
          if (button.extraInfo === 'adGroup') {
            const response = this.$res.set.destinationUrlForAdGroups({
              adGroupPlanIds: selectedRows.map(row => row.adGroupPlanId),
              destinationUrl: destinationURL
            })
            if (response) {
              this.$toast('Destination URLs set', 'success')
            } else {
              this.$toast('Error setting destination URLs', 'danger')
            }
            return
          } else {
            planType = 'creative'
            objectUpdate = {
              destinationUrl: destinationURL
            }
          }
        } else {
          return
        }
      } else if (actionType.startsWith('read-only-')) {
        planType = 'campaign'
        objectUpdate = {
          readOnly: !!actionType.endsWith('-enable')
        }
      } else if (actionType === 'campaign-republish-blueprints') {
        const selectedBlueprints = this.selectedPlans.reduce((pV, cV) => {
          if (cV.hasOwnProperty('contentTemplateId') && !pV.includes(cV.contentTemplateId)) {
            pV.push(cV.contentTemplateId)
          }
          return pV
        }, [])

        const blueprintNames = selectedBlueprints.reduce((pV, cV) => {
          pV += '• ' + this.blueprints[cV]?.templateName + '\n'

          return pV
        }, '')

        if (selectedBlueprints.length === 0) {
          await this.$alert('There were no associated Blueprints for the selections you made.')

          return
        }

        const confirm = await this.$confirm({ title: 'Are you sure?', text: `Do you want to republish campaigns under "${this.item?.name}" which were generated from the following Blueprints?\n\n${blueprintNames}` })

        if (confirm) {
          const promises = selectedBlueprints.map(n => this.$res.set.republishFromBlueprint({ accountPlanId: this.accountPlanId, contentTemplateId: n }))
          await Promise.all(promises)

          await this.blueprintPollFetch()
        }

        return
      } else if (actionType.startsWith('budget-read-only-')) {
        planType = 'budget'
        objectUpdate = {
          readOnly: actionType.endsWith('-enable')
        }
      }

      // perform update based on what was returned.  For example budget assignment changes the object to have a specific budget plan id
      const mutatedRows = selectedRows.map(item => {
        return {
          ...item,
          ...objectUpdate
        }
      })

      const response = await this.$res.set.plans(planType, mutatedRows)
      if (response) {
        this.$toast('Items Saved', 'success')
        if (planType === this.planType) {
          this.$store.dispatch('fetchActiveItem', { planType, id: this.$route.query.id })
        }
        this.reloadTableItems()
      }
    },
    async duplicate ({ type }) {

    },
    async blueprintPollFetch () {
      const response = await this.$res.fetch.launchStatusByAccount(this.accountPlanId)

      if (response) {
        if (response.length === 0) {
          // Done republishing
          if (this.$store.getters.activelyRepublishingBlueprints.length > 0) {
            this.$store.dispatch('activelyRepublishingBlueprints', [])
          }
        } else {
          const arr = response.reduce((pV, cV) => {
            pV.push(cV.contentTemplateId)
            return pV
          }, [])

          this.$store.dispatch('activelyRepublishingBlueprints', arr)
        }
      }
    },
    async bulkStatusChange (items, status, readOnlyOverride, skipConfirm) {
      let makeChange
      if (!skipConfirm) {
        const displayStatus = status.endsWith('D') ? status.substring(0, status.length - 1) : status
        makeChange = await this.$confirm(`Are you sure you want to ${displayStatus} ${items.length === 1 ? 'this' : 'these'} ${items.length} item${items.length > 1 ? 's' : ''}?`)
      }
      if (makeChange || skipConfirm) {
        const ids = items.map(id => id[this.tableType + 'PlanId'])
        const response = await this.$res.set.bulkStatusChange(this.tableType, ids, status, readOnlyOverride)
        if (response && response.response.requests && response.response.requests.length) {
          this.$toast('Status Updated', 'success')
          let responses = response.response.requests
          let totalPendingWork = []
          items.forEach(item => {
            if (this.tableType === 'campaign' || this.tableType === 'creative') { // special case for campaign because the campaignPlan is inside
              responses = responses.map(response => {
                return {
                  ...response,
                  ...response.campaignPlan
                }
              })
            }
            const response = responses.find(response => response[this.tableType + 'PlanId'] === item[this.tableType + 'PlanId'])
            totalPendingWork = totalPendingWork.concat(response?.pendingWork || [])
          })
          this.addPendingWork(totalPendingWork, this.tableType)
          this.reloadTableItems()
        } else if (!response || response.hasFailures) {
          this.$toast('There were some errors attempting to save the status.')
        } else if (!response?.hasFailures) {
          this.$toast('No changes were made.')
        }
      }
    },
    async bulkDeleteBudgets (budgets) {
      if (budgets?.length > 0) {
        const makeChange = await this.$confirm(`Are you sure you want to remove ${budgets.length} budget${(budgets.length > 1) ? 's' : ''}?`)
        let successfulRemoval = false
        const remainingBudgets = [...this.$store.getters.activeAccountBudgets]
        let removedActiveBudget = false
        if (makeChange) {
          for (const budgetDTO of budgets) {
            if (budgetDTO && budgetDTO.budgetPlanId && budgetDTO.budgetPlanId > 0) {
              if (budgetDTO.campaignPlans?.length > 0 || budgetDTO.attachedCampaignPlanIds?.length > 0) {
                this.$toast(`Cannot remove budget: ${budgetDTO.name} because it is still  attached to campaigns. Please re-assign campaigns to other budgets first.`, 'danger')
              } else {
                const response = await this.$res.set.budget({
                  ...budgetDTO,
                  status: 'DISABLED'
                })
                if (response) {
                  const removeIndex = remainingBudgets.findIndex(budget => budget.budgetPlanId === budgetDTO.budgetPlanId)
                  remainingBudgets.splice(removeIndex, 1)
                  removedActiveBudget = this.planType === 'budget' && budgetDTO.budgetPlanId === this.item?.budgetPlanId
                  successfulRemoval = true
                } else {
                  this.$bugsnag(new Error('Undefined response when Disabling a Budget', {
                    metadata: {
                      budgetDTO
                    }
                  }))
                }
              }
            }
          }

          this.$store.commit('setActiveBudgets', remainingBudgets)
          if (successfulRemoval) {
            this.$toast('Budgets removed', 'success')
          }
          if (removedActiveBudget) {
            this.$router.push(`/manage/account/?id=${this.item.accountPlanId}`)
          }
        }
      }
    },
    async rowDetailsToggled (itemOrItems) {
      const items = Array.isArray(itemOrItems) ? itemOrItems : [itemOrItems]
      const itemsWithoutChildren = items.filter(item => !item.children)

      if (itemsWithoutChildren.length > 0) {
        const [startDateTime, endDateTime] = this.$store.getters.timeRange
        let params = '?'

        if (startDateTime && endDateTime) {
          params += '&metricsStart=' + this.$moment(startDateTime).format('YYYY-MM-DD')
          params += '&metricsEnd=' + this.$moment(endDateTime).format('YYYY-MM-DD')
        }

        // Batched endpoints - this used to be separate calls
        const response = await this.$res.fetch.planChildren(this.tableType, itemsWithoutChildren, params)
        if (response) {
          let tmpItems
          if (this.tableType === 'budget') {
            tmpItems = new Map(this.$store.getters.activeAccountBudgets.map(b => [b.budgetPlanId, b]))
          } else {
            tmpItems = new Map(this.$store.getters.activeChildItems(this.tableType))
          }
          for (const [id, children] of Object.entries(response)) {
            const item = tmpItems.get(parseInt(id))
            if (item) {
              item.children = children.map(child => ({
                ...this.formatMatchTypesForChildren(child, item),
                ...child.metricsDTO,
                advertisingChannelId: item.advertisingChannelId,
                _showDetails: undefined,
                _rowVariant: undefined
              }))
            }
          }
          if (this.tableType === 'budget') {
            this.$store.commit('setActiveBudgets', Array.from(tmpItems.values()))
          } else {
            this.$store.commit('activeChildItems', { type: this.tableType, items: tmpItems })
          }
        }
      }
      this.$forceUpdate() // this needs to be in there to make sure the expand and collapse works correctly -SNG
    },
    formatMatchTypesForChildren (childItem, parentItem) {
      const item = childItem
      item.phraseMatchBid = '-'
      item.exactMatchBid = '-'
      item.broadMatchBid = '-'

      switch (childItem.matchType) {
        case 'BROAD':
          item.broadMatchBid = childItem.bid
          if (parentItem.isEnhancedBroadMatch) {
            item.keywordText = parentItem.keywordText.replace(/(^|\s+)/g, '$1+')
          }
          break
        case 'PHRASE':
          item.phraseMatchBid = childItem.bid
          item.keywordText = `"${parentItem.keywordText}"`
          break
        case 'EXACT':
          item.exactMatchBid = childItem.bid
          item.keywordText = `[${parentItem.keywordText}]`
          break
      }
      return item
    },
    async saveSubTableRowItem (item) {
      if (this.tableType === 'budget') {
        // this code is for saving an optimized daily budget item that is in a sub table
        const budgetPlan = {
          budgetPlanId: item.budgetPlanId
        }
        const partnerId = item.partnerId
        const optimizedAmount = item.optimizedDailyAmount
        await this.$res.budget.overrideOptimizedAmount(budgetPlan, partnerId, optimizedAmount)
      }
    },
    inlineLoadingTimeout () {
      this.inlineLoadingTimeoutId = setTimeout(() => {
        this.isInlineLoading = true
      }, 200)
    },
    clearInlineLoadingTimeout () {
      clearTimeout(this.inlineLoadingTimeoutId)
      this.isInlineLoading = false
    },
    async saveTableRowItem (item, bulkSave = false) {
      this.inlineLoadingTimeout()

      if (this.tableType === 'budget') {
        this.saveBudget(item)
        return
      } else if (this.tableType === 'extension') {
        await this.$store.dispatch('saveExtensionPlan', item)
        this.reloadTableItems()
        if (item.extensionKey === `${this.$route.query.id}_${this.planType}Plan`) {
          await this.$store.dispatch('fetchActiveItem', { planType: this.planType, id: this.$route.query.id })
        }

        this.clearInlineLoadingTimeout()
        return
      }

      const activeItem = { ...this.item }

      const response = await this.$res.set.plan(this.tableType, item)

      if (!response || response.hasFailures) {
        this.$bugsnag(new Error(`Error saving ${this.tableType}s.`), {
          metadata: {
            response,
            payload: item,
            activeItem
          }
        })
        this.clearInlineLoadingTimeout()
      } else {
        const dto = response.response?.requests?.length > 0 ? response.response.requests[0] : null
        let savedItem = dto
        if (dto) {
          this.addPendingWork(dto.pendingWork, this.tableType)
          if (this.tableType === 'campaign') {
            this.$store.dispatch('fetchAccountBudgets', this.item)
          } else if (this.tableType === 'creative' || this.tableType === 'ad') {
            savedItem = {
              ...dto
            }
          }
        }
        this.reloadTableItems()
        this.refreshActiveItemMetrics()

        const savedItemId = savedItem ? savedItem[`${this.tableType}PlanId`] : null
        const activeItemId = activeItem[`${this.planType}PlanId`]
        if (savedItemId === activeItemId && this.tableType === this.planType) {
          const newActiveItem = {
            ...activeItem,
            ...savedItem
          }
          this.$store.commit('activeItem', newActiveItem)
        }

        if (!bulkSave) {
          this.$toast(`${this.displayTableType} saved!`, 'success')
        }

        this.clearInlineLoadingTimeout()
        return response
      }
    },

    saveBudget (item) {
      _.delay(async () => {
        const index = this.$store.getters.activeAccountBudgets
          .findIndex(bud => bud.budgetPlanId === item.budgetPlanId)
        if (index >= 0) {
          const response = await this.$res.set.budgetDTO(item)
          if (response) {
            const updatedBudgetPlan = {
              ...item,
              ...response
            }
            // Commenting this out because the call to 'fetchPlansWithMetrics' below overwrites this anyway - JC
            // this.$store.commit('updateBudgetInActiveBudgets', updatedBudgetPlan)
            if (this.planType === 'budget' && updatedBudgetPlan.budgetPlanId === this.item.budgetPlanId) {
              this.$store.commit('activeItem', {
                ...this.item,
                ...response
              })
            }

            await this.$store.dispatch('fetchPlansWithMetrics', { fetchType: 'budget', forceBudgetReload: true })
            await this.$store.dispatch('fetchPlansWithMetrics', { fetchType: 'campaign' })
            await this.refreshActiveItemMetrics()
          } else {
            this.$toast('Error saving budgets. Please try again', 'danger')
          }
        }

        this.clearInlineLoadingTimeout()
      }, 200)
    },
    validateBudget (budgetDTO) {
      return !!budgetDTO.name
    },
    async saveStrategy (item) {
      if (item.contentTemplateId === 'Unlinked') {
        item.contentTemplateId = null
      }
      const response = this.$res.set.contentTemplateIdForCampaign(item)
      if (response) {
        this.$toast('Strategy updated', 'success')
        this.$store.getters.activeChildItems('campaign').get(item.campaignPlanId).contentTemplateId = item.contentTemplateId
        this.refreshActiveChildItemsRef() // only have to do this here because we're updating a non-reactive piece of the whole shallowRef
      }
    },
    getAddNewItemRoute (tableType) {
      const { accountPlanId, budgetPlanId, campaignPlanId, adGroupPlanId } = this.item

      const params = []
      if (accountPlanId) {
        params.push(`accountPlanId=${accountPlanId}`)
      }
      if (budgetPlanId && tableType !== 'budget' && tableType !== 'campaign') {
        params.push(`budgetPlanId=${budgetPlanId}`)
      }
      if (campaignPlanId && tableType !== 'campaign') {
        params.push(`campaignPlanId=${campaignPlanId}`)
      }
      if (adGroupPlanId && tableType !== 'adGroup' && tableType !== 'campaign' && tableType !== 'budget') {
        // don't push adGroupPlanId for higher levels
        params.push(`adGroupPlanId=${adGroupPlanId}`)
      }

      let query = `?type=${tableType}`
      if (params.length > 0) {
        query = query + '&' + params.join('&')
      }
      return query
    },
    findManageTypeAndId (item) {
      let manageType, id
      if (item.budgetOptimizerStatusDTO) {
        manageType = 'budget'
        id = item.budgetPlanId
      } else if (item?.budgetType === 'LIFETIME') {
        manageType = 'budget'
        id = item.budgetPlanId
      } else if (item.keywordPlanId) {
        manageType = 'keyword'
        id = item.keywordPlanId
      } else if (item.criterionPlanId) {
        manageType = 'criterion'
        id = item.criterionPlanId
      } else if (item.creativePlanId) {
        manageType = 'ad'
        id = item.creativePlanId
      } else if (item.formPlanId) {
        manageType = 'form'
        id = item.formPlanId
      } else if (item.keywordPlanId) {
        manageType = 'keyword'
        id = item.keywordPlanId
      } else if (item.creativePlanId) {
        manageType = 'ad'
        id = item.creativePlanId
      } else if (['siteLinkPlan', 'structuredSnippetPlan', 'calloutPlan', 'callExtensionPlan', 'priceExtensionPlan', 'imageExtensionPlan', 'locationExtensionPlan', 'promotionExtensionPlan'].includes(item.extensionType)) {
        manageType = item.extensionType.slice(0, -4)
        id = item[`${item.extensionType}Id`]
      } else if (item.adGroupPlanId) {
        manageType = 'adgroup'
        id = item.adGroupPlanId
      } else if (item.campaignPlanId) {
        manageType = 'campaign'
        id = item.campaignPlanId
      } else if (item.accountPlanId) {
        manageType = 'account'
        id = item.accountPlanId
      }
      return { manageType, id }
    },
    manageTypeToPlanType (manageType) {
      let planType
      switch (manageType) {
        case 'adgroup':
          planType = 'adGroup'
          break
        case 'ad':
          planType = 'creative'
          break
        default:
          planType = manageType
      }
      return planType
    },
    async handleManageTableAnalyzeCampaign (item) {
      this.$eventBus.$emit('open-analyze-campaign', item)
    },
    async handleManageTablePreviewGeos (item) {
      const { manageType, id } = this.findManageTypeAndId(item)
      await this.$store.dispatch('fetchActiveItem', { planType: manageType, id, settings: true })
      this.$eventBus.$emit('preview-campaign-geos')
    },
    openSettings (item) {
      if (item.keywordPlanId || item.criterionPlanId) {
        return
      }
      const { manageType, id } = this.findManageTypeAndId(item)
      this.$router.push({ query: { ...this.$route.query, settingsItemId: id, settingsItemType: manageType } })
    },
    async drillIn (item) {
      let drillIn = true

      // lowest level items
      if (item.criterionPlanId || item.creativePlanId || item.keywordPlanId || item.formPlanId || item.extensionType) {
        drillIn = false
      }

      const { manageType, id } = this.findManageTypeAndId(item)
      const possibleTableTabs = this.tableTabs
      let table = possibleTableTabs.includes(this.$route.query.table) ? this.$route.query.table : null

      if (drillIn) {
        const nextTierTypes = Hierachy.getNextTierTypes(manageType).filter(type => possibleTableTabs.includes(type))
        table = nextTierTypes?.[0] || this.$route.query.table || null
      }

      const query = { id }
      if (table) {
        query.table = table
      }

      // check for difference so that we don't try and duplicate route because it does logic here and then gets prevented
      if (id !== this.item[`${this.planType}PlanId`] || manageType.toLowerCase() !== this.planType?.toLowerCase() || query.table !== this.$route.query.table) {
        item._rowVariant = 'selected-row'
        await this.$router.push({ path: `/manage/${manageType}/`, query })
      }
    },
    rowSingleClicked (item) {
      if (item.criterionPlanId || item.creativePlanId || item.keywordPlanId || item.formPlanId || item.extensionType) {
        this.openSettings(item)
      }
    },
    coalescePlanItemStatus (planItem) {
      if (planItem.enabled === false) { // only extension type plans have this
        return 'REMOVED'
      }
      if (planItem.servingStatus) {
        return planItem.servingStatus.cascadingStatus
      }
      return planItem.status
    },
    parseImpression (val) {
      if (!val) {
        return 0
      }
      if (typeof val === 'string') {
        return parseInt((val).replace(/,/, ''))
      }
      return val
    },
    findItemAdvertisingChannel (item) {
      return item?.advertisingChannelId || 1
    },
    doesPlanHaveAdvertisingChannel (advertisingChannelId) {
      if (!this.item || !advertisingChannelId) return false

      if (this.item.advertisingChannelId) {
        return this.item.advertisingChannelId?.toString() === advertisingChannelId.toString()
      } else if (this.item.planType === 'account') {
        return this.item?.activeChannels?.includes(advertisingChannelId)
      } else if (this.item.campaignPlan) {
        return this.item.campaignPlan?.advertisingChannelId?.toString() === advertisingChannelId.toString()
      } else if ((this.item.planType === 'keyword' || this.isExtensionManageType()) && advertisingChannelId.toString() === '1') {
        return true
      }
      return false
    },
    async removeItem (item) {
      item.status = 'REMOVED'
      try {
        // return
        await this.saveTableRowItem(item)
        this.$toast(`${item.name || item.planType} Removed`, 'success')
      } catch (e) {
        this.$bugsnag(e)
      }
    },
    canTabBeDefaultUserPref (tableTab) {
      return tableTab === 'budget' || tableTab === 'campaign'
    },
    hideModal () {
      this.$pageTitle('Manage', true)
      this.$router.push('/manage/')
    },
    keyUpHandler (evt) {
      if (this.preventEscapeClose) {
        this.preventEscapeClose = false
        return
      }
      if (evt.defaultPrevented) {
        return
      }
      if (evt?.target?.classList?.contains('modal-content')) {
        return
      }
      const key = evt.key || evt.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.showModal = false
      }
    },
    metricsTableSorter (a, b, key) {
      switch (key) {
        case 'extensionName':
          return this.labelizeExtensionName(a).localeCompare(this.labelizeExtensionName(b)) // from mixin
        case 'extensionType':
          return (a.extensionType || '').localeCompare(b.extensionType || '')
        default:
          return null
      }
    },
    copyPlans () {
      try {
        this.$store.commit('copyPlans', { plans: this.selectedPlans, type: this.tableType })
        this.$toast(`${this.selectedPlans.length} ${this.tableType}${(this.selectedPlans.length > 1) ? 's' : ''} copied to clipboard`, 'success')
      } catch (e) {
        this.$toast('Local storage unavailable for copy', 'danger')
      }
    },
    writeSharedBudgetMessage (plans) {
      const singular = plans.length === 1
      return `Your selection will not be applied to ${singular ? plans[0].name : 'the following campaigns'} because ${singular ? 'it is' : 'they are'} set to a bidding strategy that is not compatible with shared budgets${singular ? '.' : ': ' + plans.map(p => p.name).join(', ')}`
    },
    DDCSpecialChannels (channels) {
      const DDCChannels = [11, 14, 15, 16, 17, 18]
      for (const channel of channels) {
        if (!DDCChannels.includes(channel)) {
          return false
        }
      }
      return true
    },
    filterOnShowPlanStatus (plans, validStatuses) {
      const filteredItems = []
      for (const item of plans) {
        if (validStatuses.includes(this.coalescePlanItemStatus(item))) {
          filteredItems.push(item)
        } else if (this.parseImpression(item.impressions) > 0) {
          filteredItems.push({ ...item, shownBasedOnImpressions: true })
        }
      }
      return filteredItems
    },
    labelizePlanType (planType) {
      return this.$filters.labelizePlanType(planType)
    },
    goToBulkEditCreative () {
      const accountId = this.item.accountPlanId
      const selected = this.selectedPlans.length === 0
        ? null
        : this.selectedPlans.map(p => p.creativePlanId).join(',')

      this.$router.push({
        path: '/bulk-edit/',
        query: { accountId, selected }
      })
    }

  }
}
</script>
<style scoped lang="scss">
#manageHeaderNav {
  height: $site-header-height;
  background-color: $site-header-bg-color;
}
.manageHeaderNav {
  height: $site-header-height;
  background-color: $site-header-bg-color;
}

.manage-header {
  height: 3.9em;
  background-color: $fluency-gray;
}

.settings-pane {
  padding: 0em 1em 0;
  overflow-x: hidden;
}

.settings-pane-overflow {
  overflow-y: scroll;
}

.tab-row {
  height: 59px;
  max-height: 59px;
  overflow: hidden;
}

.stats-pane {
  flex-flow: column;
  height: 50%;
}

.navigation-pane {
  overflow-y: auto;
  height: calc(100vh - 50px);
  /*background-color: rgba(0, 0, 0, 0.06);*/
}

.inner-right-container {
  height: calc(100vh - 100px);
}

.add-buttons {
  top: 0;
  right: 0;
}

.right-hand-container {
  /*border-left-width: 10px !important;*/
  /*border-left-color: #f0f0f0 !important;*/
  padding-left: .75rem;
  padding-right: .25rem;;
  height: calc(100vh - 50px);
}

.window-resizer {
  width: 1.2em;
  float: right;
  display: block;
}

.window-resizer a {
  display: block;
  height: 1em;
}

.window-resizer-middle {
  font-size: 130%;
}

/* OVERRIDE STYLING - standalone page */
.standalone {
  overflow: hidden;

  .navigation-pane {
    height: calc(100vh - 50px);
  }

  .stats-pane {
    height: 50%;
  }

  .settings-pane {
    height: 50%;
  }

  .right-hand-container {
    height: calc(100vh - 50px);
  }
}

.animate-width {
  transition: 250ms all linear;
}

.col-0 {
  flex: 0 0 0%;
  max-width: 0%;
}

.navigation-pane.col-dynamic {
  flex: 0 0 25%
}

.right-hand-container.col-dynamic {
  flex: 0 0 75%
}

@media (min-width: 1600px) {
  .navigation-pane.col-dynamic {
    flex: 0 0 16.6%
  }
  .right-hand-container.col-dynamic {
    flex: 0 0 83.3%
  }
}

span {
  white-space: pre;
}

.top-pane, .bot-pane {
  transition: all 150ms linear;
}

.top-zero {
  .top-pane {
    flex: 0
  }

  .bot-pane {
    flex: 1
  }
}

.fifty-fifty {
  .top-pane {
    flex: 1
  }

  .bot-pane {
    flex: 1
  }
}

.bottom-zero {
  .top-pane {
    flex: 1 1 100%
  }

  .bot-pane {
    flex: 1 1 88px
  }
}

.select-dark {
  /*background-color: #2A363B;*/
  /*color: #ccc;*/
  padding: 5px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.side-nav-collapser {
  top: 4px;
  left: -1px;
  z-index: 1;
  border-color: $site-header-bg-color !important;
  border-width: 3px !important;
}

.overlay {
  background-color: $site-header-bg-color
}

.landing-tab-control-icon {
  transition: transform 250ms linear;

  &.landing-tab-control-icon--active {
    transform: rotate(360deg);
  }
}

.badge-success.badge-campaign {
  background-color: $color-key-campaign;
}
.badge-warning.badge-adGroup {
  background-color: $color-key-adgroup;
}

#manage-pane-nav {
  min-width: 38px !important;
}
</style>
<style lang="scss">
.manage-type .table-scroll th[role="rowheader"]:not(.customTableHeading) {
  background: transparent;
}
</style>
