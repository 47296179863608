<template>
  <b-modal v-if="shouldShowModal"
           :visible="shouldShowModal"
           :title="modalTitle"
           ok-only
           ok-title="Close"
           ok-variant="secondary"
           @hide="hideProductCatalogPreview">
    <div v-if="modalContent && modalContent.previewData">
      <t-row>
        <template v-for="(preview, previewIndex) in modalContent.previewData" :key="previewIndex">
          <t-col v-if="previewIndex < numToShow" cols="6" class="text-center">
            <p class="font-weight-bold mb-2">{{preview.title}}</p>
            <iframe :src="preview.preview" height="438" width="450" frameborder="0" scrolling="no"></iframe>
            <p class="mb-5">{{preview.description}}</p>
          </t-col>
        </template>
      </t-row>

      <div class="mt-4 text-center">
        <p v-if="modalContent.previewData.length > numToShow">
          <b-btn variant="primary" @click="showMoreProductCatalogPreviews()">Show 10 more</b-btn>
        </p>
        <p v-else>Showing all {{modalContent.previewData.length}} previews</p>
      </div>
    </div>
    <t-alert v-else show variant="">No previews found.</t-alert>
  </b-modal>
</template>

<script>
export default {
  name: 'productCatalogPreview',
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      numToShow: 10
    }
  },
  computed: {
    shouldShowModal () {
      return this.value !== null
    },
    modalTitle () {
      return this?.value?.name || ''
    },
    modalContent () {
      return this?.value?.productCatalogPreview || null
    }
  },
  watch: {
    shouldShowModal: {
      handler (val, newVal) {
        if (val !== newVal) {
          this.reset()
        }
      },
      immediate: true
    }
  },
  methods: {
    reset () {
      this.$setCompat(this, 'numToShow', 10)
    },
    hideProductCatalogPreview () {
      this.$emit('hide')
    },
    showMoreProductCatalogPreviews () {
      this.$setCompat(this, 'numToShow', this.numToShow + 10)
    }
  }
}
</script>

<style scoped>
</style>
