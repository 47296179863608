<template>
  <div>
    <b-modal v-model="showModalVar" title="Blueprint Tweaks">
      <template v-for="remap in remappings">
        <blueprint-remapping :blueprints="blueprints" :campaigns="campaigns" :value="remap" @input="updateRemap(remap, $event)"/>
      </template>
      <template v-if="remappings.length === 0">
        <div>Please add a remapping configuration from the drop down on the top right.</div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BlueprintRemapping from '@/components/common/blueprintRemapping'
export default {
  name: 'remapBlueprintsModal',
  components: { BlueprintRemapping },
  props: ['showModal'],
  data () {
    return {
      showModalVar: false,
      remappings: [],
      blueprints: [],
      campaigns: []
    }
  },
  computed: {
    selected () {
      return this.$store.getters['table/selectedRowObjs']('adGroup') || []
    },
    showPlanStatus () {
      return this.$store.getters.showPlanStatus
    }
  },
  watch: {
    showModal: {
      handler (newValue) {
        this.$setCompat(this, 'showModalVar', newValue)
        if (newValue) {
          this.getBlueprints()
          this.getCampaigns()
          this.computeRemapping()
        }
      },
      immediate: true
    }
  },
  methods: {
    // generate mappings based on selected ad groups
    computeRemapping () {
      const remappings = []
      let currentContentTemplateId = 0
      let currentCampaignPlanName = ''
      this.selected.forEach(selection => {
        if (selection.contentTemplateId !== currentContentTemplateId || selection.campaignPlanName !== currentCampaignPlanName) {
          currentContentTemplateId = selection.contentTemplateId
          currentCampaignPlanName = selection.campaignPlanName
          remappings.push({
            accountPlanId: this.$store.getters.activeItem.accountPlanId,
            clone: true,
            contentRemappingRules: [],
            contentTemplateId: currentContentTemplateId,
            enabled: true,
            destinationCampaignName: '',
            sourceCampaignName: selection.campaignPlanName,
            dirty: true
          })
        }
        remappings[remappings.length - 1].contentRemappingRules.push({
          matchType: 'MATCHES',
          adGroupMatcher: selection.name,
          enabled: true
        })
      })
      this.$setCompat(this, 'remappings', remappings)
    },
    async getBlueprints () {
      const response = await this.$res.fetch.templatesMinimal()
      if (response) {
        const bloops = response.map(template => {
          return {
            value: template.contentTemplateId,
            text: template.templateName
          }
        })
        this.$setCompat(this, 'blueprints', bloops)
      }
    },
    async getCampaigns () {
      const response = await this.$res.fetch.plan('campaign', {
        accountPlanId: this.$store.getters.activeItem.accountPlanId
      })
      if (response) {
        let campaignPlans = response
        if (this.showPlanStatus === 'Show All But Removed') {
          campaignPlans = response.filter(e => e.status === 'ENABLED' || e.status === 'PAUSED')
        } else if (this.showPlanStatus === 'Show All Enabled') {
          campaignPlans = response.filter(e => e.status === 'ENABLED')
        }
        campaignPlans = campaignPlans.map(campaignPlan => {
          return campaignPlan.name
        })
        console.log('campaignPlans', response)
        console.log('campaignPlans', campaignPlans)
        this.$setCompat(this, 'campaigns', campaignPlans)
      }
    },
  }
}
</script>

<style scoped>

</style>
