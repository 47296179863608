<template>
  <div class="position-relative manage-navigation" ref="navigationScrollPane" @click="$eventBus.$emit('bv::hide::popover'); openToolboxId = null">
    <div class="flex-column">

      <div class="px-3 pt-3 pb-3 position-sticky top-0 bg-white border-bottom" style="z-index: 1;">
        <content-filter v-model="accountListFilter"
                        placeholder="Type to search for accounts"
                        prepend-icon="search"
                        :lowercase="false"
                        clear-text="Clear"
                        size="md"
                        class="mb-0"
                        :debounce="700"
                        :loading="accountsLoading"
                        @clear="autoSetCurrentPage(showAccountPlansList, activeAccountPlanId, true)"/>
      </div>

      <ul class="p-0 list-unstyled" id="manage-nav-list">

        <li v-for="account in pagedAccountPlans"
            :key="account.accountPlanId"
            :id="`nav-account-${account.accountPlanId}`"
            :class="['account-item',  {'active': activeAccountPlanId === account.accountPlanId}] ">

          <div :class="classes.linksContainer">
            <div :class="classes.links">
              <span @click="toggleExpandObject(!expandedItems[account.accountPlanId], account)">
                <fluency-icon type="chevronRight"
                              :class="[classes.chevron, 'test1', { collapsed: !expandedItems[account.accountPlanId] }]" />
              </span>
              <span :class="classes.link"
                    @click="selectNavItem($event, true, account)">{{account.name}}</span>
            </div>

            <fluency-icon v-if="account.accountPlanId === activeAccountPlanId && $store.getters.loading.subNavigation"
                          type="loop"
                          :class="classes.loading" />

            <b-button :id="'popover-target-' + account.accountPlanId"
                      variant="link"
                      class="account"
                      :class="classes.linkBtn"
                      @click.stop="toggleToolbox('account', account.accountPlanId)">
              <fluency-icon type="toolbox" />
              <template v-if="openToolboxId === 'account-'+account.accountPlanId"> <!-- Prevents rendering the popover for each account until user clicks the wrench-->
                <tasty-popover triggers="click"
                               placement="right"
                               boundary="window"
                           :target="'popover-target-' + account.accountPlanId"
                           show>
                  <b-nav vertical class="w-100" style="font-size: 12px">
                    <li class="nav-item">
                      <nuxt-link :to="`/insights/account-performance/?account=${account.accountPlanId}`" class="nav-link hover-highlight"><fluency-icon type="budget" />Account Performance</nuxt-link>
                    </li>
                    <hr />
                    <li class="nav-item">
                      <nuxt-link :to="'/manage/create/?type=budget&accountPlanId=' + account.accountPlanId" class="nav-link hover-highlight"><fluency-icon type="budget" /> Create New Budget</nuxt-link>
                    </li>
                    <li class="nav-item">
                      <nuxt-link :to="'/manage/create/?type=campaign&accountPlanId=' + account.accountPlanId" class="nav-link hover-highlight"><fluency-icon type="campaign" /> Create New Campaign</nuxt-link>
                    </li>
                    <li class="nav-item">
                      <nuxt-link :to="'/manage/create/?type=extension&accountPlanId=' + account.accountPlanId" class="nav-link hover-highlight"><fluency-icon type="extension"/> Create New Extensions</nuxt-link>
                    </li>
                  </b-nav>
                </tasty-popover>
              </template>
            </b-button>
          </div>
          <div v-if="expandedItems[account.accountPlanId]">
            <ul v-if="!hasCampaigns(account)" class="list-unstyled">
              <li :class="classes.noResults">No campaigns setup.</li>
            </ul>
            <template v-else>
              <div v-for="(campaigns, advertisingChannel) in structuredCampaignNavigation[account.accountPlanId]"
                   :key="advertisingChannel"
                   class="campaign-item campaign-item-border pb-3">
                <span class="d-flex align-items-center border-bottom mx-3 mb-1 pl-3 py-2">
                  <h4 class="mb-0">
                    <fluency-icon
                      :type="AdvertisingChannelIcons.get(advertisingChannels[advertisingChannel])"
                      class="text-success mr-3"/>
                  </h4>
                  <b>{{advertisingChannels[advertisingChannel]}}</b>
                </span>
                <ul class="list-unstyled">
                  <li v-for="campaign in showPlans(campaigns, account.accountPlanId, advertisingChannel)"
                      :id="`nav-campaign-${campaign.campaignPlanId}`"
                      :key="campaign.campaignPlanId"
                      :class="['campaign-select', {'active': activeCampaignPlanId === campaign.campaignPlanId}]">
                    <div :class="classes.linksContainer">
                      <div :class="classes.links" >
                        <span :aria-controls="`collapse-campaign-${campaign.campaignPlanId}`"
                              :aria-expanded="expandedItems[campaign.campaignPlanId]"
                              @click="toggleExpandObject(!expandedItems[campaign.campaignPlanId], account, campaign)">
                          <fluency-icon type="chevronRight"
                                        :class="[classes.chevron, { collapsed: !expandedItems[campaign.campaignPlanId] }]" />
                        </span>
                        <span :class="[classes.link, { 'text-muted': campaign.status !== 'ENABLED' }]" class="manage-nav-campaign-link"
                              @click="selectNavItem($event, true, account, campaign)">{{campaign.name}}</span>
                      </div>

                      <fluency-icon v-if="campaign.campaignPlanId === activeCampaignPlanId && $store.getters.loading.subNavigation"
                                    type="loop"
                                    :class="classes.loading" />

                      <b-button :id="'popover-target-' + campaign.campaignPlanId"
                                :class="classes.linkBtn"
                                class="campaign"
                                variant="link"
                                @click.stop="toggleToolbox('campaign', campaign.campaignPlanId)">
                        <fluency-icon type="toolbox"/>
                        <template v-if="openToolboxId === 'campaign-'+campaign.campaignPlanId"> <!-- Prevents rendering the popover for each account until user clicks the wrench-->
                          <tasty-popover :target="'popover-target-' + campaign.campaignPlanId"
                                         placement="right"
                                         boundary="window"
                                     show
                                     triggers="click">
                            <b-nav class="w-100" style="font-size: 12px" vertical>
                              <li class="nav-item">
                                <nuxt-link :to="'/manage/create/?type=adGroup&accountPlanId=' + account.accountPlanId + '&campaignPlanId=' + campaign.campaignPlanId" class="nav-link hover-highlight"><fluency-icon type="adgroup" /> Create New Ad Group</nuxt-link>
                              </li>
                              <li class="nav-item" v-if="campaign.advertisingChannelId === 1">
                                <nuxt-link :to="'/manage/create/?type=extension&accountPlanId=' + account.accountPlanId + '&campaignPlanId=' + campaign.campaignPlanId" class="nav-link hover-highlight"><fluency-icon type="extension" /> Create New Extensions</nuxt-link>
                              </li>
                            </b-nav>
                          </tasty-popover>
                        </template>
                      </b-button>
                      <partner-icons :partners="campaign.partnerObjects" primaryType="campaign" :account-plan-id="account.accountPlanId"/>
                    </div>
                    <b-collapse :id="`collapse-campaign-${campaign.campaignPlanId}`" v-model="expandedItems[campaign.campaignPlanId]">
                      <div v-if="expandedItems[campaign.campaignPlanId]">
                        <ul v-if="!adGroupNavigation[campaign.campaignPlanId] || adGroupNavigation[campaign.campaignPlanId].length === 0" class="list-unstyled">
                          <li :class="classes.noResults">No Ad Groups setup.</li>
                        </ul>
                        <ul v-else class="list-unstyled">

                          <li v-for="adGroup in showPlans(adGroupNavigation[campaign.campaignPlanId])"
                              :key="adGroup.adGroupPlanId"
                              :id="`nav-adGroup-${adGroup.adGroupPlanId}`"
                              :class="['ad-group-item', 'ad-group-item-border',  {'active': activeAdGroupPlanId === adGroup.adGroupPlanId}]">
                            <div :class="classes.linksContainer">
                              <div :class="classes.links">
                                <span :class="[classes.link, { 'text-muted': campaign.status !== 'ENABLED' }]"
                                      @click="selectNavItem($event, null, account, campaign, adGroup)">{{adGroup.name}}</span>
                              </div>

                              <b-button :id="'popover-target-' + adGroup.adGroupPlanId"
                                        :class="classes.linkBtn"
                                        class="ad-group"
                                        variant="link"
                                        @click.stop="toggleToolbox('adGroup', adGroup.adGroupPlanId)">
                                <fluency-icon type="toolbox" />
                                <template v-if="openToolboxId === 'adGroup-'+adGroup.adGroupPlanId"> <!-- Prevents rendering the popover for each account until user clicks the wrench-->
                                  <tasty-popover :target="'popover-target-' + adGroup.adGroupPlanId"
                                                 placement="right"
                                                 boundary="window"
                                             show
                                             triggers="click">
                                    <b-nav class="w-100" style="font-size: 12px" vertical>
                                      <li v-if="campaign.advertisingChannelId === 1" class="nav-item"><nuxt-link class="nav-link hover-highlight" :to="'/manage/create/?type=keyword&accountPlanId=' + account.accountPlanId + '&campaignPlanId=' + campaign.campaignPlanId + '&adGroupPlanId=' + adGroup.adGroupPlanId"><fluency-icon type="keyword" /> Create New Keyword</nuxt-link></li>
                                      <li v-if="campaign.advertisingChannelId === 1" class="nav-item"><nuxt-link class="nav-link hover-highlight" :to="'/manage/create/?type=extension&accountPlanId=' + account.accountPlanId + '&campaignPlanId=' + campaign.campaignPlanId + '&adGroupPlanId=' + adGroup.adGroupPlanId"><fluency-icon type="extension" /> Create New Extension</nuxt-link></li>
                                      <li class="nav-item"><nuxt-link class="nav-link hover-highlight" :to="'/manage/create/?type=creative&accountPlanId=' + account.accountPlanId + '&campaignPlanId=' + campaign.campaignPlanId + '&adGroupPlanId=' + adGroup.adGroupPlanId"><fluency-icon type="creative" /> Create New Creative</nuxt-link></li>
                                    </b-nav>
                                  </tasty-popover>
                                </template>
                              </b-button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-else>
                        <ul class="list-unstyled">
                          <li class="ml-lg-5">&nbsp;</li>
                          <li class="ml-lg-5">&nbsp;</li>
                        </ul>
                      </div>
                    </b-collapse>
                  </li>
                </ul>
                <t-button-inline v-if="showPlanStatus !== 'Show All Enabled' && shownCampaignLengths[`${account.accountPlanId}${advertisingChannel}`] > 0"
                                 severity="secondary"
                                 link-hover
                                 class="ml-5 mt-3 px-3"
                                 @click="toggleDisabled(account.accountPlanId, advertisingChannel)">
                  <template v-if="showDisabled[`${account.accountPlanId}${advertisingChannel}`]">
                    <template v-if="showPlanStatus === 'Show All But Removed'">
                      Hide Paused Campaigns
                    </template>
                    <template v-else>
                      Hide Paused/Removed Campaigns
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="showPlanStatus === 'Show All But Removed'">
                      Show {{shownCampaignLengths[`${account.accountPlanId}${advertisingChannel}`]}} Paused Campaigns
                    </template>
                    <template v-else>
                      Show {{shownCampaignLengths[`${account.accountPlanId}${advertisingChannel}`]}} Paused/Removed Campaigns
                    </template>
                  </template>
                </t-button-inline>
              </div>
            </template>
          </div>

        </li>

      </ul>
      <p-paginator v-if="showAccountPlansList.length > pageSize"
                  :totalRecords="showAccountPlansList.length"
                  :rows="pageSize"
                  aria-controls="manage-nav-list"
                  template="PageLinks"
                  class="small"
                  v-model:first="currentPageFirstIndex" />
    </div>
  </div>
</template>

<script>
import { AdvertisingChannelIcons } from 'assets/js/constants'
import ContentFilter from '@/components/common/forms/content-filter.vue'
import PartnerIcons from '@/components/common/partnerIcons.vue'
import { shallowRef } from 'vue'
import _pick from 'lodash.pick'
import { useAccountsById } from '@/composables/useAccountsById.js'
import { useCampaignNavigation } from '../../composables/useCampaignNavigation'
import { useAdGroupNavigation } from '../../composables/useAdGroupNavigation'

export default {
  name: 'ManageNavigation',
  components: { PartnerIcons, ContentFilter },
  props: ['modalShown'],
  setup () {
    const showAccountPlansList = shallowRef([])
    const { getAccountsById } = useAccountsById()
    return {
      showAccountPlansList,
      getAccountsById
    }
  },
  data () {
    return {
      classes: {
        headers: 'ml-3 py-3 px-4',
        linksContainer: 'd-flex align-items-center pr-2',
        links: 'my-2 pl-4 nav-item-container d-flex align-items-start flex-grow-1',
        link: 'manage-nav-link cursor-pointer flex-grow-1',
        linkBtn: 'show-on-hover invisible cursor-pointer pt-2 pb-0',
        chevron: 'cursor-pointer collapse-indicator text-muted mr-2 flex-shrink-1',
        noResults: 'ml-5 pl-5 font-italic text-muted pb-2',
        loading: 'rotating loading mt-2'
      },
      searchInput: null,
      lastScrolledTo: null,
      activeAccountPlanId: -1,
      activeCampaignPlanId: -1,
      activeAdGroupPlanId: -1,
      openToolboxId: null,
      currentPageFirstIndex: 0,
      pageSize: 50,
      expandedItems: {},
      AdvertisingChannelIcons,
      accountsLoading: false,
      showDisabled: {},
      shownCampaignLengths: {}
    }
  },
  mounted () {
    this.showAccountPlansList = this.showPlans(Array.from(this.getAccountsById().values()))
      .map(acct => ({
        accountPlanId: acct.accountPlanId,
        name: acct.name
      }))
    this.$store.subscribe(this.storeSubscribeAccountsById)
  },
  beforeUnmount () {
    const idx = this.$store._subscribers.indexOf(this.storeSubscribeAccountsById)
    if (idx >= 0) {
      this.$store._subscribers.splice(idx, 1)
    }
  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        const { accountPlanId, campaignPlanId, adGroupPlanId } = val || {}
        const oldAccountPlanId = (oldVal || {}).accountPlanId
        const oldCampaignPlanId = (oldVal || {}).campaignPlanId
        this.activeAccountPlanId = accountPlanId
        this.activeCampaignPlanId = campaignPlanId || -1
        this.activeAdGroupPlanId = adGroupPlanId
        if (val && !val.expanded) {
          this.toggleExpand(true, accountPlanId, campaignPlanId, oldAccountPlanId, oldCampaignPlanId)
        }
        if (accountPlanId !== oldAccountPlanId) {
          this.autoSetCurrentPage(this.showAccountPlansList, accountPlanId)
        }
        if (accountPlanId) {
          this.$nextTick(() => this.scroller())
        }
      },
      immediate: true
    },
    showAccountPlansList: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal?.length !== oldVal?.length) {
          this.autoSetCurrentPage(newVal, this.activeAccountPlanId)
          this.$nextTick(() => this.scroller())
        }
        this.accountsLoading = false
      }
    }
  },
  computed: {
    isInternalUser () {
      return this.$store.getters?.user?.internalUser
    },
    item () {
      return this.$store.getters.activeItem
    },
    accountListFilter: {
      get () {
        const filter = this.$store.getters.activeSegment?.criteriaJson?.name
        if (Array.isArray(filter)) {
          return filter.join(' ').replace(/\*/g, '')
        } else {
          return ''
        }
      },
      set (val) {
        if (val !== this.accountListFilter) {
          this.accountsLoading = true
          if (val.length > 0) {
            this.$store.dispatch('applySegment', { name: [`*${val}*`] })
          } else {
            this.$store.dispatch('removeSegmentCriteria', 'name')
          }
        }
      }
    },
    navigationSearchTree () {
      return this.$store.getters.navigationSearchTree
    },
    showPlanStatus () {
      return this.$store.getters.showPlanStatus
    },
    pagedAccountPlans () {
      if (this.showAccountPlansList.length <= this.pageSize) {
        return this.showAccountPlansList
      }
      return this.showAccountPlansList.slice(this.currentPageFirstIndex, this.currentPageFirstIndex + this.pageSize)
    },
    structuredCampaignNavigation () {
      return useCampaignNavigation().getCampaignNavigation()
    },
    adGroupNavigation () {
      return useAdGroupNavigation().getAdGroupNavigation()
    },
    advertisingChannels () {
      return this.$store.getters.advertisingChannels.reduce((accumulator, current) => ({ ...accumulator, [current.advertisingChannelId]: current.channelLabel }), {})
    }
  },
  methods: {
    storeSubscribeAccountsById (mutation, state) {
      if (mutation.type === 'setAccountsById' || mutation.type === 'updateAccountInAccountsById') {
        this.showAccountPlansList = this.showPlans(Array.from(this.getAccountsById().values()))
          .map(acct => ({
            accountPlanId: acct.accountPlanId,
            name: acct.name
          }))
      }
    },
    selectNavItem (event, expandValue, account, campaign, adGroup) {
      const planType = adGroup ? 'adGroup' : campaign ? 'campaign' : 'account'

      this.activeAccountPlanId = account.accountPlanId
      this.activeCampaignPlanId = campaign?.campaignPlanId
      this.activeAdGroupPlanId = adGroup?.adGroupPlanId

      this.$track.event(
        'manage_navigation',
        { type: planType },
        'manage',
        event.target.closest('.manage-nav-link')
      )

      if (expandValue !== null) {
        this.toggleExpandObject(expandValue, account, campaign)
      }
      this.scroller()

      this.$nextTick(async () => {
        if (adGroup) {
          await this.$router.push(`/manage/adgroup/?id=${adGroup.adGroupPlanId}${this.getQueryParamsForNavEvent('adGroup')}`)
          this.popDrawer()
        } else if (campaign) { // if the nav item you are selecting is a campaign (when you click an account selection there is no type
          await this.$router.push(`/manage/campaign/?id=${campaign.campaignPlanId}${this.getQueryParamsForNavEvent('campaign')}`)
          this.popDrawer()
        } else {
          await this.$router.push(`/manage/account/?id=${account.accountPlanId}${this.getQueryParamsForNavEvent('account')}`)
          this.popDrawer()
        }
      })
    },
    popDrawer () {
      this.$nextTick(() => {
        const v = this
        setTimeout(() => {
          v.$eventBus.$emit('manage-drawer-open', { contextSensitive: true })
        }, 333)
      })
    },
    toggleExpand (expandValue, accountPlanId, campaignPlanId, oldAccountPlanId, oldCampaignPlanId) {
      this.setExpandValue(accountPlanId, expandValue)
      this.setExpandValue(campaignPlanId, expandValue)
      this.$nextTick(() => {
        if (expandValue && (accountPlanId !== oldAccountPlanId || campaignPlanId !== oldCampaignPlanId)) {
          this.fetchNav({ accountPlanId, campaignPlanId })
        }
      })
    },
    setExpandValue (id, expandValue) {
      if (id) {
        if (this.expandedItems[id]) {
          this.expandedItems[id] = expandValue
        } else {
          this.expandedItems[id] = expandValue
        }
      }
    },
    toggleExpandObject (expandValue, account, campaign) {
      if (expandValue) {
        this.fetchNav({
          accountPlanId: account.accountPlanId,
          campaignPlanId: campaign ? campaign.campaignPlanId : null
        })
      }
      const id = campaign ? campaign.campaignPlanId : account.accountPlanId
      this.setExpandValue(id, expandValue)
    },
    async fetchNav (plan) {
      if (plan?.accountPlanId) {
        if (!this.structuredCampaignNavigation[plan.accountPlanId]) {
          const response = await this.$res.fetch.structuredNavigation(plan.accountPlanId)
          if (response) {
            Object.entries(response).forEach(entry => {
              response[entry[0]] = entry[1] // trim the campaign plan
                .map(cmp => _pick(cmp, ['accountPlanId', 'campaignPlanId', 'status', 'name', 'advertisingChannelId', 'partnerObjects']))
            })
            this.$store.commit('campaignNavigation', { [plan.accountPlanId]: response })
          }
        }
      }
      if (plan?.campaignPlanId) {
        if (!this.adGroupNavigation[plan.campaignPlanId]) {
          const response = await this.$res.fetch.navigation('adgroup', plan)
          if (response) {
            this.$store.commit('adGroupNavigation', { [plan.campaignPlanId]: response })
          }
        }
      }
    },
    scroller: function () {
      const scrollFn = () => this.$nextTick(() => {
        const { manageType } = this.$route.params
        const planId = this.activeAccountPlanId
        const currentScrollAttempt = this.lastScrolledTo !== null ? `account${planId}` : 'initial'
        if (manageType && planId && this.lastScrolledTo !== currentScrollAttempt) {
          const targetEl = document.getElementById(`nav-account-${planId}`)
          if (targetEl) {
            targetEl.scrollIntoView()
            if (this.$refs.navigationScrollPane) {
              this.$refs.navigationScrollPane.scrollTop -= 73
            }
            this.lastScrolledTo = currentScrollAttempt
          }
        }
      })

      scrollFn()
    },
    showPlans (plansObject, accountId, channel) {
      let plans = []
      if (plansObject) {
        if (Array.isArray(plansObject)) {
          plans = plansObject
        } else if (plansObject instanceof Map) {
          plans = Array.from(plansObject.values())
        }
      }
      if (plans) {
        if (this.showPlanStatus === 'Show All Enabled') {
          plans = plans.filter(e => e.status === 'ENABLED')
        }
        if (this.showPlanStatus === 'Show All But Removed') {
          plans = plans.filter(e => e.status === 'ENABLED' || e.status === 'PAUSED')
        }
        if (accountId && channel) {
          const fPlans = plans.filter(e => e.status === 'ENABLED')
          this.shownCampaignLengths[`${accountId}${channel}`] = plans.length - fPlans.length
          if (this.showPlanStatus !== 'Show All Enabled' && !this.showDisabled[`${accountId}${channel}`]) {
            return fPlans
          }
        }
      }
      return plans
    },
    toggleDisabled (accountId, channel) {
      const key = `${accountId}${channel}`
      if (this.showDisabled[key]) {
        this.showDisabled[key] = false
        this.lastScrolledTo = null
        this.scroller()
      } else {
        this.showDisabled[key] = true
      }
    },
    getQueryParamsForNavEvent (type) {
      let query = ''
      const { form, table } = this.$route.query
      if (table) {
        query += `&table=${table}`
      }
      if (this.$route.params.manageType === type) {
        if (form) {
          query += `&form=${form}`
        }
      }
      return query
    },
    hasCampaigns (account) {
      const campaignList = this.structuredCampaignNavigation[account.accountPlanId]
      if (campaignList && Object.keys(campaignList).length > 0) {
        return true
      }
      return false
    },
    getAdGroups (account, campaign) {
      const adGroupList = this.adGroupNavigation[campaign.campaignPlanId]
      if (adGroupList) {
        return true
      }
      return false
    },
    toggleToolbox (type, id) {
      this.openToolboxId = this.openToolboxId === `${type}-${id}` ? null : `${type}-${id}`
    },
    autoSetCurrentPage (accountList = [], activeAccountPlanId, scroll) {
      const index = accountList.findIndex(a => a.accountPlanId === this.activeAccountPlanId)
      if (index >= 0) {
        this.currentPageFirstIndex = Math.floor(index / this.pageSize) * this.pageSize

        if (scroll) {
          this.$nextTick(() => this.scroller())
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.account-item,
.campaign-select,
.ad-group-item {
  .collapse-indicator {
    position: relative;
    min-width: 17px;
    min-height: 17px;
    max-width: 17px;
    max-height: 17px;
    transition: transform 150ms linear;
    transform-origin: center center;

    &:before {
      position: absolute;
      top: 3px;
      left: 3px;
    }

    &:hover {
      color: $fluency-gray !important;
    }
  }

  .manage-nav-link{
    word-break: break-word;
    &:hover {
      color: #000 !important;
    }
  }


  &.active > div:first-child {
    background-color: darken($fluency-lightergray, 5%);
  }

  .nav-item-container .collapse-indicator:not(.collapsed) {
    transform: rotate(90deg);
  }

}

.account-item:hover {
  .account.show-on-hover {
    visibility: visible !important;
  }
}

.campaign-select:hover {
  .campaign.show-on-hover {
    visibility: visible !important;
  }
}

.ad-group-item:hover {
  .ad-group.show-on-hover {
    visibility: visible !important;
  }
}

.campaign-item,
.ad-group-item {
  margin-left: 21px;
}

.campaign-item-border,
.ad-group-item-border {
  border-left-width: 2px;
  border-left-style: solid;
}
.campaign-item-border {
  border-left-color: #99B898;
}
.ad-group-item-border {
  border-left-color: #FECEA8;
}

.results-header {
  .fluency-icon {
    padding-left: 0;
  }
}

.scroll-content {
  overflow: auto
}
</style>
<style lang="scss">
.manage-navigation mark {
  padding: 0.05rem;

  &.account {
    background-color: $fluency-blue;
  }

  &.campaign {
    background-color: $fluency-green;
  }

  &.adGroup {
    background-color: $fluency-yellow;
  }
}
</style>
