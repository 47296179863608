<template>
  <b-modal :visible="show"
           title="Duplicate Plans"
           body-class="overflow-visible"
           ok-title="Duplicate"
           @change="$emit('update:show', $event)"
           @ok="executeDuplication">

    <div><em><b>{{plans.length}} {{$filters.labelizePlanType(planType)}}{{plans.length > 1 ? 's' : ''}}</b> selected</em></div>

    <hr class="my-4" />

    <t-row>
      <!-- == PREFIX/SUFFIX ======================================================================================== -->
      <t-col v-if="nameReplacements.length > 0" cols="4">
        <b-form-group label="Name Replacements">
          <template #label><h5>Name Replacements <info-tooltip popover title="Values to be prepended and appended to the duplicated names" /></h5></template>
          <div class="mad-lib-container" :class="classes.formInset">
            <label class="mb-0">Prefix:</label>
            <basic-mad-lib-input v-model="newNamePrefix" placeholder="(Optional)" fill-width />
          </div>
          <div class="mad-lib-container" :class="classes.formInset">
            <label class="mb-0">Suffix:</label>
            <basic-mad-lib-input v-model="newNameSuffix" placeholder="(Optional)" fill-width />
          </div>
        </b-form-group>
      </t-col>
      <!-- == DEPTH ================================================================================================ -->
      <t-col v-if="!isLeafNodePlanType" cols="4">
        <b-form-group label="Duplicate Depth">
          <template #label><h5>Depth</h5></template>
          <b-form-radio :checked="selectedCopyDepth" @input="selectedCopyDepth = $event" name="paste-depth" :value="copyDepthOpts.shallow" class="mb-3" :class="classes.formInset">
            Only the {{$filters.labelizePlanType(planType)}}{{plans.length > 1 ? 's' : ''}}
          </b-form-radio>
          <b-form-radio :checked="selectedCopyDepth" @input="selectedCopyDepth = $event" name="paste-depth" :value="copyDepthOpts.deep" :class="classes.formInset">
            The {{$filters.labelizePlanType(planType)}}{{plans.length > 1 ? 's' : ''}} and all
            {{plans.length > 1 ? 'their' : 'its'}} children
          </b-form-radio>
        </b-form-group>
      </t-col>
      <!-- == DATES ================================================================================================ -->
      <t-col v-if="showDateOptions" cols="4">
        <b-form-group label="Dates">
          <template #label><h5>Start/End Dates <em class="text-muted">(Optional)</em> <info-tooltip popover title="Optionally choose start and/or end dates to set in the duplicated items"/></h5></template>
          <div class="d-flex-center mb-2" :class="classes.formInset">
            <label class="date-label mb-0 white-space-no-wrap mr-3">Start Date:</label>
            <single-date-picker v-model="startDateCopyOpt"></single-date-picker>
          </div>
          <div class="d-flex-center" :class="classes.formInset">
            <label class="date-label mb-0 white-space-no-wrap mr-3">End Date:</label>
            <single-date-picker v-model="endDateCopyOpt" :min-date="startDateCopyOpt || new Date()"></single-date-picker>
          </div>
        </b-form-group>
      </t-col>
    </t-row>

    <hr class="mt-0 mb-4" />

    <t-row>
      <!-- == NEW NAMES ============================================================================================ -->
      <t-col v-if="nameReplacements.length > 0" cols="4">
        <b-form-group label="Name Replacements">
          <template #label><h5>New {{$filters.labelizePlanType(planType)}} Names <info-tooltip popover title="Edit each individual name for a complete name replacement. The edited name will take precedence over any prefix or suffix." /></h5></template>

          <div v-for="(nameReplacement, idx) of nameReplacements"
               :key="idx"
               class="mad-lib-container"
               :class="classes.formInset">
            <basic-mad-lib-input v-model="nameReplacement.new" fill-width />
          </div>
        </b-form-group>
      </t-col>
      <!-- == TEXT REPLACEMENTS ==================================================================================== -->
      <t-col cols="8">
        <b-form-group label="Find and Replace Text">
          <template #label>
            <h5>
              Find &amp; Replace Text
              <info-tooltip popover>
                <template #title>
                  You can use Find and Replacement text to edit the name or the text inside your duplicates in bulk.
                  <ul class="">
                    <li>Campaigns: Name</li>
                    <li>Ad Groups: Name, Destination Url</li>
                    <li>Creative: Name, Ad Text Assets, Destination Url</li>
                  </ul>
                </template>
              </info-tooltip>
            </h5>
          </template>

          <div v-for="(replacement, replacementIndex) in replacements"
               :key="`replacement-${replacementIndex}`"
               class="d-flex-center mb-2">
            <div class="mad-lib-container" :class="classes.formInset">
              <basic-mad-lib-input v-model="replacement.from" placeholder="Find Text" fill-width />
            </div>
            <div class="mx-3"><fluency-icon type="arrowRight" /></div>
            <div class="mad-lib-container">
              <basic-mad-lib-input v-model="replacement.to" placeholder="Replace With" fill-width />
            </div>
          </div>

          <b-button variant="ghost-secondary"
                    @click="replacements.push({from:'', to:''})">
            <fluency-icon type="add" class="pr-1" /> Find and Replace Text
          </b-button>
        </b-form-group>
      </t-col>
    </t-row>

    <t-alert :show="errorDuplicating" variant="danger">
      There was an error duplicating the {{$filters.labelizePlanType(planType)}}{{plans.length > 1 ? 's' : ''}}{{planType === 'campaign' ? ` and Budget${plans.length > 1 ? 's': ''}` : ''}}. It is likely because
      a {{$filters.labelizePlanType(planType)}}{{planType === 'campaign' ? ` or Budget` : ''}} with the same name already exists. Try again by adjusting the name.
    </t-alert>

    <template #modal-footer v-if="duplicating">
      <fluency-loader inline dots color />
    </template>
  </b-modal>
</template>

<script>
import InfoTooltip from '@/components/common/forms/infoTooltip'
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import hierarchy from 'assets/js/hierarchy'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import SingleDatePicker from '@/components/common/dateSelector/components/singleDatePicker.vue'

export default {
  name: 'duplicatePlans',
  components: { SingleDatePicker, InfoTooltip, FluencyLoader, BasicMadLibInput },
  props: {
    plans: {
      type: Array,
      required: true
    },
    planType: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    accountPlanId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      replacements: [],
      nameReplacements: this.plans.filter(p => p.name?.length > 0).map(p => ({ sourceId: p[`${this.planType}PlanId`], original: p.name, new: `${p.name} (Copy)`, edit: false })),
      newNamePrefix: '',
      newNameSuffix: '(Copy)',
      copyDepthOpts: {
        deep: 'DEEP',
        shallow: 'SHALLOW'
      },
      selectedCopyDepth: 'DEEP',
      startDateCopyOpt: null,
      endDateCopyOpt: null,
      duplicating: false,
      errorDuplicating: false,
      planListShowAll: false,
      classes: {
        formInset: 'ml-2'
      }
    }
  },
  async created () {

  },
  watch: {
    newNameSuffix: {
      handler (newVal, oldVal) {
        if (oldVal?.length > 0 || newVal?.length > 0) {
          this.nameReplacements.forEach(r => {
            if (r.new && r.new.endsWith(oldVal) && (newVal === '' || !r.new.endsWith(newVal) || oldVal?.endsWith(newVal))) {
              const index = r.new.lastIndexOf(oldVal)
              if (index > -1) {
                r.new = `${r.new.slice(0, index).trim()} ${newVal}`.trim()
              }
            }
          })
        }
      }
    },
    newNamePrefix: {
      handler (newVal, oldVal) {
        if (oldVal?.length > 0 || newVal?.length > 0) {
          this.nameReplacements.forEach(r => {
            if (r.new && r.new.startsWith(oldVal) && (newVal === '' || !r.new.startsWith(newVal) || oldVal?.startsWith(newVal))) {
              r.new = `${newVal} ${r.new.replace(oldVal, '').trim()}`
              // const index = r.new(oldVal)
              // if (index > -1) {
              //   r.new = `${r.new.slice(0, index).trim()} ${newVal}`
              // }
            }
          })
        }
      }
    }
  },
  computed: {
    level () {
      switch (this.planType.toLowerCase()) {
        case 'campaign':
          return 'CAMPAIGN'
        case 'adgroup':
          return 'ADGROUP'
        case 'creative':
        case 'ad':
          return 'CREATIVE'
      }
      return null
    },
    parentPlanType () {
      switch (this.level) {
        case 'CAMPAIGN': return 'account'
        case 'ADGROUP': return 'campaign'
        case 'CREATIVE': return 'adGroup'
      }
      return null
    },
    isLeafNodePlanType () {
      return hierarchy.isLeafNode(this.planType)
    },
    plansGroupedByParentId () {
      return this.plans.groupBy(plan => plan[`${this.parentPlanType}PlanId`])
    },
    nParents () {
      return Object.keys(this.plansGroupedByParentId).length
    },
    showDateOptions () {
      return this.planType === 'campaign' || this.planType === 'adGroup'
    }
  },
  methods: {
    async executeDuplication (modalEvent) {
      if (!this.level) return
      modalEvent.preventDefault()
      this.duplicating = true
      this.errorDuplicating = false
      const plansGroupedByParentId = { ...this.plansGroupedByParentId }
      const promises = []
      for (const entry of Object.entries(plansGroupedByParentId)) {
        const parentId = entry[0]
        const plans = entry[1]

        const pasteBuffer = {
          level: this.level,
          strategy: this.selectedCopyDepth,
          options: {
            autoReplaceAccountValues: false,
            startDate: this.startDateCopyOpt,
            endDate: this.endDateCopyOpt,
            namePrefix: this.newNamePrefix,
            nameSuffix: this.newNameSuffix,
            nameReplacements: this.nameReplacements.filter(nr => nr.original !== nr.new).map(nr => ({ sourceId: nr.sourceId, newName: nr.new }))
          },
          sourceItemIds: plans.map(plan => plan[`${this.planType}PlanId`]),
          destinationParentId: parentId,
          destinationParentAccountId: this.accountPlanId,
          replacements: this.replacements
        }

        if (pasteBuffer.destinationParentId && pasteBuffer.destinationParentAccountId) {
          promises.push(this.$res.set.pastePlans(pasteBuffer))
        }
      }

      let responses = []
      try {
        responses = await Promise.all(promises)
      } catch (e) { console.error(e) }
      this.duplicating = false

      if (responses.some(res => !res)) {
        this.$toast('Items failed to duplicate', 'danger')
        this.errorDuplicating = true
      } else {
        const totalErrorCount = responses.sum(res => res.nErrors)
        if (totalErrorCount >= 1) {
          this.$toast(`Encountered ${totalErrorCount} error${totalErrorCount > 1 ? 's' : 's'} while trying to duplicate`, 'danger')
          this.errorDuplicating = true
        } else {
          this.$toast('Items Duplicated', 'success')
        }
      }
      if (!this.errorDuplicating) {
        this.$emit('update:show', false)
        const query = { ...this.$route.query }
        const params = { ...this.$route.params }
        if (Object.keys(this.plansGroupedByParentId).length === 1) {
          query.id = Object.keys(this.plansGroupedByParentId)[0]
          params.manageType = this.parentPlanType
        } else {
          query.id = this.accountPlanId
          params.manageType = 'account'
        }
        if (query.id === this.$route.query.id && params.manageType === this.$route.params.manageType) {
          this.$store.dispatch('fetchPlansWithMetrics', { fetchType: this.planType, forceBudgetReload: true })
        } else {
          this.$router.push({
            ...this.$route,
            query,
            params
          })
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.date-label {
  min-width: 60px;
}
</style>
