<template>
  <div>
    <div class="overflow-auto-y overflow-hidden-x" style="height:250px;">
      <canvas ref="chart" class="w-100" :style="`height:${chartHeight}`"/>
    </div>
    <div v-if="scrollChart" class="overflow-hidden" style="height:1.8em;margin-left:0.3em;margin-right:1.2em;">
      <canvas ref="chartAxis" class="w-100" :style="`height:${chartHeight}`"/>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import _ from 'lodash'

export default {
  name: 'viewSimilarMetricsChart',
  props: ['chartData', 'highlightIndex', 'selectedMetric'],
  data () {
    return {
      chartData2: {},
      options: {},
      formatting: {
        cost: 'currency',
        impressions: 'number',
        clicks: 'number',
        conversions: 'number',
        clickThroughRate: 'percentage',
        costPerClick: 'currency-precise',
        costPerConversion: 'currency-precise',
        costPerThousandImpressions: 'currency-precise',
        conversionRate: 'percentage',
        impressionShare: 'percentage'
      }
    }
  },
  watch: {
    chartData: {
      handler () {
        this.$nextTick(() => {
          this.drawChart()
        })
      },
      deep: true
    }
  },
  computed: {
    chartHeight () {
      return Math.min(this.chartData.labels.length * 50, 250) + 'px'
    },
    scrollChart () {
      return parseFloat(this.chartHeight) > 250
    }
  },
  methods: {
    drawChart () {
      const v = this

      this.chartData2 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            label: 'Total Spend',
            backgroundColor: 'rgba(254, 206, 168, 0.8)',
            data: [50, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          }
        ]
      }

      const scrollingChartOptions = {
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                if (v.formatting[this.selectedMetric]) {
                  return v.formatter(v.formatting[this.selectedMetric], tooltipItem.raw)
                  // return tooltipItem.value
                } else {
                  return tooltipItem.raw
                }
              }
            }
          }
        },
        scales: {
          y: {
            display: !this.scrollChart,
            ticks: {
              callback: () => ''
            }
          },
          x: {
            ticks: {
              callback: (val) => {
                if (v.formatting[this.selectedMetric]) {
                  return v.formatter(v.formatting[this.selectedMetric], val)
                  // return val
                } else {
                  return val
                }
              }
            }
          }
        }
      }

      const chartAxisOptions = _.cloneDeep(scrollingChartOptions)
      chartAxisOptions.scales.x = {
        position: 'top',
        ticks: {
          callback: (val) => {
            if (v.formatting[this.selectedMetric]) {
              return v.formatter(v.formatting[this.selectedMetric], val)
              // return val
            } else {
              return val
            }
          }
        }
      }

      if (this.chartObj) {
        this.chartObj.data = v.chartData
        this.chartObj.update()
        if (this.scrollChart) {
          this.chartAxis.data = v.chartData
          if (this.chartAxis.update) {
            this.chartAxis.update()
          }
        }
      } else {
        this.chartObj = new Chart(this.$refs.chart, {
          type: 'bar',
          data: v.chartData,
          options: scrollingChartOptions
        })
        if (this.scrollChart) {
          this.chartAxis = new Chart(this.$refs.chartAxis, {
            type: 'bar',
            data: v.chartData,
            options: chartAxisOptions
          })
        }
      }
    },
    formatter (format = '', value) {
      if (typeof value === 'number') {
        switch (format) {
          case 'number':
            return this.$filters.number(value, false, true)
          case 'fixed':
            return this.$filters.number(value, 1)
          case 'percentage':
            return this.$filters.percentage(value)
          case 'currency':
            return this.$filters.currency(value, 0)
          case 'currency-precise':
            return this.$filters.currency(value, 2)
          default:
            return value
        }
      } else {
        switch (format) {
          case 'from-caps-underscore':
            return this.$filters.convertFromCapsUnderscore(value)
          default:
            return value
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
