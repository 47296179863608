<template>
  <b-modal v-if="planId && show"
           lazy
             :visible="show"
             body-class="preview-geo-popover"
           dialog-class="custom-geo-modal-size"
           size="md"
              @hidden="show = false"
             @shown="handleShown()">
    <geo-target-editor :value="plan.geoTargetDTO" :account-plan="accountPlan" preview-mode style="width:476px;">
      <template #under-map>
        <div class="d-flex justify-content-between pt-3 align-items-end">
          <geo-target-method :value="plan" read-only></geo-target-method>
          <b-button variant="outline-success" class="d-flex align-items-center ml-3" @click="openSettings()">
            <span class="mr-2 white-space-no-wrap">Edit Location Settings</span>
            <fluency-icon type="chevronRight" />
          </b-button>
        </div>
      </template>
    </geo-target-editor>
  </b-modal>
</template>

<script>
import GeoTargetEditor from '@/components/pages/manage/geoTargetEditor'
import GeoTargetMethod from '@/components/pages/manage/campaign/geoTargetMethod'
export default {
  name: 'PreviewGeos',
  components: { GeoTargetMethod, GeoTargetEditor },
  data () {
    return {
      show: false
    }
  },
  computed: {
    plan () {
      return this.$store.getters.settingsItem
    },
    planId () {
      return this.plan?.adGroupPlanId || this.plan?.campaignPlanId
    },
    planType () {
      if (this.plan?.adGroupPlanId) {
        return 'adgroup'
      }

      return 'campaign'
    },
    target () {
      return `plan-name-${this.planId}`
    },
    accountPlan () {
      return this.$store.getters?.accountsById?.get(this.plan?.accountPlanId)
    }
  },
  methods: {
    handleShown () {
      const el = document.querySelector('.preview-geo-popover')
      if (el) {
        el.focus()
      }
    },
    openSettings () {
      this.show = false
      this.$router.push({ query: { ...this.$route.query, settingsItemId: this?.planId, settingsItemType: this.planType, form: 'locations' } })
    },
    showTrue () {
      this.show = true
    }
  },
  mounted () {
    this.$eventBus.$on('preview-campaign-geos', this.showTrue)
  },
  beforeUnmount () {
    this.$eventBus.$off('preview-campaign-geos', this.showTrue)
  }
}
</script>

<style lang="scss">
  .custom-geo-modal-size {
    max-width: 510px;
  }
</style>
