<template>
  <div>
    <b-button-group class="float-left">
      <b-button :disabled="disabled" :variant="(setOrModify === 'modify')?'success':''" @click="setOrModify = 'modify'">Modify by</b-button>
      <b-button :disabled="disabled" :variant="(setOrModify === 'set')?'success':''" @click="setOrModify = 'set';dollarOrPercent = '$'">Set</b-button>
    </b-button-group>
    <b-form-select :disabled="disabled" v-if="setOrModify === 'modify'" style="width:130px;" class="ml-3 float-left" v-model="increaseOrDecrease" :options="increaseOrDecreaseOptions"/>
    <b-input-group style="width:130px;" class="ml-3 float-left">
      <b-input-group-prepend>
        <b-button :disabled="disabled" @click="toggleFixedPercent()">{{dollarOrPercent}}</b-button>
      </b-input-group-prepend>
      <b-form-input :disabled="disabled" type="number" min="0.00" v-model="adjustAmount" @change="update()"/>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'bulkModifyDollarAmount',
  props: ['disabled'],
  data () {
    return {
      setOrModify: 'modify',
      dollarOrPercent: '$',
      adjustAmount: null,
      increaseOrDecrease: 1,
      increaseOrDecreaseOptions: [
        { text: 'Increase', value: 1 },
        { text: 'Decrease', value: -1 }
      ]
    }
  },
  computed: {
    name () {
      return this.data
    }
  },
  methods: {
    update () {
      this.$emit('input', {
        setOrModify: this.setOrModify,
        dollarOrPercent: this.dollarOrPercent,
        adjustAmount: this.adjustAmount,
        increaseOrDecrease: this.increaseOrDecrease
      })
    },
    toggleFixedPercent () {
      if (this.setOrModify === 'modify') {
        this.dollarOrPercent = (this.dollarOrPercent === '$') ? '%' : '$'
      }
      this.update()
    }
  }
}
</script>

<style scoped>

</style>
