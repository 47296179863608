<template>
  <b-dropdown no-caret
              variant="link"
              :disabled="count === 0"
              menu-class="accountNotificationsDropdown"
              toggle-class="accountNotificationsToggle border-0 p-0 no-focus">

    <template #button-content>
      <header-icon-notifications icon-type="bell" :notifications="count" class="dropdown-toggle" :notifications-class="[`bg-${severityVariant[highestSeverity]}`, `text-${highestSeverity === 'Elevated' ? 'black' : 'white'}`]"
                                 v-p-tooltip.bottom="{ value: 'Notifications', showDelay: 10 }"/>
    </template>

    <p>To see notifications for {{activeAccount?.name}} along with notifications for all other accounts, navigate over to your <nuxt-link target="_blank" :href="`/alerts/?view=account&accounts=${activeAccount?.accountPlanId}`">Notifications Page</nuxt-link>.</p>
    <template v-for="notification in notificationsFlat" :key="notification.hashCode" >
      <div  v-if="showMuted || !notification.archived" class="border-bottom mb-3 pb-3">
          <div class="d-flex align-items-center mb-2">
            <fluency-icon type="warning" :class="`text-${severityVariant[notification.severity]}`" class="mr-2"></fluency-icon>
            <b-badge v-if="notification.archived" class="mr-3" variant="muted">Muted</b-badge>
            <span :class="{'text-muted': notification.archived}">{{notification.taskName}}</span>
          </div>
          <p v-html="notification.description"
             class="mb-0"
             :class="{'text-muted': notification.archived}"></p>
          <expandable-list v-if="notification.children"
                           :list="notification.children.map(c => c.taskName)"
                           :links="notification.children.map(c => c.uriToNavigateTo)"
                           :truncate-length="5"></expandable-list>
          <div class="d-flex mt-3">
            <template v-if="notification.link && (!notification.children || notification.children.length === 0)">
              <b-button v-if="!notification.link.startsWith('/manage')"
                        variant="primary"
                        size="sm"
                        class="mr-3"
                        :href="notification.link"
                        target="_blank">{{CTA(notification.taskName)}}</b-button>
              <nuxt-link :to="notification.link" v-else-if="notification.link !== $route.fullPath">
                <b-button variant="primary"
                          size="sm"
                          class="mr-3">{{CTA(notification.taskName)}}</b-button>
              </nuxt-link>
            </template>
            <b-button variant="outline-secondary"
                      size="sm"
                      class="d-flex"
                      :disabled="muting === notification.hashCode"
                      @click="mute(notification)">
              <fluency-icon v-if="muting === notification.hashCode" type="loop" class="rotating"></fluency-icon>
              <fluency-icon v-else :type="notification.archived ? 'unmute' : 'mute'" class="mr-2"></fluency-icon>
              <span v-if="notification.archived">Unmute</span>
              <span v-else>Mute</span>
            </b-button>
          </div>
      </div>
    </template>
    <div class="d-flex">
      <span class="mr-3">Show Muted</span>
      <b-form-checkbox :checked="showMuted" @input="showMuted = $event" switch />
    </div>
  </b-dropdown>
</template>

<script>
import { convertToMuteDTO } from '@/store/modules/notifications'
import ExpandableList from '@/components/common/expandableList'
import HeaderIconNotifications from '@/components/common/headerIconNotifications'

export default {
  name: 'AccountNotifications',
  components: { ExpandableList, HeaderIconNotifications },
  data () {
    return {
      severityMap: {
        High: 3,
        Elevated: 2,
        Normal: 1
      },
      severityVariant: {
        High: 'danger',
        Elevated: 'warning',
        Normal: 'info'
      },
      muting: -1,
      showMuted: false
    }
  },
  computed: {
    activeAccount () {
      const item = this.$store.getters.activeItem
      return item?.accountPlan || item
    },
    loading () {
      return this.$store.getters.loading?.notifications
    },
    notifications () {
      return this.$store.getters.activeAccountNotifications || []
    },
    notificationsFlat () {
      return this.notifications.filter(s => s.sectionTitle !== 'Budget Reallocation Recommendations')
        .reduce((accumulator, current) => [...accumulator, ...current.dashboardTaskList
          .map(n => ({
            ...n,
            link: this.findUrl(n),
            section: current.sectionTitle,
            children: n.children?.length > 0 ? n.children.filter(c => c.uriToNavigateTo) : null
          }))], [])
        .sort((a, b) => {
          if (this.severityMap[a.severity] > this.severityMap[b.severity]) {
            return -1
          } else if (this.severityMap[a.severity] < this.severityMap[b.severity]) {
            return 1
          } else {
            return 0
          }
        })
    },
    count () {
      return this.notificationsFlat.filter(n => !n.archived).length
    },
    highestSeverity () {
      return this.notificationsFlat.reduce((highest, current) => {
        if (this.severityMap[current.severity] > this.severityMap[highest]) {
          return current.severity
        } else {
          return highest
        }
      }, 'Normal')
    }
  },
  methods: {
    findUrl (notification) {
      if (notification.secondaryUri) {
        return notification.secondaryUri
      }
      return notification.uriToNavigateTo
    },
    async mute (notification) {
      this.muting = notification.hashCode
      let resp
      if (notification.archived) {
        resp = await this.$res.set.unmuteNotifications(convertToMuteDTO([notification]))
      } else {
        resp = await this.$res.set.muteNotifications('Forever', convertToMuteDTO([notification]))
      }
      if (resp) {
        this.$toast('Muted', 'success')
        this.$store.commit('toggleNotificationMuted', notification.hashCode)
      }
      this.muting = -1
    },
    CTA (type) {
      switch (type) {
        default:
          return 'Show'
      }
    }
  }
}
</script>

<style lang="scss">
.accountNotificationsDropdown {
  width: 400px;
  max-height: 95vh;
  overflow-y: auto;
}
</style>
